import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Layout from "../../../layout";
import Images from "../../../Images";
import { Breadcrumb } from "react-bootstrap";
import BreadCrumb from "../breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteUser,
  getCredentials,
  getUserProfileDetail,
  resetPasscodeUser,
  resetPasswordUser,
  updateProfile,
} from "../../../../services/api/endpoints";
import axiosInstance from "../../../../services/middleware";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { DatePicker, message } from "antd";
import { BlobServiceClient } from "@azure/storage-blob";
import {
  decryptRequest,
  encryptRequest,
} from "../../../../services/helpers/crypto";
import DeleteUserModal from "../../../modal/deleteUser";

const UserProfile = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");

  const { profile } = useSelector((state) => state.profileData);
  const { loginData } = useSelector((state) => state.adminLoginData);
  const [profileData, setProfileData] = useState({
    id:"",
    name: "",
    email: "",
    lName: "",
    gender: "",
    phone: "",
    countryCode: "",
    dob: "",
    isPhoneVerified: "",
    city: "",
    state: "",
    isEmailVerified: "",
    status: "",
    lattitude: "",
    longitude: "",
    addressLine1: "",
    addressLine2: "",
    addressName: "",
    createdAt: "",
    userType: "",
    profilePic: "",
    url: "",
    remainingDays: "",
  });
  const [profilePicChange, setProfilePicChange] = useState({
    userId: null,
    profilePic: "",
  });
  const [creds, setCreds] = useState(null);
  const [editProfile, setEditProfile] = useState(1);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [pageNo, setPageNo] = useState(
    window?.location?.pathname.split("?").pop()
  );
  const [fileAzure, setFileAzure] = useState(null);

  const onEditProfileClick = (event) => {
    event.preventDefault();
    setEditProfile(0);
    getCreds();
  };
  async function getCreds() {
    try {
      const response = await axiosInstance.get(`${getCredentials}`);

      const apiData = response.data.data;

      if (apiData) {
        setCreds(apiData);
      }
    } catch (err) {
      console.log("err", err);
    }
  }
  const onCancelClick = (event) => {
    event.preventDefault();
    setEditProfile(1);
  };
  const handleFileChange = (event) => {
    setFile(URL.createObjectURL(event.target.files[0]));
    setProfileData({ ...profileData, profilePic: event.target.files[0].name });
    setFileAzure(event.target.files[0]);
    handleUpload(event.target.files[0]);
  };
  const handleUpload = async (files) => {
    // debugger
    if (files) {
      // const blobService = new BlobServiceClient(
      //   `${creds.azureBlobURL}/?${creds.connectionString}`
      // );
      // const containerClient = blobService.getContainerClient(
      //   creds.containerName
      // );

      // const blobClient = containerClient.getBlockBlobClient(files.name);
      // const options = { blobHTTPHeaders: { blobContentType: files.type } };
      // const response = await blobClient.uploadBrowserData(files, options);
      // setUrl(response);

      // Set your storage account name, container name, and SAS token here
      const blobServiceClient = new BlobServiceClient(creds.azureBlobURL);
      const containerName = creds.containerName;

      const containerClient =
        blobServiceClient.getContainerClient(containerName);
      const blobName = files.name;

      const blockBlobClient = containerClient.getBlockBlobClient(blobName);

      try {
        const response = await blockBlobClient.uploadBrowserData(files);
        setUrl(response);
      } catch (error) {
        console.log("Error uploading file", error);
      }
    }
  };

  const confirm = async () => {
    try {
      const response = await axiosInstance.delete(`${deleteUser}/${profileData?.id}`);
      const apiData = response;
      // setResponseGet2(apiData2);
      setOpenModal(false);
      message.success("User Deleted");
      // allUsers();
      navigate(`/user-management`);
      
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setPageNo(location?.state?.id);
    async function getUserProfile() {
      try {
        const response = await axiosInstance.get(
          `${getUserProfileDetail}/${profile?.id}`
        );

        const apiData = response.data.data;

        setProfileData(apiData);
        if (apiData) {
          setProfileData({
            id:apiData?.id,
            name: decryptRequest(apiData?.name),
            email: decryptRequest(apiData?.email),
            lName: decryptRequest(apiData?.lName),
            gender: apiData?.gender,
            phone: decryptRequest(apiData?.phone),
            countryCode: apiData?.countryCode,
            dob: apiData?.dob,
            isPhoneVerified: apiData?.isPhoneVerified,
            city: apiData?.city,
            state: apiData?.state,
            isEmailVerified: apiData?.isEmailVerified,
            status: apiData?.status,
            lattitude: apiData?.lattitude,
            longitude: apiData?.longitude,
            addressLine1: apiData?.addressLine1,
            addressLine2: apiData?.addressLine2,
            addressName: apiData?.addressName,
            createdAt: apiData?.createdAt,
            userType: apiData?.userType,
            profilePic: apiData?.profilePic,
            url: apiData?.url,
            remainingDays: apiData?.remainingDays,
            isLawEnforcement: apiData?.isLawEnforcement,
          });
        }
        setFile(apiData?.url);
      } catch (err) {
        console.log("err", err);
      }
    }

    getUserProfile();
  }, [editProfile]);
  const onInputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "profilePic") {
      setProfilePicChange({
        ...profilePicChange,
        userId: profile?.id,
        profilePic: event.target.files[0]?.name,
      });
    } else {
      setProfileData({ ...profileData, [name]: value });
    }
    if (name == "isLawEnforcement") {
      setProfileData({
        ...profileData,
        isLawEnforcement: profileData?.isLawEnforcement == true ? false : true,
      });
    } else {
    }
  };

  useEffect(() => {
    if (profileData === null) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [profileData]);

  const onSubmitClick = async (event) => {
    event.preventDefault();
    let payload = {
      id: profile?.id,

      userId: loginData?.data?.id,

      fName: encryptRequest(profileData.name),

      lName: encryptRequest(profileData.lName),

      email: encryptRequest(profileData.email),

      gender: profileData.gender,

      phone: encryptRequest(profileData.phone),

      cityName: profileData.city,

      state: profileData.state,

      dob: profileData.dob,
      isLawEnforcement: profileData?.isLawEnforcement,
    };
    try {
      setLoading2(true);
      const response = await axiosInstance.put(`${updateProfile}`, payload);
      const apiData2 = response;
      if (apiData2?.data?.success) {
        setLoading2(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Profile Edit Successful",
          timer: 1500,
        });

        setEditProfile(1);
      } else {
        setLoading2(false);

        Swal.fire({
          position: "center",
          icon: "error",
          title: "Profile Edit Failed",
          showConfirmButton: true,
          // timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onResetClick = async (event) => {
    event.preventDefault();
    let payload = {
      email: profile?.email,
    };
    try {
      setLoading3(true);
      const response = await axiosInstance.put(`${resetPasswordUser}`, payload);
      const apiData3 = response;
      if (apiData3?.data?.success) {
        setLoading3(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Link sent successfully",
          timer: 1500,
        });
      } else {
        setLoading3(false);

        Swal.fire({
          position: "center",
          icon: "error",
          title: "Failed",
          showConfirmButton: true,
          // timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onPasscode = async (event) => {
    event.preventDefault();
    let payload = {
      email: profile?.email,
    };
    try {
      setLoading3(true);
      const response = await axiosInstance.put(`${resetPasscodeUser}`, payload);
      const apiData3 = response;
      if (apiData3?.data?.success) {
        setLoading3(false);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Link sent successfully",
          timer: 1500,
        });
      } else {
        setLoading3(false);

        Swal.fire({
          position: "center",
          icon: "error",
          title: "Failed",
          showConfirmButton: true,
          // timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onDeleteProfileClick = async () => {
    setOpenModal(true);
  };
  const onBackClick = () => {
    if (location?.state?.id == "archived") {
      dispatch({ type: "IS_ARCHIVE", payload: true });
    } else {
      dispatch({ type: "IS_ARCHIVE", payload: false });
    }

    navigate(`/user-management`, {
      state: {
        id: location?.state?.id == "archived" ? location?.state?.id : null,
      },
    });
  };
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }
  return (
    <Layout>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/user-management" onClick={() => onBackClick()}>
            {location?.state?.id == "archived" ? "Archive Users" : "All Users"}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active={editProfile == 0 ? false : true}>
          {decryptRequest(profile?.name)}’s Profile
        </Breadcrumb.Item>
        {editProfile == 0 ? (
          <Breadcrumb.Item active={editProfile == 0 ? true : false}>
            <Link to="#" className="text-dark">
              <strong>Edit Profile</strong>
            </Link>
          </Breadcrumb.Item>
        ) : null}
      </Breadcrumb>
      <div className="top-filter">
        <div className="row">
          <div className="col-md-12">
            <BreadCrumb
              setHeaderState={props.setHeaderState}
              headerState={props.headerState}
            />
          </div>
        </div>
      </div>
      <div className="bg-outer">
        {loading === true ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              {/* <span className="sr-only">Loading...</span> */}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-3 col-md-2 col-xs-12">
              {/* header image placement */}
              <div className="image-wrapper">
                <div className="profile-img">
                  {profileData?.profilePic == "" ||
                  profileData?.profilePic == null ? (
                    <p className="avatar-text">
                      {profileData?.name?.slice(0, 1)}
                    </p>
                  ) : (
                    <img
                      src={
                        profileData?.profilePic == "" ||
                        profileData?.profilePic == null
                          ? Images.ADMINPIC
                          : file
                      }
                    />
                  )}
                </div>

                {profileData?.status === 1 ? (
                  <span className="active-label">
                    <i className="icon-user"></i> Active
                  </span>
                ) : null}
                {profileData?.status === 3 ? (
                  <span className="inactive-label">
                    <i className="icon-user"></i> Cancelled
                  </span>
                ) : null}
                {profileData?.status === 4 ? (
                  <span className="blocked-label">
                    <i className="icon-user"></i> Blocked
                  </span>
                ) : null}
                {/* {editProfile === 0 ? (
                  <div className="camera-label">
                    <input
                      type="file"
                      name="profilePic"
                      id="profile-img"
                      className="d-none"
                      accept="image/*"
                      onChange={handleFileChange}
                    />

                    <label htmlFor="profile-img">
                      <i className="icon-camera"></i>
                    </label>
                  </div>
                ) : null} */}
              </div>
              {profileData?.status === 2 ? (
                <p className="remaining-days text-center">
                  {profileData?.remainingDays} days remaining.
                </p>
              ) : null}
              {profileData?.status === 4 ? (
                <p className="remaining-days text-center">
                  {profileData?.remainingDays} days remaining.
                </p>
              ) : null}
              {/* header image placement ends */}
              {editProfile === 1 ? (
                <button
                  className="btn btn-primary btn-xl btn-icon text-white mx-auto"
                  onClick={onEditProfileClick}
                >
                  Edit Profile <i className="icon-user"></i>
                </button>
              ) : (
                <></>
              )}
              {profileData?.status === 3 && location?.state?.id !== "archived" ? (
                <button
                  className="btn btn-primary btn-xl btn-icon text-white mx-auto mt-2"
                  onClick={onDeleteProfileClick}
                >
                  Delete User <i className="icon-cross"></i>
                </button>
              ) : (
                <></>
              )}
            </div>
            <div className="col-lg-8 col-md-9 col-xs-12">
              <form className="profile-wrapper">
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      value={profileData?.name}
                      disabled={editProfile === 1 ? true : false}
                      onChange={onInputChange}
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lName"
                      placeholder="User Type"
                      value={profileData?.lName}
                      disabled={editProfile === 1 ? true : false}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="form-label">User Type</label>

                    <select
                      className="form-control"
                      disabled={editProfile === 1 ? true : false}
                      value={profileData?.userType}
                      name="userType"
                      onChange={onInputChange}
                    >
                      <option value="PersonalAccount">Personal Account</option>
                      <option value="Guardian">Guardian Account</option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Email Address</label>
                    <div className="icon-holder">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="example@gmail.com"
                        value={profileData?.email}
                        onChange={onInputChange}
                        disabled={true}
                      />
                      {profileData?.isEmailVerified == true ? (
                        <i className="icon-check-circle primary"></i>
                      ) : (
                        <i className="icon-pending text-warning"></i>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Phone Number</label>
                      <div className="icon-holder">
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          placeholder="+1 (XXX) XXX-XXXX"
                          value={formatPhoneNumber(profileData?.phone)}
                          disabled={true}
                          onChange={onInputChange}
                        />
                        {!profileData?.phone ? null : (
                          <i className="icon-check-circle primary"></i>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label">Date of Birth</label>

                      <div className="date-space spacer-new">
                        <DatePicker
                          className="btn btn-white btn-lg datepicker"
                          dob="dob"
                          // selected={moment(profileData?.dob)}
                          onChange={(date) =>
                            setProfileData({
                              ...profileData,
                              dob: moment
                                .utc(date)
                                .local()
                                .format("YYYY-MM-DDTHH:mm:ss"),
                            })
                          }
                          value={
                            profileData?.dob ? moment(profileData.dob) : null
                          }
                          disabled={editProfile === 1 ? true : false}
                          format="MM-DD-YYYY"
                        />
                        <i className="icon-calendar"></i>
                      </div>
                    </div>
                
                  </div>
                  <div className="form-group col-md-6">
                    <label className="form-label">Gender</label>
                    <ul className="gender-list">
                      {editProfile === 1 ? (
                        <li>
                          <input
                            id="women"
                            name="gender"
                            value=""
                            type="radio"
                            checked
                            disabled={editProfile === 1 ? true : false}
                          />
                          {profileData?.gender === "Woman" ? (
                            <label
                              htmlFor="women"
                              className={
                                profileData?.gender === "Woman"
                                  ? "active"
                                  : null
                              }
                            >
                              Woman
                            </label>
                          ) : null}
                          {profileData?.gender === "Man" ? (
                            <label
                              htmlFor="man"
                              className={
                                profileData?.gender === "Man" ? "active" : null
                              }
                            >
                              Man
                            </label>
                          ) : null}
                          {profileData?.gender === "Transgender" ? (
                            <label
                              htmlFor="man"
                              className={
                                profileData?.gender === "Transgender"
                                  ? "active"
                                  : null
                              }
                            >
                              Transgender
                            </label>
                          ) : null}
                          {profileData?.gender === "" ? (
                            <label
                              htmlFor="man"
                              className={
                                profileData?.gender === "" ? "active" : null
                              }
                            >
                              NA
                            </label>
                          ) : null}
                          {profileData?.gender === "Non-Binary" ? (
                            <label
                              htmlFor="nonbinary"
                              className={
                                profileData?.gender === "Non-Binary"
                                  ? "active"
                                  : null
                              }
                            >
                              Non-Binary
                            </label>
                          ) : null}
                          {profileData?.gender === "Other" ? (
                            <label
                              htmlFor="other"
                              className={
                                profileData?.gender === "Other"
                                  ? "active"
                                  : null
                              }
                            >
                              Other
                            </label>
                          ) : null}
                        </li>
                      ) : null}
                      {editProfile === 0 ? (
                        <>
                          <li>
                            <input
                              id="women"
                              name="gender"
                              value="Woman"
                              type="radio"
                              disabled={editProfile === 1 ? true : false}
                              onChange={onInputChange}
                            />
                            <label
                              htmlFor="women"
                              className={
                                profileData?.gender === "Woman"
                                  ? "active"
                                  : null
                              }
                            >
                              Woman
                            </label>
                          </li>
                          <li>
                            <input
                              id="man"
                              name="gender"
                              value="Man"
                              type="radio"
                              disabled={editProfile === 1 ? true : false}
                              onChange={onInputChange}
                            />
                            <label
                              htmlFor="man"
                              className={
                                profileData?.gender === "Man" ? "active" : null
                              }
                            >
                              Man
                            </label>
                          </li>
                          <li>
                            <input
                              id="transgender"
                              name="gender"
                              value="Transgender"
                              type="radio"
                              disabled={editProfile === 1 ? true : false}
                              onChange={onInputChange}
                            />
                            <label
                              htmlFor="transgender"
                              className={
                                profileData?.gender === "Transgender"
                                  ? "active"
                                  : null
                              }
                            >
                              Transgender
                            </label>
                          </li>
                          <li>
                            <input
                              id="nonbinary"
                              name="gender"
                              value="Non-Binary"
                              type="radio"
                              disabled={editProfile === 1 ? true : false}
                              onChange={onInputChange}
                            />
                            <label
                              htmlFor="nonbinary"
                              className={
                                profileData?.gender === "Non-Binary"
                                  ? "active"
                                  : null
                              }
                            >
                              Non-Binary
                            </label>
                          </li>
                          <li>
                            <input
                              id="other"
                              name="gender"
                              value="Other"
                              type="radio"
                              disabled={editProfile === 1 ? true : false}
                              onChange={onInputChange}
                            />
                            <label
                              htmlFor="other"
                              className={
                                profileData?.gender === "Other"
                                  ? "active"
                                  : null
                              }
                            >
                              Other
                            </label>
                          </li>
                        </>
                      ) : null}
                    </ul>
                    <div className="form-group mt-3">
                      <label className="form-label">User ID</label>
                      <div className="icon-holder">
                        <input
                          type="text"
                          className="form-control"
                          name="phone"
                          placeholder="+1 (XXX) XXX-XXXX"
                          value={(profileData?.id)}
                          disabled={true}
                          // onChange={onInputChange}
                        />
                        {/* {!profileData?.phone ? null : (
                          <i className="icon-check-circle primary"></i>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Location</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        placeholder="Location"
                        value={profileData?.city}
                        onChange={onInputChange}
                        disabled={editProfile === 1 ? true : false}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">State</label>
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        placeholder="state"
                        onChange={onInputChange}
                        value={profileData?.state}
                        disabled={editProfile === 1 ? true : false}
                      />
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="switch-label">
                      <p>Share data with authorities</p>
                      <label className="onoffswitch">
                        <input
                          type="checkbox"
                          name="isLawEnforcement"
                          formcontrolname=""
                          className="onoffswitch-checkbox"
                          id="myonoffswitch-3"
                          checked={profileData?.isLawEnforcement}
                          disabled={true}
                          // onChange={onInputChange}
                        />
                        <div
                          className="onoffswitch-label"
                          htmlFor="myonoffswitch-3"
                        >
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>

                {editProfile === 0 ? (
                  <div className="text-center">
                    {loading2 === true ? (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          {/* <span className="sr-only">Loading...</span> */}
                        </div>
                      </div>
                    ) : (
                      <>
                        <button
                          className="btn btn-primary btn-xxl btn-round d-block m-auto"
                          onClick={(event) => onSubmitClick(event)}
                          data-loading-text={
                            loading2 === true
                              ? "<i className='icon-spinner icon-spin icon-large'></i> Loading"
                              : null
                          }
                        >
                          Save Changes
                        </button>
                        <a
                          to="/user-management"
                          className="btn"
                          onClick={onCancelClick}
                        >
                          Cancel
                        </a>
                      </>
                    )}
                  </div>
                ) : null}
                {/* for the profile view this sectionbe used */}
                {editProfile === 1 ? (
                  <div className="text-center btn-group-wrap">
                    {loading2 === true ? (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          {/* <span className="sr-only">Loading...</span> */}
                        </div>
                      </div>
                    ) : (
                      <button
                        className="btn btn-primary btn-xl btn-round"
                        onClick={(event) => onResetClick(event)}
                      >
                        Send Reset Password Link
                      </button>
                    )}
                    <button
                      className="btn btn-primary outline btn-xl btn-round"
                      onClick={(event) => onPasscode(event)}
                    >
                      Send Reset Passcode Link
                    </button>
                  </div>
                ) : null}

                {/* end */}
              </form>
            </div>
          </div>
        )}
      </div>
      <DeleteUserModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        confirm={confirm}
      />
    </Layout>
  );
};

export default UserProfile;
