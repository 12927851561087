import React, { useState } from "react";
import ActiveSos from "./activeSOS/ActiveSOS";
import ArchivedSOS from "./archivedSos/ArchivedSOS";
import SosHistory from "./sosHistory/SosHistory";

const SosMainComponent = () => {
  const [headerState, setHeaderState] = useState(1);



  return (
    <div>
      {headerState === 1 ? (
        <ActiveSos setHeaderState={setHeaderState} headerState={headerState} />
      ) : null}

      {headerState === 2 ? (
        <SosHistory setHeaderState={setHeaderState} headerState={headerState} />
      ) : null}
      {headerState === 3 ? (
        <ArchivedSOS
          setHeaderState={setHeaderState}
          headerState={headerState}
        />
      ) : null}
    </div>
  );
};

export default SosMainComponent;
