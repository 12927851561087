import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import Layout from "../layout";
import DatePicker from "react-datepicker";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
// import { useSelector } from "react-redux";
import TotalUsers from "../../components/dashboard/TotalUsers";
import TotalPaidUsers from "../dashboard/TotalPaidUsers";
import TotalActiveSos from "../dashboard/TotalActiveSos";
// import PaidUsersGain from "../dashboard/PaidUsersGain";
import FreeTrialUsers from "../dashboard/FreeTrialUsers";
import axiosInstance from "../../services/middleware";
import {
  getTotalActiveSosCount,
  getTotalCountAsOf,
  getTotalCountBetweenDateRange,
  getTotalNewExistingtrialUserCount,
  getTotalPaidUserGainLoss,
} from "../../services/api/endpoints";
import moment from "moment/moment";
import { CSVLink } from "react-csv";

const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [responseGet, setResponseGet] = useState(null);
  const [responseGet2, setResponseGet2] = useState(null);
  const [responseGet3, setResponseGet3] = useState(null);
  const [responseGet4, setResponseGet4] = useState(null);
  const [responseGet5, setResponseGet5] = useState(null);
  const [responseGet6, setResponseGet6] = useState(null);
  const [responseGet7, setResponseGet7] = useState(null);
  const [responseGet8, setResponseGet8] = useState(null);
  const [responseGet9, setResponseGet9] = useState(null);
  const [responseGet10, setResponseGet10] = useState(null);
  const [tillDateArr, setTillDateArr] = useState([]);
  // const [csvApiData, setCsvApiData] = useState(null);
  var d = new Date();

  const [startDate2, setStartDate2] = useState(d.setDate(d.getDate() - 30));
  const [startDate3, setStartDate3] = useState(new Date());
  let d1 = moment.utc(startDate2).local().format("MM-DD-YYYYThh:mm:ss");
  let d2 = moment.utc(startDate3).local().format("MM-DD-YYYYThh:mm:ss");

  useEffect(() => {
    async function TotalActiveSOSCount() {
      try {
        const response = await axiosInstance.get(
          `${getTotalActiveSosCount}/${d1}/${d2}`
        );
        const apiData = response.data.data;
        let arr = [];
        if (apiData?.length > 0) {
          arr = apiData.map((x) => ({
            date: moment(x?.sosDate).format("MM-DD-YYYY"),
            type: "Active SOS Count",
            count: x?.activeSOS === 0 ? null : x?.activeSOS,
          }));

          setResponseGet(arr);
        }
      } catch (err) {
        console.log(err);
      }
    }
    async function totalNewExistingtrialUserCount() {
      try {
        const response2 = await axiosInstance.get(
          `${getTotalNewExistingtrialUserCount}/${d1}/${d2}`
        );
        const apiData2 = response2.data.data;
         
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];

        for (let i = 0; i < apiData2.length; i++) {
          arr2.push({
            date: moment(apiData2[i]?.subscriptionStartDate).format("MM-DD-YYYY"),
            type: "Existing User on Trial",
            count:
              apiData2[i]?.existingUserTrial === 0
                ? null
                : apiData2[i]?.existingUserTrial,
          });
        }
        for (let i = 0; i < apiData2.length; i++) {
          arr3.push({
            date: moment(apiData2[i]?.subscriptionStartDate).format("MM-DD-YYYY"),
            type: "New User on Trial",
            count:
              apiData2[i]?.newUserTrial === 0 ? null : apiData2[i]?.newUserTrial,
          });
        }

        arr4 = arr2.concat(arr3);

        setResponseGet2(arr4);
      } catch (err) {
        console.log(err);
      }
    }

    async function totaPaidUserGainLoss() {
      try {
        const response3 = await axiosInstance.get(
          `${getTotalPaidUserGainLoss}/${d1}/${d2}`
        );
        const apiData3 = response3.data.data;
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];

        for (let i = 0; i < apiData3.length; i++) {
          arr2.push({
            date: moment(apiData3[i]?.subscriptionStartDate).format("MM-DD-YYYY"),
            type: "Cancelled Paid Users",
            count:
              apiData3[i]?.cancelledUsers === 0
                ? "-"
                : apiData3[i]?.cancelledUsers,
          });
        }
        for (let i = 0; i < apiData3.length; i++) {
          arr3.push({
            date: moment(apiData3[i]?.subscriptionStartDate).format("MM-DD-YYYY"),
            type: "New Paid Users (Free Trial Converts)",
            count:
              apiData3[i]?.freeTrailToPaidUsers === 0
                ? "-"
                : apiData3[i]?.freeTrailToPaidUsers,
          });
        }
        for (let i = 0; i < apiData3.length; i++) {
          arr4.push({
            date: moment(apiData3[i]?.subscriptionStartDate).format("MM-DD-YYYY"),
            type: "New Paid Users (Direct Signup)",
            count:
              apiData3[i]?.directPaidUsers === 0
                ? "-"
                : apiData3[i]?.directPaidUsers,
          });
        }
        arr5 = arr2.concat(arr3, arr4);

        setResponseGet3(arr5);
      } catch (err) {
        console.log(err);
      }
    }
    async function TotalCountAsOf() {
      try {
        const response = await axiosInstance.get(`${getTotalCountAsOf}/${moment
          .utc(startDate)
          .local()
          .format("MM-DD-YYYYT05:30:00")}`);
        const apiData = response.data.data;

        setResponseGet4(apiData);
        setTillDateArr([apiData]);
      } catch (err) {
        console.log(err);
      }
    }
    async function totalCountBetweenDateRange() {
      try {
        const response5 = await axiosInstance.get(
          `${getTotalCountBetweenDateRange}/${d1}/${d2}`
        );
        const apiData5 = response5.data.data;

        setResponseGet7(apiData5);
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];
        let arr6 = [];
        let arr7 = 0;
        let arr8 = 0;
        let arr9 = 0;

        for (let i = 0; i < apiData5.length; i++) {
          arr2.push({
            date: moment(apiData5[i]?.subscriptionStartDate).format("MM-DD-YY"),
            type: "Total Paid Users",
            count:
              apiData5[i]?.totalPaidUsers === 0
                ? null
                : apiData5[i]?.totalPaidUsers,
          });
        }
        for (let i = 0; i < apiData5.length; i++) {
          arr3.push({
            date: moment(apiData5[i]?.subscriptionStartDate).format("MM-DD-YY"),
            type: "Total Paid Users",
            count:
              apiData5[i]?.totalPaidUsers === 0
                ? null
                : apiData5[i]?.totalPaidUsers,
          });
        }
        for (let i = 0; i < apiData5.length; i++) {
          arr4.push({
            date: moment(apiData5[i]?.subscriptionStartDate).format("MM-DD-YY"),
            type: "Total Guardian Users",
            count:
              apiData5[i]?.totalGuardiansUsers === 0
                ? null
                : apiData5[i]?.totalGuardiansUsers,
          });
        }
        for (let i = 0; i < apiData5.length; i++) {
          arr5.push({
            date: moment(apiData5[i]?.subscriptionStartDate).format("MM-DD-YY"),
            type: "Total Free Trial Users",
            count:
              apiData5[i]?.totalFreeTrialUsers === 0
                ? null
                : apiData5[i]?.totalFreeTrialUsers,
          });
        }
        arr6 = arr3.concat(arr4, arr5);
        setResponseGet6(arr6);
        setResponseGet5(arr2);

        apiData5.forEach((element) => {
          arr7 += element.totalPaidUsers;
        });
        apiData5.forEach((element) => {
          arr8 += element.totalGuardiansUsers;
        });
        apiData5.forEach((element) => {
          arr9 += element.totalFreeTrialUsers;
        });
        setResponseGet8(arr7);
        setResponseGet9(arr8);
        setResponseGet10(arr9);
      } catch (err) {
        console.log(err);
      }
    }
    TotalActiveSOSCount(responseGet);
    totalNewExistingtrialUserCount(responseGet2);
    totaPaidUserGainLoss(responseGet3);
    TotalCountAsOf(responseGet4);
    totalCountBetweenDateRange(responseGet5);
  }, [startDate2, startDate3]);

  const headers = [
    { label: "Total Users", key: "totalUsers" },
    { label: "New Free Trial Users", key: "newFreeTrialUsers" },
    { label: "New Paid Users", key: "newPaidUsers" },
    { label: "Total Paid Users", key: "totalPaidUsers" },
  ];

  const csvReport = {
    data: tillDateArr,
    headers: headers,
    filename: "current_day_user_data.csv",
  };

  return (
    <Layout>
      <div className="dashboard">
        <div className="card gradient-lay">
          <div className="card-head">
            <div className="header-data">
              <div className="form-group">
                <label>
                  <strong>As of</strong>
                </label>
                <div className="date-space">
                  <DatePicker
                    className="btn btn-white btn-lg datepicker text-center"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    disabled
                  />
                  {/* <i className="icon-calendar"></i> */}
                </div>
              </div>

              {tillDateArr?.length > 0 ? (
                <CSVLink
                  type="button"
                  className="btn btn-white btn-icon btn-lg btn-right ms-auto"
                  {...csvReport}
                >
                  <span>Download </span> <i className="icon-download"></i>
                </CSVLink>
              ) : null}
            </div>
          </div>
          <div className="card-body">
            <div className="report">
              <div className="report-box">
                <div className="data">
                  <p>Total Users</p>
                  <h4>{responseGet4?.totalUsers}</h4>
                </div>
                <div className="right-icon">
                  <i className="icon-dashboard"></i>
                </div>
              </div>
              <div className="report-box">
                <div className="data">
                  <p>Total Paid Users</p>
                  <h4>{responseGet4?.totalPaidUsers}</h4>
                </div>
                <div className="right-icon">
                  <i className="icon-dashboard"></i>
                </div>
              </div>
              <div className="report-box">
                <div className="data">
                  <p>New Paid Users</p>
                  <h4>{responseGet4?.newPaidUsers}</h4>
                </div>
                <div className="right-icon">
                  <i className="icon-dashboard"></i>
                </div>
              </div>
              <div className="report-box">
                <div className="data">
                  <p>New Free Trial Users</p>
                  <h4>{responseGet4?.newFreeTrialUsers}</h4>
                </div>
                <div className="right-icon">
                  <i className="icon-dashboard"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-data card-outer">
          <h1 className="title-heading">Date Range</h1>
          <div className="date-space">
            <DatePicker
              className="btn btn-white btn-lg datepicker"
              selected={startDate2}
              onChange={(value) => setStartDate2(value)}
            />
            <i className="icon-calendar"></i>
          </div>
          <div className="date-space">
            <DatePicker
              className="btn btn-white btn-lg datepicker"
              selected={startDate3}
              onChange={(value) => setStartDate3(value)}
            />
            <i className="icon-calendar"></i>
          </div>
        </div>
        <div className="card gradient-lay">
          <TotalUsers
            responseGet6={responseGet6}
            responseGet9={responseGet9}
            responseGet8={responseGet8}
            responseGet10={responseGet10}
            responseGet7={responseGet7}
          />

          <TotalPaidUsers responseGet5={responseGet5} />

          {/* <PaidUsersGain responseGet3={responseGet3} /> */}

          <TotalActiveSos responseGet={responseGet} />

          <FreeTrialUsers responseGet2={responseGet2} />
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
