import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
function CustomSelect(props) {
  const [defaultSelectText, setDefaultSelectText] = useState(() => "");
  const [, setDefaultSelectImage] = useState(() => "");
  const [showOptionList, setShowOptionList] = useState(() => false);
  const dispatch = useDispatch();
  const { dropState } = useSelector((state) => state.dropdownData);
  const { optionsList } = props;
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    setDefaultSelectText(props.defaultText);
    setDefaultSelectImage(props.defaultImage);
    dispatch({ type: "DROPDOWN", payload: "" })
  }, [props.defaultText, props.defaultImage, props.filterType]);

  const handleClickOutside = (e) => {
    if (
      !e.target.classList.contains("custom-select-option") &&
      !e.target.classList.contains("selected-text")
    ) {
      setShowOptionList(false);
      dispatch({ type: "DROPDOWN", payload: "" })

    }
  };
  const handleListDisplay = () => {
    dispatch({ type: "DROPDOWN", payload: { value: defaultSelectText == dropState?.value ? "" : defaultSelectText } })
  };
  const handleOptionClick = (e) => {
    setDefaultSelectText(e.target.getAttribute("data-name"));
    setDefaultSelectImage(e.target.getAttribute("data-icon"));
    setShowOptionList(false);
    props.changeValue(e.target.getAttribute("data-value"))
    props.changePage(1)
  };

  return (
    <div className="custom-select-container">
      <div
        className={showOptionList ? "selected-text active" : "selected-text"}
        onClick={() => handleListDisplay()}
      >
        {defaultSelectText}
      </div>
      {(dropState?.value === defaultSelectText) && (
        <ul
          className="select-options p-0 m-0 icon-fixed-size"
          data={defaultSelectText}
        >
          {optionsList.map((option) => {
            return (
              <li
                className="custom-select-option d-flex justify-content-between"
                data-name={option.name}
                data-icon={option.icon}
                key={option.id}
                data-value={option.id}
                onClick={handleOptionClick}
              >
                {option.name}
                <i className={option.icon} name={option.name}></i>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
export default CustomSelect;
