import React, { useEffect, useState } from "react";
import Layout from "../../../layout";
import { Breadcrumb } from "react-bootstrap";
import BreadCrumb from "../breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { getSubscriptionHistory } from "../../../../services/api/endpoints";
import axiosInstance from "../../../../services/middleware";
import moment from "moment/moment";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { decryptRequest } from "../../../../services/helpers/crypto";

const SubscriptionHistory = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profileData);
  const [responseGet, setResponseGet] = useState(null);

  useEffect(() => {
    async function subscriptionData() {
      try {
        const response = await axiosInstance.get(
          `${getSubscriptionHistory}/${profile?.id}`
        );
        const apiData = response.data.data;
        setResponseGet(apiData);
      } catch (err) {
        console.log(err);
      }
    }
    subscriptionData();
  }, []);
  const onBackClick = () => {
    if (location?.state?.id == "archived") {
      dispatch({ type: "IS_ARCHIVE", payload: true });
    } else {
      dispatch({ type: "IS_ARCHIVE", payload: false });
    }

    navigate(`/user-management`, {
      state: {
        id: location?.state?.id == "archived" ? location?.state?.id : null,
      },
    });
  };
  return (
    <Layout>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/user-management" onClick={() => onBackClick()}>
            {location?.state?.id == "archived" ? "Archive Users" : "All Users"}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {decryptRequest(profile?.name)}'s Subscription History
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="top-filter">
        <div className="row">
          <div className="col-md-12">
            <BreadCrumb
              setHeaderState={props.setHeaderState}
              headerState={props.headerState}
            />
          </div>
        </div>
      </div>
      <div className="bg-outer">
        {/* subscription list 1*/}
        {responseGet?.length ? (
          responseGet.map((subscription, index) => {
            return (
              <div className="card subscription-list" key={index}>
                <div className="card-body d-flex align-items-center">
                  <div className="subscription-icon text-center text-light me-4 flex-shrink-0">
                    <i className="icon-user fs-20"></i>
                  </div>
                  {/* details */}

                  <>
                    <ul className="subscription-detail mb-0 ps-0">
                      <li>
                        <h6 className="mb-1 fs-13 fw-bold">
                          {profile?.userType == "PersonalAccount"
                            ? "Personal Account"
                            : "Guardian Account"}
                          {/* {subscription?.cancelledStatus === false} */}
                          {subscription?.cancelledStatus === false &&
                            subscription.subscriptionType === 1 ?
                          <span
                            className={"plan-status  fst-italic fw-semibold"}
                            style={{ color: "#5370f6" }}
                          >
                            {subscription?.cancelledStatus === false &&
                            subscription.subscriptionType === 1
                              ? `Free Trial Expiration : ${moment
                                  .utc(subscription?.expirtedAt)
                                  .local()
                                  .format("MM-DD-YYYY")}`
                              : null}
                          </span> :null}
                          {subscription?.cancelledStatus === false ?
                          <span
                            className={
                              "plan-status active fst-italic fw-semibold"
                            }
                          >
                            {subscription?.cancelledStatus === false ? (
                              <>
                                {subscription.subscriptionType === 3 ||
                                subscription.subscriptionType === 7
                                  ? `Subscription Expiration : ${moment
                                      .utc(subscription?.expirtedAt)
                                      .local()
                                      .format("MM-DD-YYYY")}`
                                  : null}
                              </>
                            ) : null}
                          </span> :null}
                          {subscription?.cancelledStatus === true ? 
                          <span
                            className={
                              subscription?.cancelledStatus == false
                                ? "plan-status active fst-italic fw-semibold"
                                : "plan-status cancel fst-italic fw-semibold"
                            }
                          >
                            {subscription?.cancelledStatus === true
                              ? `Cancelled`
                              : null}
                          </span> : null}
                        </h6>
                      </li>
                      <li>
                        <h6 className="mb-1 fst-italic fs-13 fw-semibold">
                          {subscription.subscriptionType === 1
                            ? "Free Subscription"
                            : null}{" "}
                          {subscription.subscriptionType === 3
                            ? "Monthly Subscription"
                            : null}{" "}
                          {subscription.subscriptionType === 7
                            ? "Annual Subscription"
                            : null}{" "}
                          | {subscription.storageType === 1 ? "2 GB" : null}{" "}
                          {subscription.storageType === 2 ? "10 GB" : null}
                        </h6>
                      </li>
                      <li>
                        <p className="date-time mb-0 fs-12">
                          <i className="icon-calendar me-2"></i>
                          {moment
                            .utc(subscription?.createdAt)
                            .local()
                            .format("MM-DD-YYYY")}
                        </p>
                      </li>
                    </ul>

                    {subscription.subscriptionType === 1 &&
                    subscription?.cancelledStatus === false ? (
                      <button
                        type="button"
                        // className="btn ms-auto btn-xl btn-round btn-color px-4"
                        className="btn ms-auto btn-xl btn-round btn-color px-4"
                      >
                        Free
                      </button>
                    ) : null}
                    {subscription.subscriptionType === 3 &&
                    subscription?.cancelledStatus === false ? (
                      <button
                        type="button"
                        // className="btn ms-auto btn-xl btn-round btn-color px-4"
                        className="btn ms-auto btn-xl btn-round btn-color px-4"
                      >
                        Paid
                      </button>
                    ) : null}
                    {subscription.subscriptionType === 7 &&
                    subscription?.cancelledStatus === false ? (
                      <button
                        type="button"
                        // className="btn ms-auto btn-xl btn-round btn-color px-4"
                        className="btn ms-auto btn-xl btn-round btn-color px-4"
                      >
                        Paid
                      </button>
                    ) : null}
                    {subscription?.cancelledStatus === true ? (
                      <button
                        type="button"
                        // className="btn ms-auto btn-xl btn-round btn-color px-4"
                        className="btn ms-auto btn-xl btn-round btn-color px-4 cancel"
                      >
                        Cancelled
                      </button>
                    ) : null}
                  </>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center">
            No Subscription Details found for this user
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SubscriptionHistory;
