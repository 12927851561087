import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const ChangePassword = (props) => {
  // password eye button addition
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordType3, setPasswordType3] = useState("password");

  const [validCurrentPassword, setValidCurrentPassword] = useState(true);
  const [validNewPassword, setValidNewPassword] = useState(true);

  const [validConfirmPassword, setValidConfirmPassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");

  const [disableButton, setDisableButton] = useState(true);
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };
  const togglePassword3 = () => {
    if (passwordType3 === "password") {
      setPasswordType3("text");
      return;
    }
    setPasswordType3("password");
  };
  const { closeModal } = props;
  const handleClick = (event) => {
    closeModal();
  };

  const validatingFunction = () => {
    if (props.currentPassword == "" || props.newPassword == "") {
      setDisableButton(true);
      setValidCurrentPassword(true);
      setValidNewPassword(true);
      setValidConfirmPassword(true);
    } else {
      setDisableButton(false);
      setValidCurrentPassword(false);
      setValidNewPassword(false);
      setValidConfirmPassword(false);
    }
    if (
      props.currentPassword.length <= 7 &&
      props.currentPassword.length != ""
    ) {
      setValidCurrentPassword(false);
    } else {
      setValidCurrentPassword(true);
    }
    if (props.newPassword.length <= 7 && props.newPassword.length != "") {
      setValidNewPassword(false);
    } else {
      setValidNewPassword(true);
    }
  };

  useEffect(() => {
    validatingFunction();
  }, [props.currentPassword, props.newPassword]);

  return (
    <>
      <div className="profile-title mb-5">
        <h3 className="title-heading text-primary mb-0">Security Settings</h3>
      </div>
      <div className="change-pass">
        <div className="mb-4">
          <h3 className="title-heading">Change Password</h3>
          <p>Change the password that you use to sign-in</p>
        </div>

        <Form>
          <div className="mb-3 pass-field">
            <label className="form-label">Current Password</label>
            <div className="position-relative">
              <input
                type={passwordType}
                className="form-control"
                name="CurrentPassword"
                placeholder="Current Password"
                autoComplete="off"
                onChange={(event) =>
                  props.setCurrentPassword(event.target.value)
                }
              />
              <button type="button" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <i className="icon-eye-slash"></i>
                ) : (
                  <i className="icon-eye"></i>
                )}
              </button>
            </div>
          </div>

          <div className="mb-3 pass-field">
            <label className="form-label">New Password</label>
            <div className="position-relative">
              <input
                type={passwordType2}
                className="form-control"
                name="CurrentPassword"
                placeholder="New Password"
                autoComplete="off"
                onChange={(event) => props.setNewPassword(event.target.value)}
              />
              <button type="button" onClick={togglePassword2}>
                {passwordType2 === "password" ? (
                  <i className="icon-eye-slash"></i>
                ) : (
                  <i className="icon-eye"></i>
                )}
              </button>
            </div>
            {validNewPassword == false ? (
              <p className="error text-wrap">
                Password should be of 8 characters
              </p>
            ) : null}
          </div>

          <div className="mb-3 pass-field">
            <label className="form-label">Confirm New Password</label>
            <div className="position-relative">
              <input
                type={passwordType3}
                className="form-control"
                name="CurrentPassword"
                placeholder="Confirm New Password"
                onChange={(event) => setConfirmPassword(event.target.value)}
                autoComplete="off"
              />
              <button type="button" onClick={togglePassword3}>
                {passwordType3 === "password" ? (
                  <i className="icon-eye-slash"></i>
                ) : (
                  <i className="icon-eye"></i>
                )}
              </button>
            </div>
            {confirmPassword.length != 0 &&
            confirmPassword != props.newPassword ? (
              <p className="error text-wrap">
                New Password must be same as Confirm password
              </p>
            ) : null}
          </div>

          <div className="submit-button-group w-100 text-center">
            {props?.loading === true ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <button
                type="submit"
                className="btn btn-primary btn-xxl round"
                onClick={props.handleSubmit}
                disabled={disableButton}
              >
                Save Changes
              </button>
            )}
            <button
              type="button"
              className="btn btn-transparent"
              onClick={handleClick}
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ChangePassword;
