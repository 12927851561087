import { message } from "antd";
import moment from 'moment-timezone';
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { downloadUserTimeline } from "../../services/api/endpoints";
import axiosInstance from "../../services/middleware";
import { regionFromTimezone } from "../../services/helpers/regionFromTimeZone";

const TimelineModal = (props) => {
  var d = new Date();
  const { profile } = useSelector((state) => state.profileData);

  const [startDate2, setStartDate2] = useState(
    moment.utc().local(d.setDate(d.getDate())).format("MM-DD-YYYY")
  );
  const [startDate3, setStartDate3] = useState(
    moment.utc().local(d.setDate(d.getDate())).format("MM-DD-YYYY")
  );
  const [loading, setLoading] = useState(false);


  const onDownloadCLick = async () => {
    setLoading(true)
    let payload = {
      userId: profile?.id,
      userName: profile?.name +" " +profile?.lName,
      FirstName: profile?.name,
      LastName:profile?.lName,
      startDate: moment(startDate2).format(
        "MM-DD-YYYYThh:mm:ss"
      ),
      endDate: moment(startDate3).format(
        "MM-DD-YYYYThh:mm:ss"
      ),
      region:regionFromTimezone(moment.tz.guess()),
    };
    try {
      const response = await axiosInstance.post(`${downloadUserTimeline}`, payload);
      const apiData = response;
      setLoading(false)
      props.showHandler(false);
      message.success(apiData?.data?.message);
      
    } catch (err) {
      setLoading(false)
      
      message.error(err)
    }
  };

  return (
    <Modal show={props.showmodal} size="md" className="custom-modal" centered>
      <Modal.Header>
        <Modal.Title>Download</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => {
            props.showHandler(false);
          }}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline-download">
          <p>Download all files for a specific date range</p>

          <div className="form-group date-selection">
            <div className="date-space">
              <DatePicker
                className="form-control datepicker"
                selected={new Date(startDate2)}
                placeholderText="Date (From)"
                onChange={(value) => setStartDate2(value)}
              />
            </div>
            <div className="date-space">
              <DatePicker
                className="form-control datepicker"
                selected={new Date(startDate3)}
                placeholderText="Date (From)"
                onChange={(value) => setStartDate3(value)}
              />
            </div>
          </div>
          <div className="text-center">
          {loading === true ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            </div>
          </div>
        ) : (
            <button
              className="btn btn-primary btn-xxl btn-round d-block m-auto btn-shadow"
              onClick={() => onDownloadCLick()}
            >
              Download <i className="icon-download"></i>
            </button>
        )}
            <button
              className="btn"
              onClick={() => {
                props.showHandler(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TimelineModal;
