import React, { useState, useEffect, useRef } from "react";
import Images from "../Images";
import validator from "validator";
import { useSelector, useDispatch } from "react-redux";
import { loginAdminPanel } from "../../actions/loginAction";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPasssword] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [blankEmail, setBlankEmail] = useState(false);
  const [firstClick, setFirstClick] = useState(1);

  const { loginData } = useSelector((state) => state.adminLoginData);
  useEffect(() => {
    const { data, httpStatus, message, success } = loginData;

    if (httpStatus === 200 && success === true) {
      if (!localStorage.getItem("adminLoginToken")) {
        localStorage.setItem("adminLoginToken", data.accessToken);
        const email = data.email;
        const fullName = data.name;
        const user = { email: email, fullName: fullName };
        localStorage.setItem("user", JSON.stringify(user));
        if (data?.roleType === 3) {
          navigate("/dashboard");
          dispatch({ type: "PAGE_ROUTE_DATA", payload:"dashboard"  })

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Login Successful",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            showConfirmButton: true,
            text: "This id is not authorized",
          });
          dispatch({ type: "RESET_STATE" });
        }
        
      }
    } else if (success === false) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        showConfirmButton: true,
        text: message,
      });
      dispatch({ type: "RESET_STATE" });
    }
  }, [loginData, navigate]);

  const onFieldChange = (event) => {
    let target = event.target;
    let name = target.name;
    let value = target.value;

    if (name === "email") {
      setEmail(value);
      if (value != "" && !validator.isEmail(value)) {
        setBlankEmail(false);
        setValidEmail(false);
      }
      if (value != "" && validator.isEmail(value)) {
        setBlankEmail(false);
        setValidEmail(true);
      }
    }

    if (name === "password") {
      setPassword(value);
      if (value != "") {
        setValidPasssword(true);
      }
    }
  };
  const onFieldError = (event) => {
    event.preventDefault();

    let target = event.target;
    let name = target.name;
    let value = target.value;

    setFirstClick(2);

    if (name === "email" && value && validator.isEmail(value) && value != "") {
      setEmail(value);
      setValidEmail(true);
      setDisableButton(false);
      setBlankEmail(false);
    }
    if (name === "email" && value == "") {
      setEmail(value);
      setValidEmail(false);
      setDisableButton(true);
      setBlankEmail(true);
    }
    if (
      name === "password" &&
      value != ""
    ) {
      setPassword(value);
      setValidPasssword(true);
    } else if (
      name === "password" &&
      value == ""
    ) {
      setPassword(value);
      setValidPasssword(false);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!email) {
  
      setDisableButton(true);
    } else if (!password) {

      setDisableButton(true);
    } else {
      setLoading(true);

      dispatch(loginAdminPanel(email, password));
      if (loginData !== "") {
        navigate("/dashboard");
        dispatch({ type: "PAGE_ROUTE_DATA", payload:"dashboard"  })

      }
    }
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const prevCountRef = useRef();
  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevCountRef.current = email;
  }, [email]);
  return (
    <div className="layout-auth">
      <div className="logo-header">
        <img src={Images.LOGO} alt="logo" />
      </div>
      {/* Left banner column */}
      <div className="left-col">
        <div className="banner-image">
          <img src={Images.LOGINBANNER} alt="logo" />
        </div>
      </div>

      {/* Right Banner Login Page */}
      <div className="right-col">
        <div className="card">
          <div className="card-body">
            <h1 className="heading-two">Sign In</h1>
            <form
              className="auth-form"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="mb-3">
                <label className="form-label">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email Address"
                  onChange={(event) => onFieldChange(event)}
                  onBlur={(event) => onFieldError(event)}
                  autoComplete="off"
                />

                {validEmail == false &&
                email !== null &&
                blankEmail == false &&
                firstClick != 1 ? (
                  <p className="error text-wrap">Please enter valid email id</p>
                ) : null}

                {blankEmail == true ? (
                  <p className="error text-wrap">Email is required.</p>
                ) : null}
              </div>
              <div className="mb-3 pass-field">
                <label className="form-label">Password</label>
                <div className="position-relative">
                  <input
                    type={passwordType}
                    className="form-control"
                    name="password"
                    onChange={(event) => onFieldChange(event)}
                    onBlur={(event) => onFieldError(event)}
                    placeholder="Password"
                    autoComplete="off"
                  />
                  <button type="button" onClick={togglePassword}>
                    {passwordType === "password" ? (
                      <i className="icon-eye-slash"></i>
                    ) : (
                      <i className="icon-eye"></i>
                    )}
                  </button>
                </div>
                {validPassword === false && password !== null ? (
                  <p className="error text-wrap">Password is required</p>
                ) : null}
              </div>
              <div className="check-labels">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="remember"
                  />

                  <label className="form-check-label" htmlFor="remember">
                    Remember Me
                  </label>
                </div>
                <Link className="forgot-link" to="/forgot-password">
                  Forgot password ?
                </Link>
              </div>
              <button
                // disabled={disableButton}
                type="submit"
                className="btn btn-primary signin-btn"
                data-loading-text={
                  loading === true
                    ? "<i className='icon-spinner icon-spin icon-large'></i> Loading"
                    : null
                }
              >
                {loading === true ? (
                  <>
                    <span
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>

                    <span className="sr-only">Loading...</span>
                  </>
                ) : (
                  <div>Sign In</div>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
