import Images from "../Images";
import { useDispatch } from "react-redux";
import { useState } from "react";
import MyProfile from "../MyProfile";

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    setIsActive((current) => !current);
  };



  return (
    <>
      <div className="main-header">
        <div className="searchbar">
       
        </div>
 


        <div className="profile-btn cursor-pointer" onClick={handleClick}>
          <div className="img-box">
            <img src={Images.ADMINPIC} alt="profile" />
          </div>
          <p>Admin</p>
        </div>

      </div>
      <div className={isActive ? "d-block shadow" : "shadow"}></div>
      <div className={isActive ? "show profile-bar" : "profile-bar"}>
        <div className="bar-header">
          <div className="profile-title">
            <h3 className="title-heading mb-0">Profile</h3>
            <button
              type="button"
              className="close-bar btn-transparent"
              onClick={handleClick}
            >
              <i className="icon-cross"></i>
            </button>
          </div>
        </div>
        <div className="bar-body">
          <MyProfile closeModal={handleClick} />
        </div>
      </div>
    </>
  );
};

export default Header;
