import React  from "react";
import TotalActiveSosGraphComponent from "./TotalActiveSosGraphComponent";
import { CSVLink } from "react-csv";


const TotalActiveSos= ({responseGet}) => {

  const headers = [
    { label: "Date", key: "date" },
    { label: "Type", key: "type" },
    { label: "Count", key: "count" },
  ];
  const csvReport = {
    data: responseGet,
    headers:headers,
    filename: "total_active_sos.csv",
  };

  return (
    <div>
      <div className="card-head">
        <div className="header-data">
          <h1 className="title-heading">Total Active SOS</h1>
          {responseGet?.length > 0 ? (
                <CSVLink
                  type="button"
                  className="btn btn-white btn-icon btn-lg btn-right ms-auto"
                  {...csvReport}
                >
                  <span>Download </span> <i className="icon-download"></i>
                </CSVLink>
              ) : null}
        </div>
      </div>
      <div className="card-body">
        <TotalActiveSosGraphComponent responseGet={responseGet}/>
      </div>
    </div>
  );
};
export default TotalActiveSos;
