import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import SosBreadcrumb from "../sosBreadcrumb/SosBreadcrumb";
import DatePicker from "react-datepicker";

import moment from "moment/moment";
import { Popover } from "antd";
import { getSosHistory } from "../../../../services/api/endpoints";
import axiosInstance from "../../../../services/middleware";
import { debounce } from "lodash";
import SosHistoryDetails from "./SosHistoryDetails";
import Pagination from "react-responsive-pagination";
import { CSVLink } from "react-csv";
import { decryptRequest } from "../../../../services/helpers/crypto";

const SosHistory = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [payloadDate, setPayloadDate] = useState(" "
  );

  const [responseGet, setResponseGet] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [pageState, setPageState] = useState(0);
  const [selectedSos, setSelecetedSos] = useState(null);

  const [csvFormat, setCsvFormat] = useState(null);

  // for new pagination start

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [dataPage, setDataPage] = useState(20);
  function handlePageChange(page) {
    setCurrentPage(page);
    // ... do something with `page`
  }

  // for new pagination end
  const onSearchChange = debounce((event) => {
    setSearchParam(event.target.value);
    setCurrentPage(1);
  }, 500);

  useEffect(() => {
    async function sosHistoryData() {
      try {
        const response = await axiosInstance.get(
          `${getSosHistory}/${currentPage}/${dataPage}/${
            payloadDate === " "
              ? " "
              : moment(payloadDate).local().format("MM-DD-YYYYThh:mm:ss")
          }/${searchParam}`

        );
        const apiData = response.data.data;
        if (apiData?.result?.length > 0) {
          let cnt = Math.ceil(apiData?.totalCount / dataPage);
          setTotalPages(cnt);
        }
        setResponseGet(apiData);
       
      } catch (err) {
        console.error("network Error");
      }
    }

    sosHistoryData();
  }, [searchParam?.length, currentPage, payloadDate]);

useEffect(() => {
  async function allSosHistoryData() {
    try {
      const response = await axiosInstance.get(
        `${getSosHistory}`
      );
      const apiData = response.data.data;
    
      const formattedResponse = apiData?.result?.map((response) => {
        const formattedDate = moment
          .utc(response?.startDate)

          .format("MM-DD-YYYY");
        const formattedTime = moment
          .utc(response?.startDate?.slice(0, 10) + "T" + response?.startTime)
          .local()

          .format("h:mm A");
        const formattedEndDate = moment
          .utc(response?.endDate)

          .format("MM-DD-YYYY");
        const formattedEndTime = moment
          .utc(response?.endDate?.slice(0, 10) + "T" + response?.endTime)
          .local()

          .format("h:mm A");
        const formatPhone = formatPhoneNumber(decryptRequest(response?.phone));
        const startAddress = response.startLocation?.replace(
          /,(?=\s*\d)/,
          " "
        );
        const endAddress = response.endLocation?.replace(/,(?=\s*\d)/, " ");

        return {
          ...response,
          startDate: formattedDate,
          startTime: formattedTime,
          endDate: formattedEndDate,
          endTime: formattedEndTime,
          phone: formatPhone,
          startLocation: startAddress,
          endLocation: endAddress,
          fName:decryptRequest(response?.fName),
          lName:decryptRequest(response?.lName),
          email:decryptRequest(response?.email)


        };
      });
      setCsvFormat(formattedResponse);
    } catch (err) {
      console.error("network Error");
    }
  }

  allSosHistoryData();
}, [])


  const onDateChange = (date) => {
    let dateFormat = moment.utc(date).local().format("YYYY-MM-DD");
    setStartDate(date);
    setPayloadDate(dateFormat);
  };

  const onClearDateClick = () => {
    setPayloadDate(" ");
    setStartDate(new Date());

  };

  const onSosCLick = (sos) => {
    setPageState(1);
    setSelecetedSos(sos);
  };

  const headers = [
    { label: "Start Date", key: "startDate" },
    { label: "First Name", key: "fName" },
    { label: "Last Name", key: "lName" },
    { label: "Start Time", key: "startTime" },
    { label: "End Time", key: "endTime" },
    { label: "Email", key: "email" },
    { label: "Mobile Number", key: "phone" },
    { label: "Gender", key: "gender" },
    { label: "Age", key: "age" },
    { label: "Start Location", key: "startLocation" },
    { label: "End Location", key: "endLocation" },
    { label: "End Date", key: "endDate" },

    // { label: "SOS Active", key: "activeSOS" },
    // { label: "Address 1", key: "addressLine1" },
    // { label: "Address 2", key: "addressLine2" },
    // { label: "latitude", key: "lattitude" },
    // { label: "longitude", key: "longitude" },
  ];

  const csvReport = {
    data: csvFormat,
    headers: headers,
    filename: "sos_history_list.csv",
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  return (
    <>
      {pageState === 0 ? (
        <Layout>
          <div className="top-filter">
            <div className="row">
              <div className="col-md-12">
                <SosBreadcrumb
                  setHeaderState={props.setHeaderState}
                  headerState={props.headerState}
                />
              </div>
            </div>
          </div>
          <div className="top-filter">
            <div className="row main-header mb-0 gx-0 px-5 py-3 bg-transparent">
              <div className="col-md-4">
                <h1 className="title-heading mb-0">SOS History</h1>
              </div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col">
                    <div className="searchbar placeholder-light">
                      <input
                        type="text"
                        className="form-control py-2"
                        name="search"
                        placeholder="Search by name, email, phone number"
                        onChange={onSearchChange}
                      />

                      <i className="icon-search"></i>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="header-data">
                      <div className="date-space position-relative cursor-pointer">
                        <DatePicker
                          className="btn btn-white btn-lg datepicker"
                          selected={startDate}
                          onChange={(date) => onDateChange(date)}
                        />
                        <i className="icon-calendar"></i>
                      </div>
                      <i className="icon-cross m-2 " style={{right:"3.2rem",cursor:"pointer"}} onClick={()=>onClearDateClick()}></i>
                    </div>
                  </div>
                  <div className="col-auto">
                    {csvFormat?.length > 0 ? (
                      <CSVLink
                        type="button"
                        className="btn btn-white btn-icon btn-lg btn-right btn-round"
                        {...csvReport}
                      >
                        <span>Download </span>{" "}
                        <i className="icon-download me-2"></i>
                      </CSVLink>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-outer table-responsive">
            <table className="table seperate-column">
              <thead>
                <tr>
                  <td>Start Date</td>
                  <td>Name</td>
                  <td>Start Time</td>
                  <td>End Time</td>
                  <td>Email Address</td>
                  <td>Mobile Number</td>
                  <td>Gender</td>
                  <td>Age</td>
                  <td>Start Location</td>
                  <td>End Location</td>
                  <td>End Date</td>
                </tr>
              </thead>
              <tbody>
                {responseGet ? (
                  responseGet?.result?.map((sos, index) => {
                    return (
                      <tr
                        onClick={() => onSosCLick(sos)}
                        className="cursor-pointer"
                        key={index}
                      >
                        <td>
                          <span onClick={() => onSosCLick(sos)}>
                            {sos?.startDate === null
                              ? "- -"
                              : moment
                                  .utc(sos?.startDate)
                                  .local()
                                  .format("MM-DD-YYYY")}
                          </span>
                        </td>
                        <td>
                          <span onClick={() => onSosCLick(sos)}>
                            {sos?.fName && sos?.lName === null
                              ? "- -"
                              : decryptRequest(sos?.fName) + " " + decryptRequest(sos?.lName)}
                          </span>
                        </td>
                        <td>
                          {moment
                            .utc(
                              sos?.startDate?.slice(0, 10) +
                                "T" +
                                sos?.startTime
                            )
                            .local()

                            .format("h:mm A")}
                        </td>
                        <td>
                          {sos?.endDate != null
                            ? moment
                                .utc(
                                  sos?.endDate?.slice(0, 10) +
                                    "T" +
                                    sos?.endTime
                                )
                                .local()

                                .format("h:mm A")
                            : "- -"}
                        </td>
                        <td>{sos?.email === null ? "- -" : decryptRequest(sos?.email)}</td>
                        <td>
                          {sos?.phone === null
                            ? "- -"
                            : formatPhoneNumber(decryptRequest(sos?.phone))}
                        </td>
                        <td>
                          {sos?.gender === null || sos?.gender === ""
                            ? "- -"
                            : sos?.gender}
                        </td>
                        <td>{sos?.age === null ? "- -" : sos?.age}</td>
                        <td>
                          <Popover
                            content={sos?.startLocation?.replace(
                              /,(\d)/g,
                              " $1"
                            )}
                          >
                            <p className="ellipsis mb-0 mx-auto">
                              {" "}
                              {sos?.startLocation === null
                                ? "- -"
                                : sos?.startLocation}
                            </p>
                          </Popover>
                        </td>
                        <td>
                          <p className="ellipsis mb-0 mx-auto">
                            {" "}
                            <Popover
                              content={sos?.endLocation?.replace(
                                /,(\d)/g,
                                " $1"
                              )}
                            >
                              <p className="ellipsis mb-0 mx-auto">
                                {" "}
                                {sos?.endLocation === null
                                  ? "- -"
                                  : sos?.endLocation}
                              </p>
                            </Popover>
                          </p>
                        </td>
                        <td>
                          {sos?.endDate === null
                            ? "- -"
                            : moment
                                .utc(sos?.endDate)
                                .local()
                                .format("MM-DD-YYYY")}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">No SOS History found </tr>
                )}
              </tbody>
            </table>
            {responseGet?.result?.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                onPageChange={(page) => handlePageChange(page)}
                maxWidth={10}
                previousLabel="< Prev"
                nextLabel="Next >"
                narrowStrategy="dropEllipsis"
              />
            ) : <Pagination
            // {...bootstrap5PaginationPreset} // include Bootstrap 5 preset
            total={1}
            current={1}
            // onPageChange={(page) => handlePageChange(page)}
            maxWidth={3}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
            // showLimit={5}
          />}
          </div>
        </Layout>
      ) : (
        <SosHistoryDetails
          setPageState={setPageState}
          selectedSos={selectedSos}
        />
      )}
    </>
  );
};

export default SosHistory;
