import React, { useState, useEffect, useId } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../../layout";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/middleware";
import { blockUser, getUserList } from "../../../services/api/endpoints";
import BlockUserModal from "../../modal/blockUser";
import { debounce } from "lodash";
import { message, Popover } from "antd";
import Pagination from "react-responsive-pagination";
import { CSVLink } from "react-csv";
import {
  decryptRequest,
  encryptRequest,
} from "../../../services/helpers/crypto";
// import { decryptRequest } from "../../../services/helpers/crypto";

const UserManagement = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id1 = useId();
  const id2 = useId();

  const [responseGet, setResponseGet] = useState([]);
  const [activeFilter, setActiveFilter] = useState(0);
  const [typeFilter, setTypeFilter] = useState(0);
  const [isActive, setIsActive] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [noRecord, setNoRecord] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [downloadData, setDownloadData] = useState([]);
  const { pageNo } = useSelector((state) => state.pageNoData);
  const { isArchive } = useSelector((state) => state.pageNoData);

  // for new pagination start

  const [currentPage, setCurrentPage] = useState(pageNo);
  const [totalPages, setTotalPages] = useState(0);
  const [dataPage, setDataPage] = useState(20);
  function handlePageChange(page) {
    setCurrentPage(page);
    // ... do something with `page`
  }

  // for new pagination end

  useEffect(() => {
    if (isArchive == true) {
      props.setUserHeadState(2);
    }

    async function userData() {
      try {
        const response = await axiosInstance.get(
          `${getUserList}/${currentPage}/${dataPage}/${activeFilter}/${typeFilter}/${searchParam}`
        );

        const apiData = response.data.data;
        if (apiData?.result?.length > 0) {
          dispatch({ type: "IS_ARCHIVE", payload: false });

          let cnt = Math.ceil(apiData?.totalCount / dataPage);
          setTotalPages(cnt);
        }
        setResponseGet(apiData);
        setNoRecord(false);
      } catch (err) {
        // alert("network Error");
        setNoRecord(true);
      }
    }

    userData();
  }, [isActive, activeFilter, typeFilter, searchParam, currentPage]);
  useEffect(() => {
    totalUsers();
  }, []);

  const allUsers = async () => {
    try {
      const response = await axiosInstance.get(
        `${getUserList}/${currentPage}/${dataPage}/${activeFilter}/${typeFilter}/${searchParam}`
      );

      const apiData = response.data.data;
      if (apiData?.result?.length > 0) {
        dispatch({ type: "IS_ARCHIVE", payload: false });

        let cnt = Math.ceil(apiData?.totalCount / dataPage);
        setTotalPages(cnt);
      }
      setResponseGet(apiData);
      setNoRecord(false);
    } catch (err) {
      // alert("network Error");
      setNoRecord(true);
    }
  };
  const totalUsers = async () => {
    try {
      const response = await axiosInstance.get(`${getUserList}`);

      const apiData = response.data.data.result;
      if (apiData?.length > 0) {
        // dispatch({ type: "IS_ARCHIVE", payload: false });

        // let cnt = Math.ceil(apiData?.totalCount / dataPage);
        // setTotalPages(cnt);
        if (apiData?.length > 0) {
          let arr2 = [];
          for (let i = 0; i < apiData.length; i++) {
            arr2.push({
              lName: decryptRequest(apiData[i]?.lName),
              age: apiData[i]?.age,
              gender: apiData[i]?.gender,
              name: decryptRequest(apiData[i]?.name),
              phone: formatPhoneNumber(decryptRequest(apiData[i]?.phone)),
              status:
                apiData[i]?.status == 1
                  ? "Active"
                  : apiData[i]?.status == 2
                  ? "Inactive"
                  : "Blocked",
              subscription: !apiData[i]?.subscription
                ? "- -"
                : apiData[i]?.subscription == 1
                ? "Free"
                : apiData[i]?.subscription == 3
                ? "Monthly"
                : "Annual",
              userType: apiData[i]?.userType,
              location: apiData[i]?.location,
              email: decryptRequest(apiData[i]?.email),
            });
          }
          if (arr2?.length > 0) {
            setDownloadData(arr2);
          }
        }
      }
      // setResponseGet(apiData);
      // setNoRecord(false);
    } catch (err) {
      // alert("network Error");
      setNoRecord(true);
    }
  };
  const [defaultStatus] = useState(() => "User Status");
  const [statusList] = useState(() => {
    return [
      {
        id: 0,
        name: "All",
        icon: "icon-people",
      },
      {
        id: 1,
        name: "Active",
        icon: "icon-person-check",
      },
      {
        id: 3,
        name: "Cancelled",
        icon: "icon-cross",
      },
      {
        id: 4,
        name: "Blocked",
        icon: "icon-person-cross",
      },
    ];
  });

  const [userType] = useState(() => "User Type");
  const [userList] = useState(() => {
    return [
      {
        id: 0,
        name: "All ",
        icon: "icon-people",
      },
      {
        id: 1,
        name: "Personal",
        icon: "icon-person-circle",
      },
      {
        id: 2,
        name: "Guardian",
        icon: "icon-people",
      },
    ];
  });
  const onAlluserClick = (event) => {
    event.preventDefault();

    props.setUserHeadState(1);
  };
  const onArchiveUserClick = (event) => {
    event.preventDefault();
    props.setUserHeadState(2);
  };
  const onUserNameClick = (event, users) => {
    event.preventDefault();
    dispatch({ type: "PROFILE_DATA", payload: users });
    dispatch({ type: "PAGE_NO", payload: currentPage });
    navigate(`/profile`);
  };
  const onSearchChange = debounce((event) => {
    setSearchParam(event.target.value);
    setCurrentPage(1);
  }, 500);

  const confirm = async (users) => {
    try {
      const response = await axiosInstance.put(`${blockUser}/${users?.id}`);
      const apiData = response;
      // setResponseGet2(apiData2);
      setOpenModal(false);
      message.success("Status updated");
      allUsers();
    } catch (err) {
      console.log(err);
    }
  };

  const headers = [
    { label: "First Name", key: "name" },
    { label: "Last Name", key: "lName" },
    { label: "User Type", key: "userType" },
    { label: "Gender", key: "gender" },

    { label: "Age", key: "age" },
    { label: "Location", key: "location" },

    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Subscription type", key: "subscription" },
    { label: "Status", key: "status" },
  ];

  const csvReport = {
    data: downloadData,
    headers: headers,
    filename: "user_list.csv",
  };
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }
  return (
    <Layout>
      <div className="top-filter">
        <div className="row">
          <div className="col-md-12">
            <div className="filter-row-new">
              <div className="btn-group-wrap">
                <button
                  className="btn btn-xl btn-round active"
                  onClick={(event) => onAlluserClick(event)}
                >
                  All Users
                </button>
                <button
                  className="btn btn-xl btn-round"
                  onClick={(event) => onArchiveUserClick(event)}
                >
                  Archive Users
                </button>
              </div>
              <div className="right-filter">
                <div className="form-group">
                  <CustomSelect
                    filterType="status"
                    defaultText={defaultStatus}
                    optionsList={statusList}
                    changeValue={setActiveFilter}
                    changePage={setCurrentPage}
                  />
                </div>
                <div className="form-group">
                  <CustomSelect
                    filterType="user"
                    defaultText={userType}
                    optionsList={userList}
                    changeValue={setTypeFilter}
                    changePage={setCurrentPage}
                  />
                </div>
                <div className="searchbar placeholder-light">
                  <Popover content="Search by name, email, phone number, location">
                    <input
                      type="text"
                      className="form-control py-2"
                      name="search"
                      placeholder="Search by name, email..."
                      onChange={onSearchChange}
                    />
                  </Popover>
                  <i className="icon-search"></i>
                </div>

                {downloadData?.length > 0 ? (
                  <CSVLink
                    type="button"
                    className="btn btn-white btn-icon btn-lg btn-right btn-round"
                    {...csvReport}
                  >
                    <span>Download </span> <i className="icon-download"></i>
                  </CSVLink>
                ) : null}

                <br />
                <br />
              </div>
            </div>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-7"></div>
        </div>
      </div>

      <div className="bg-outer table-responsive">
        <table className="table seperate-column">
          <thead>
            <tr>
              <td>Name</td>
              <td>User Type</td>
              <td>Gender</td>
              <td>Age</td>
              <td>Location</td>
              <td>Email Address</td>
              <td>Mobile Number</td>
              <td>Subscription Type</td>
              <td>Status</td>
              <td>Days +</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {/* {JSON.stringify(responseGet)} */}
            {responseGet && noRecord !== true ? (
              responseGet?.result?.map((users, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {/* <Link to="/profile" state={{ users: users }}> */}
                      <a onClick={(event) => onUserNameClick(event, users)}>
                        {users.name === null || users.name === ""
                          ? "- -"
                          : decryptRequest(users.name) +
                            " " +
                            decryptRequest(users?.lName)}
                      </a>
                      {/* </Link> */}
                    </td>
                    <td>
                      {users.userType === null || users.userType === ""
                        ? "- -"
                        : users.userType === "PersonalAccount"
                        ? "Personal"
                        : "Guardian"}
                    </td>
                    <td>
                      {users.gender === null || users.gender === ""
                        ? "NA"
                        : users.gender}
                    </td>
                    <td>
                      {users.age === null || users.age === ""
                        ? "- -"
                        : users.age}
                    </td>
                    <td>
                      {users.location === null || users.location === ""
                        ? "- -"
                        : users.location}
                    </td>
                    <td>
                      {users.email === null || users.email === ""
                        ? "- -"
                        : decryptRequest(users.email)}
                    </td>
                    <td>
                      {users.phone === null || users.phone === ""
                        ? "- -"
                        : formatPhoneNumber(decryptRequest(users?.phone))}
                    </td>
                    <td>
                      {users.subscription === 1 ? "Free" : null}{" "}
                      {users.subscription === 3 ? "Monthly" : null}{" "}
                      {users.subscription === 7 ? "Annual" : null}
                      {/* |{" "}
                      {users.storageType === 1 ? "2 GB" : null}{" "}
                      {users.storageType === 2 ? "10 GB" : null} */}
                    </td>
                    <td>
                      {users.status === 1 ? "Active" : null}{" "}
                      {users.status === 3 
                        ? "Cancelled"
                        : null}{" "}
                      {users.status === 4 ? "Blocked" : null}
                    </td>
                    <td>{users.days === null ? "- -" : users.days}</td>
                    <td>
                      <a
                        className="danger-light"
                        onClick={() => {
                          setOpenModal(true);
                          setSelectedUser(users);
                        }}
                      >
                        <i className="icon icon-ban"></i>
                      </a>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-black">
                <span className="">No Users found </span>
              </tr>
            )}
          </tbody>
        </table>
        {responseGet?.result?.length > 0 && noRecord !== true ? (
          <Pagination
            // {...bootstrap5PaginationPreset} // include Bootstrap 5 preset
            total={totalPages}
            current={currentPage}
            onPageChange={(page) => handlePageChange(page)}
            maxWidth={3}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
            // showLimit={5}
          />
        ) : (
          <Pagination
            // {...bootstrap5PaginationPreset} // include Bootstrap 5 preset
            total={1}
            current={1}
            // onPageChange={(page) => handlePageChange(page)}
            maxWidth={3}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
            // showLimit={5}
          />
        )}
      </div>
      <BlockUserModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        confirm={confirm}
        selectedUser={selectedUser}
      />
    </Layout>
  );
};

export default UserManagement;
