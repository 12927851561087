import { debounce } from "lodash";
import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { getInvoiceList } from "../../../services/api/endpoints";
import axiosInstance from "../../../services/middleware";
import Layout from "../../layout";
import Pagination from "react-responsive-pagination";
import { CSVLink } from "react-csv";
import { decryptRequest } from "../../../services/helpers/crypto";

function InvoiceManagement() {
  const [responseGet, setResponseGet] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const dataPerPage = 5;

  const [searchParam, setSearchParam] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [dataPage, setDataPage] = useState(20);
  const [csvFormat, setCsvFormat] = useState(null);

  function handlePageChange(page) {
    setCurrentPage(page);
    // ... do something with `page`
  }

  // for new pagination end

  const onSearchChange = debounce((event) => {
    setSearchParam(event.target.value);
    setCurrentPage(1);
  }, 500);
  useEffect(() => {
    async function invoiceList() {
      try {
        const response = await axiosInstance.get(
          `${getInvoiceList}/${currentPage}/${dataPage}/${searchParam}`
        );
        const apiData = response.data.data;
        setResponseGet(apiData);

        if (apiData?.result?.length > 0) {
          let cnt = Math.ceil(apiData?.totalCount / dataPage);
          setTotalPages(cnt);
        }
      } catch (err) {
        console.log(err);
      }
    }
    invoiceList();
  }, [searchParam, currentPage]);

  useEffect(() => {
    async function allInvoiceList() {
      try {
        const response = await axiosInstance.get(`${getInvoiceList}`);
        const apiData = response.data.data;
        const formattedResponse = apiData?.result?.map((response) => {
          const formattedDate = moment
            .utc(response?.createdAt)

            .format("MM-DD-YYYY");
          const formattedTime = moment
            .utc(response?.createdAt)
            .local()

            .format("h:mm A");
          const formattedPhone = formatPhoneNumber(
            decryptRequest(response?.phone)
          );

          return {
            ...response,
            subscriptionStartDate: formattedDate,
            subscriptionStartTime: formattedTime,
            subscriptionType:
              response.subscriptionType === 1
                ? "Free"
                : response.subscriptionType === 3
                ? "Monthly"
                : "Annual",
            storageType: response.storageType === 1 ? "2 GB" : "10GB",
            phone: formattedPhone,
            userFName: decryptRequest(response?.userFName),
            userLName: decryptRequest(response?.userLName),
            email: decryptRequest(response?.email),
          };
        });
        setCsvFormat(formattedResponse);
      } catch (err) {
        console.log(err);
      }
    }
    allInvoiceList();
  }, []);

  const headers = [
    { label: "Txn Id", key: "transcationId" },
    { label: "Original Txn Id", key: "originalTranscationId" },
    { label: "First Name", key: "userFName" },
    { label: "Last Name", key: "userLName" },
    { label: "Phone", key: "phone" },
    { label: "Email", key: "email" },
    { label: "User Subscription Type", key: "subscriptionType" },
    { label: "User Storage Type", key: "storageType" },

    { label: "Subscription Start Date", key: "subscriptionStartDate" },
    { label: "Subscription Start Time", key: "subscriptionStartTime" },
    { label: "Amount", key: "amount" },
  ];
  const csvReport = {
    data: csvFormat,
    headers: headers,
    filename: "invoice_list.csv",
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  return (
    <Layout>
      <div className="top-filter">
        <div className="row main-header mb-0 gx-0 bg-transparent">
          <div className="col-md-6">
            {" "}
            <h1 className="title-heading">Invoice Management</h1>
          </div>
          <div className="col-md-6">
            <div className="d-flex">
              <div className="searchbar me-4 placeholder-light">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  placeholder="Search by name, email, phone no., transaction id"
                  onChange={onSearchChange}
                />
                <i className="icon-search"></i>
              </div>

              {csvFormat?.length > 0 ? (
                <CSVLink
                  type="button"
                  className="btn btn-white btn-icon btn-lg btn-right btn-round"
                  {...csvReport}
                >
                  <span>Download </span> <i className="icon-download me-2"></i>
                </CSVLink>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-outer table-responsive">
        <table className="table seperate-column">
          <thead>
            <tr>
              <td>Txn Id</td>
              <td>Original Txn Id</td>
              <td>Name</td>
              <td>Phone no.</td>
              <td>Email</td>
              <td>Subscription Type</td>
              <td>Date</td>
              <td>Time</td>
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            {responseGet?.result?.map((invoice, index) => (
              <tr key={index}>
                <td>
                  {invoice?.transcationId === null ||
                  invoice?.transcationId === ""
                    ? "- -"
                    : invoice?.transcationId}
                </td>
                <td>
                  {invoice?.originalTranscationId === null ||
                  invoice?.originalTranscationId === ""
                    ? "- -"
                    : invoice?.originalTranscationId}
                </td>
                <td>
                  {decryptRequest(invoice?.userFName)}{" "}
                  {decryptRequest(invoice?.userLName)}
                </td>
                <td>{formatPhoneNumber(decryptRequest(invoice?.phone))}</td>
                <td>{decryptRequest(invoice?.email)}</td>
                <td>
                  {invoice.subscriptionType === 1 ? "Free" : null}{" "}
                  {invoice.subscriptionType === 3 ? "Monthly" : null}{" "}
                  {invoice.subscriptionType === 7 ? "Annual" : null} |{" "}
                  {invoice.storageType === 1 ? "2 GB" : null}{" "}
                  {invoice.storageType === 2 ? "10 GB" : null}
                </td>
                <td>
                  {invoice?.createdAt === null
                    ? "- -"
                    : moment
                        .utc(invoice?.createdAt)
                        .local()
                        .format("MM-DD-YYYY")}{" "}
                </td>
                <td>
                  {invoice?.createdAt === null
                    ? "- -"
                    : moment.utc(invoice?.createdAt).local().format("h:mm a")}
                </td>
                <td>${invoice?.amount === null ? 0 : invoice?.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {responseGet?.result?.length > 0 ? (
          <Pagination
            total={totalPages}
            current={currentPage}
            onPageChange={(page) => handlePageChange(page)}
            maxWidth={10}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
          />
        ) : (
          <Pagination
            // {...bootstrap5PaginationPreset} // include Bootstrap 5 preset
            total={1}
            current={1}
            // onPageChange={(page) => handlePageChange(page)}
            maxWidth={3}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
            // showLimit={5}
          />
        )}
      </div>
    </Layout>
  );
}

export default InvoiceManagement;
