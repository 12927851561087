import React, { useState, useEffect } from "react";

import { Column } from "@ant-design/charts";


const TotalActiveSosGraphComponent = ({ responseGet }) => {
  const [data, setData] = useState(responseGet);


  const config = {
    data,
    isStack: true,
    yField: "count",
    yAxis: {
      nice: false,
      tickInterval: 1,
    },
    // tooltip: {
    //   formatter: (datum) => {
    //     return `Date: ${datum.date}\nValue: ${datum.value}`;
    //   },
    // },
    xField: "date",
    seriesField: "type",
    label: {
      style: {
        fontSize: 15,
        fontWeight: 700,
        fill: '#333333'
      },
      position: "middle",

      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
      ],
    },
  };
  useEffect(() => {
    setData(responseGet)
  }, [responseGet]);

  return (
    <div>
      <div className="graph-wrapper">
        <div className="graph w-100">
          <div className="report-box">
            {data?.length > 0 ?
              <Column {...config} />

              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalActiveSosGraphComponent;
