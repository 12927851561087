import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Logout = ({ openModal, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandle = () => {
    dispatch({ type: "RESET_STATE" });
    localStorage.clear();
    navigate('/')
  };
  return (
    <>
      <Modal
        show={openModal}
        onHide={closeModal}
        centered
      >
        <Modal.Header>
          <Modal.Title>Logout</Modal.Title>
          <button type='button' className='btn-close-modal' onClick={closeModal}>
            <span className='icon-cross'></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className='mb-5'>Are you sure you want to logout?</p>
          <div className='text-center'>
            <Button variant="primary" className='btn-xxl' onClick={() => logoutHandle()}>
              Logout
            </Button>
            <Button variant="transparent" className='btn-xxl' onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Logout;