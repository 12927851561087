import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import Images from "../../../Images";
import BatteryGauge from "react-battery-gauge";
import {
  getPostDetailSos,
  getSosChatDetail,
  getUserProfileDetail,
} from "../../../../services/api/endpoints";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../services/middleware";
import moment from "moment/moment";
// import {
//   GoogleMap,
//   MarkerF,
//   Polyline,
//   useJsApiLoader,
// } from "@react-google-maps/api";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import { decryptRequest } from "../../../../services/helpers/crypto";
// const containerStyle = {
//   width: "650px",
//   height: "230px",
// };
const SosHistoryDetails = ({ setPageState, selectedSos }) => {
  const [response, setResponse] = useState(null);
  const [chatResponse, setChatResponse] = useState(null);
  const [profileResponse, setProfileResponse] = useState(null);
  const [geoAddress, setGeoAddress] = useState(null);
  const [vidObjectUrl, setVidObjectUrl] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  // });
  const CustomBattery = {
    batteryBody: {
      strokeWidth: 4,
      cornerRadius: 6,
      fill: "none",
      strokeColor: "#111",
    },
    batteryCap: {
      fill: "none",
      strokeWidth: 4,
      strokeColor: "#111",
      cornerRadius: 2,
      capToBodyRatio: 0.4,
    },
    batteryMeter: {
      fill: "black",
      lowBatteryValue: 15,
      lowBatteryFill: "red",
      outerGap: 8,
      noOfCells: 1,
      interCellsGap: 1,
    },
    readingText: {
      lightContrastColor: "#111",
      darkContrastColor: "#fff",
      lowBatteryColor: "red",
      fontFamily: "Helvetica",
      fontSize: 14,
      display: "none",
      showPercentage: false,
    },
    chargingFlash: {
      scale: undefined,
      fill: "orange",
      animated: true,
      animationDuration: 1000,
    },
  };

  const onBackClick = () => {
    // event.preventDefault();
    setPageState(0);
  };
  useEffect(() => {
    const getSosDetails = async () => {
      const response2 = await axiosInstance.get(
        `${getPostDetailSos}/${selectedSos?.id}`
      );
      const apiData2 = response2.data.data;
      setResponse(apiData2);
      getUserProfile(apiData2?.userId);

      if (apiData2.sosChildPost) {
        //  debugger
        if (apiData2?.sosChildPost?.lattitude != undefined) {
          const url = `https://nominatim.openstreetmap.org/reverse?lat=${apiData2?.sosChildPost?.lattitude}&lon=${apiData2?.sosChildPost?.longitude}&format=json`;

          return fetch(url)
            .then((response) => response.json())
            .then((data) => setGeoAddress(data.display_name))
            .catch((error) => console.error(error));
        }
      }
    };
    const sosChatDetails = async () => {
      const res = await axiosInstance.get(
        `${getSosChatDetail}/${selectedSos?.id}`
      );
      const apiData3 = res.data.data;
      setChatResponse(apiData3);
    };
    getSosDetails();
    sosChatDetails();
  }, []);
  useEffect(() => {
    if (response?.postMedia && response?.postMedia.length > 0) {
      const fetchPromises = response?.postMedia.map((videoData) => {
        return fetch(videoData.url)
          .then((response) => response.blob())
          .then((videoBlob) => {
            const url = URL.createObjectURL(videoBlob);
            return {
              url,
              duration: videoData.duration,
              fileSize: videoData.fileSize,
              fileType: videoData.fileType,
            };
          })
          .catch((error) => {
            console.error("Error fetching video:", error);
            return null;
          });
      });

      Promise.all(fetchPromises).then((videoUrls) => {
        const validVideoUrls = videoUrls.filter((url) => url !== null);
        setVidObjectUrl(validVideoUrls);
      });
    }
  }, [response]);
  const getUserProfile = async (userId) => {
    try {
      const response = await axiosInstance.get(
        `${getUserProfileDetail}/${userId}`
      );

      const apiData = response.data.data;

      setProfileResponse(apiData);
    } catch (err) {
      console.log("err", err);
    }
  };
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }
  const onUserNameClick = (event, sos) => {
    event.preventDefault();
    dispatch({ type: "PROFILE_DATA", payload: sos });
    dispatch({ type: "PAGE_ROUTE_DATA", payload:"user-management"  })

    // dispatch({ type: "ARCHIVE_PAGE_NO", payload: currentPage });
    navigate(`/profile`, {
      state: {
        id: "",
      },
    });
  };
  return (
    <Layout>
      {/* profile header */}
      <div className="back-btn mx--3">
        <button type="button" onClick={() => onBackClick()}>
          <i className="icon-angle-left"></i>
        </button>
      </div>
      <div className="row align-items-center profile-header">
        <div className="left-item col">
          <div className="d-flex align-items-center">
            <h1 className="title-heading mb-0 me-4">SOS Details</h1>
          </div>
          {response == null ? null : (
            <span className="date">
              {moment.utc(response?.createdAt).local().format("MM-DD-YYYY")}
            </span>
          )}
        </div>
        {profileResponse == null ? null : (
          <div className="col-auto">
            <div className="sos-profile row align-items-center">
              <div className="col profile-data fs-13">
                <h6
                  className="fs-4 mb-0"
                  onClick={(event) => onUserNameClick(event, profileResponse)}
                  style={{ cursor: "pointer" }}
                >
                  <u>
                    {decryptRequest(profileResponse?.name)} {decryptRequest(profileResponse?.lName)}
                  </u>
                </h6>
                <p className="mb-0">{formatPhoneNumber(response?.phone)}</p>
                <p className="mb-0">{response?.email}</p>
                <div className="d-flex align-items-center address">
                  <img
                    src={Images.LOCATIONDARK}
                    height="12px"
                    className="me-2"
                    alt="location"
                  />
                  {/* {profileResponse?.addressLine2?.replace(/,(\d+)/, " $1")} */}
                  {!profileResponse?.addressLine2 ? "- -" : profileResponse?.addressLine2?.split(",")[0] +
                    ", " +
                    profileResponse?.addressLine2?.split(",")[1]}
                  {/* {profileResponse?.addressLine2?.split(",")[0]}
                  {", "} {profileResponse?.addressLine2?.split(",")[1]} */}
                </div>
              </div>
              <div className="col-auto">
                {response?.userProfilePic === null ||
                response?.userProfilePic === "" ? (
                  <p className="avatar-text">
                    {decryptRequest(profileResponse?.name)?.slice(0, 1)}
                  </p>
                ) : (
                  <div className="img-box position-relative">
                    <img src={response?.url} alt="profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* profile header end */}
      <hr />
      <div className="top-filter">
        {/* <button
          type="button"
          className="btn btn-white btn-icon btn-lg btn-round ms-auto"
        >
          <span>Download </span> <i className="icon-download me-2"></i>
        </button> */}
      </div>
      {response == null ? null : (
        <>
          <div className="row gx-0 sos-details">
            {/* active SOS */}
            <div className="col-lg-5 pe-lg-5">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="card-top d-flex justify-content-between time fs-14 mb-3">
                    <span>
                      <strong>
                        {moment
                          .utc(response?.createdAt)
                          .local()
                          .format("h:mm A")}
                      </strong>
                    </span>
                    {/* battery indicator */}
                    <div className="d-flex align-items-center fs-12">
                      <BatteryGauge
                        className="me-2"
                        value={response?.batteryPercentage}
                        size={24}
                        customization={CustomBattery}
                      />
                      {response?.batteryPercentage}%
                    </div>
                  </div>

                  {response?.startTime != null && response?.endTime == null ? (
                    <p className="mb-3">
                      {response?.firstName} activated <strong> SOS</strong>
                    </p>
                  ) : null}

                  {response?.startTime != null && response?.endTime != null ? (
                    <p className="mb-3">
                      {/* {response?.firstName} deactivatedss */}
                      {response?.sosChildPost?.description ==
                      "Admin DeActivated the Sos"
                        ? "Admin deactivated "
                        : response?.firstName + "deactivated"}
                      <strong>SOS</strong>
                    </p>
                  ) : null}
                  {response?.startTime == null && response?.endTime == null ? (
                    <p className="mb-3">
                      {response?.firstName} cancelled <strong> SOS</strong>
                    </p>
                  ) : null}
                  <div className="d-flex align-items-center mb-3 address">
                    <i className="icon-location text-primary me-2 mt-2 align-self-start"></i>
                    <p className="mb-0">
                      <strong>{response?.locationName}</strong>
                      <br />{" "}
                      {response?.addressLine2?.split(",")[0] +
                        " " +
                        response?.addressLine2?.split(",")[1] +
                        ", " +
                        response?.addressLine2?.split(",")[2]}
                        <span className="d-block">
                            {
                              response?.addressLine2?.split(",")[3]
                            }
                          </span>
                    </p>
                    {/* {response?.sosChildPost?.locationName},{" "}
                    {response?.addressLine2?.split(",")[0] +
                      ", " +
                      response?.addressLine2?.split(",")[1] +
                      " " +
                      response?.addressLine2?.split(",")[2] +
                      ", " +
                      response?.addressLine2?.split(",")[3]} */}
                  </div>
                  <p className="share-with">
                    <span className="me-3">Shared with:</span>
                    {/* <span>Everyone</span> */}
                    {response?.sharingWith?.length > 0 ? (
                      <ul className="img-collect">
                        {response?.sharingWith.map((item, index) => (
                          <>
                            {!item?.userProfilePic ? (
                              <li key={index}>
                                <span className="letter-f">
                                  {(item?.userFriendName)?.substring(0, 1)}
                                </span>
                              </li>
                            ) : (
                              <li key={index}>
                                <img src={item?.url} alt="profile" />
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    ) : (
                      <p> None</p>
                    )}
                  </p>
                </div>
              </div>
            </div>

            {/* past SOS */}
            {/* <div className="col-lg-5 pe-lg-5">
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-top d-flex justify-content-between time fs-14 mb-3">
                <span>10:02 PM</span>
                <div className="d-flex align-items-center">
                  <i className="icon-battery-half me-2 fs-18"></i>100%
                </div>
              </div>

              <p className="mb-3">
                Skylar activated
                <strong> SOS</strong>
              </p>
              <div className="d-flex align-items-center mb-3 address">
                <img
                  src={Images.LOCATIONDARK}
                  height="16px"
                  className="me-2"
                  alt="location"
                />
                Address Line, Houston, TX
              </div>
              <p className="share-with">
                <span className="me-3">Shared with:</span>
                <span>Everyone</span>
              </p>
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <div className="card-top d-flex justify-content-between time fs-14 mb-3">
                <span>10:02 PM</span>
                <div className="d-flex align-items-center">
                  <i className="icon-battery-half me-2 fs-18"></i>100%
                </div>
              </div>

              <p className="mb-3">
                Skylar deactivated
                <strong> SOS</strong>
              </p>
              <div className="d-flex align-items-center mb-3 address">
                <img
                  src={Images.LOCATIONDARK}
                  height="16px"
                  className="me-2"
                  alt="location"
                />
                Address Line, Houston, TX
              </div>
              <p className="share-with">
                <span className="me-3">Shared with:</span>
                <span>Everyone</span>
              </p>
            </div>
          </div>
        </div> */}

            {/* map*/}
            <div className="col-lg-7">
              <div className="card overflow-hidden mb-4">
                <div className="map-box ">
                  {/* <iframe
                className="w-100 h-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2515839006487!2d77.36386661545025!3d28.622220991297475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff430a9852f%3A0x11b0f6c89f81224e!2sTechAhead!5e0!3m2!1sen!2sin!4v1667380582022!5m2!1sen!2sin"
                title="maps"
                allowfullscreen=""
                loading="lazy"
              ></iframe> */}
                  {/* {isLoaded ? (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={{
                        lat: response?.lattitude,
                        lng: response?.longitude,
                      }}
                      zoom={8}
                      // onLoad={onLoad}
                      // onUnmount={onUnmount}
                    >
                      <MarkerF
                        position={{
                          lat: response?.lattitude,
                          lng: response?.longitude,
                        }}
                      />
                      <MarkerF
                        position={{
                          lat: response?.sosChildPost?.lattitude,
                          lng: response?.sosChildPost?.longitude,
                        }}
                      />
                      <Polyline
                        path={[
                          {
                            lat: response?.lattitude,
                            lng: response?.longitude,
                          },
                          {
                            lat: response?.commuteChildPost?.lattitude,
                            lng: response?.commuteChildPost?.longitude,
                          },
                        ]}
                        geodesic={true}
                        options={{
                          strokeColor: "purple",
                          strokeOpacity: 1,
                          strokeWeight: 4,
                        }}
                      />
                    </GoogleMap>
                  ) : null} */}
                  <iframe
                    name="gMap"
                    className="w-100"
                    style={{ height: "100%" }}
                    src={`https://www.google.com/maps/embed/v1/place?q=${response?.lattitude},${response?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          {response?.sosChildPost == null ? null : (
            <>
              <div className="col-lg-5 pe-lg-5">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-top d-flex justify-content-between time fs-14 mb-3">
                      <span>
                        <strong>
                          {moment
                            .utc(response?.sosChildPost?.createdAt)
                            .local()
                            .format("h:mm A")}
                        </strong>
                      </span>
                      {/* battery indicator */}
                      <div className="d-flex align-items-center fs-12">
                        <BatteryGauge
                          className="me-2"
                          value={response?.sosChildPost?.batteryPercentage}
                          size={24}
                          customization={CustomBattery}
                        />
                        {response?.sosChildPost?.batteryPercentage}%
                      </div>
                    </div>
                    {response?.startTime != null &&
                    response?.endTime == null &&
                    response?.sosChildPost == null ? (
                      <p className="mb-3">
                        {response?.firstName} activated <strong> SOS</strong>
                      </p>
                    ) : null}

                    {response?.startTime != null &&
                    response?.endTime == null &&
                    response?.sosChildPost != null ? (
                      <p className="mb-3">
                        {/* {response?.firstName} deactivated <strong> SOS</strong> */}
                        {response?.sosChildPost?.description ==
                        "Admin DeActivated the Sos"
                          ? "Admin deactivated "
                          : response?.firstName + " " + "deactivated"}{" "}
                        <strong>SOS</strong>
                      </p>
                    ) : null}
                    {response?.startTime == null &&
                    response?.endTime == null ? (
                      <p className="mb-3">
                        {response?.firstName} cancelled <strong> SOS</strong>
                      </p>
                    ) : null}
                    <div className="d-flex align-items-center mb-3 address">
                      {/* <img
                        src={Images.LOCATIONDARK}
                        height="16px"
                        className="me-2 mt-2 align-self-start"
                        alt="location"
                      /> */}
                      <i className="icon-location text-primary me-2 mt-2 align-self-start"></i>
                      {!response?.sosChildPost?.locationName ? (
                        <div>{geoAddress?.replace(/,(?=\s*\d)/, "")}</div>
                      ) : (
                        <p>
                          <strong>
                            {response?.sosChildPost?.locationName}{" "}
                          </strong>
                          <br />
                          {/* {response?.sosChildPost?.addressLine1},{" "} */}
                          {response?.addressLine2?.split(",")[0] +
                            " " +
                            response?.addressLine2?.split(",")[1] +
                            ", " +
                            response?.addressLine2?.split(",")[2]}
                            <span className="d-block">
                            {
                              response?.addressLine2?.split(
                                ","
                              )[3]
                            }
                          </span>
                        </p>
                      )}

                      {/* {geoAddress} */}
                    </div>
                    <p className="share-with">
                      <span className="me-3">Shared with:</span>
                      {response?.sharingWith?.length > 0 ? (
                        <ul className="img-collect">
                          {response?.sharingWith.map((item, index) => (
                            <>
                              {!item?.userProfilePic ? (
                                <li key={index}>
                                  <span className="letter-f">
                                    {item?.userFriendName?.substring(0, 1)}
                                  </span>
                                </li>
                              ) : (
                                <li key={index}>
                                  <img src={item?.url} alt="profile" />
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      ) : (
                        <p> None</p>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* chat history */}
          <div className="chat-history">
            <h1 className="title-heading mb-4">Chat History</h1>
            <div className="card mb-4">
              <div className="card-body">
                <ul className="mb-0">
                  {chatResponse?.length > 0 ? (
                    <>
                      {chatResponse?.map((chat, index) => (
                        <>
                          <li key={index}>
                            <div className="d-flex mb-2">
                              <div className="chat-history-user me-5 flex-shrink-0">
                                {/* <div className="img-box position-relative">
                                  <img src={chat?.senderUrl} alt="profile" />
                                </div> */}
                                <div className="">
                                  {chat?.senderProfilePic === null ||
                                  chat?.senderProfilePic === "" ? (
                                    <p className="avatar-text-image">
                                      {chat?.senderName?.slice(0, 1)}
                                    </p>
                                  ) : (
                                    <div className="img-box position-relative">
                                      <img
                                        src={chat?.senderUrl}
                                        alt="profile"
                                      />
                                    </div>
                                  )}
                                </div>
                                <p className="fw-semibold">
                                  {chat?.senderName}
                                </p>
                              </div>
                              <div className="chat">
                                <p className="form-control fs-14 mb-2">
                                  {chat?.message}
                                </p>
                                <span className="ps-3 mb-2">
                                  {moment
                                    .utc(chat?.createdAt)
                                    .local()
                                    .format("h:mm A")}
                                </span>
                              </div>
                            </div>
                          </li>
                        </>
                      ))}
                    </>
                  ) : (
                    <li className="text-black">No Chat Data Found</li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          {/* audio video recording */}
          <div className="audio-video-box">
            <h1 className="title-heading mb-4">Audio and Video Recordings</h1>
            <div className="audio-video row">
              {vidObjectUrl?.length > 0 ? (
                vidObjectUrl?.map((media, index) => (
                  <div className="video-box mb-4" key={index}>
                    <div className="video-play text-center fs-12  bg-light p-5 rounded-5">
                      <>
                        {media?.fileType == ".mp4" ? (
                          <>
                            {/* <video width="300" height="100" controls>
                              <source src={media?.url} type="video/mp4" />
                            </video> */}
                            <ReactPlayer
                              url={media?.url}
                              height={100}
                              width={270}
                              controls={true}
                            />
                            <p className="mb-0 ps-3">
                              {/* <span className="me-1">{media?.fileSize}</span> */}
                              <span>{media?.duration}</span>
                            </p>
                          </>
                        ) : (
                          <>
                            <ReactAudioPlayer src={media?.url} controls />
                            <p className="mb-0">
                              {/* <span className="me-1">{media?.fileSize}</span> */}
                              <span>{media?.duration}</span>
                            </p>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Media Available</p>
              )}
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default SosHistoryDetails;
