import React from "react";
import Header from "../header";
import Sidebar from "../sidebar";

const Layout = (props) => {
  return (
    <div className="main-wrapper">
      <div className="content-wrapper">
        <Sidebar />
        <div className="main-content">
          <Header />
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
