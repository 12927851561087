import React, { useState,useEffect } from "react";
import LocationHistory from "./locationHistory/LocationHistory";
import UserProfile from "./profile/UserProfile";
import SubscriptionHistory from "./subscriptionHistory/SubscriptionHistory";
import Timeline from "./Timeline/timeline";
import { useSelector } from "react-redux";
import { Link,useLocation,useNavigate } from "react-router-dom";

const ProfileMainComponent = () => {
  const navigate = useNavigate();
  const [headerState, setHeaderState] = useState(1);
  

  return (
    <div>
      {headerState === 1 ? (
        <UserProfile
          setHeaderState={setHeaderState}
          headerState={headerState}
        />
      ) : null}

      {headerState === 2 ? (
        <Timeline setHeaderState={setHeaderState} headerState={headerState} />
      ) : null}
      {headerState === 3 ? (
        <SubscriptionHistory
          setHeaderState={setHeaderState}
          headerState={headerState}
        />
      ) : null}
      {headerState === 4 ? (
        <LocationHistory
          setHeaderState={setHeaderState}
          headerState={headerState}
        />
      ) : null}
    </div>
  );
};

export default ProfileMainComponent;
