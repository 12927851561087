import React, { useState, useEffect } from "react";

import Layout from "../../layout";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
  EditorState,
  convertFromHTML,
  ContentState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";


const CmsEditor2 = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [cmsData, setCmsData] = useState(null);
  const [editorState, setEditorState] = useState(null);


  const [response, setResponse] = useState();

  const handleSave = () => {
    if (cmsData != "") {
      const token = localStorage.getItem("adminLoginToken");
      const effectiveUrl = `${process.env.REACT_APP_ENV_VARIABLE_KEY}/api/CMS/EditCMSPage`;
      axios({
        url: effectiveUrl,
        method: "PUT",
        headers: {
          Authorization: token,
        },
        data: {
          id: location?.state?.id,

          pageHtml: draftToHtml(convertToRaw(editorState?.getCurrentContent())),
        },
      })
        .then((response) => setResponse(response))
        .catch((response) => setResponse(response));
    }
  };
  const onBackClick = () => {
    navigate("/cms");
  };
  useEffect(() => {
    if (
      response?.data?.httpStatus === 200 &&
      response?.data?.success === true
    ) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: response?.data?.message,
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/cms");
    } else if (response?.data?.httpStatus === 200) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: response?.data?.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [response]);
  useEffect(() => {
    if (location?.state?.cms) {
      setCmsData(location?.state?.cms);
    }
  }, [location]);

  useEffect(() => {
    if (cmsData != null) {
      let content = ContentState.createFromBlockArray(convertFromHTML(cmsData));
      let finalHtml = EditorState.createWithContent(content);
      setEditorState(finalHtml);
    }
  }, [cmsData]);
  return (
    <div>
      <Layout>
        <div className="back-btn mx--3">
          <button type="button" onClick={() => onBackClick()}>
            <i className="icon-angle-left"></i>
          </button>
        </div>
        <div className="top-filter">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1 className="fs-3 mb-0 customer-help">
                {location?.state?.name}
              </h1>
              <p className="d-inline-flex">
                <i className="icon-pending me-2"></i>
                <small>
                  Change price on iTunes before making changes here.
                </small>
              </p>
              <p className="">
                {/* <i className="icon-pending me-2"></i> */}
                {/* <small> */}
                  To change from word to HTML tags paste your word file data click<a href="https://html-cleaner.com/"> here</a>.
                {/* </small> */}
              </p>
              <p className="">
                {/* <i className="icon-pending me-2"></i> */}
                {/* <small> */}
                  To view the HTML content paste the output of the above link <a href="https://jsfiddle.net/">here</a>.
                {/* </small> */}
              </p>
              <p className="">
                {/* <i className="icon-pending me-2"></i> */}
                {/* <small> */}
                  Then you can paste the HTML view page in the below input box.
                {/* </small> */}
              </p>
            </div>
           
            <button
              type="button"
              className="btn btn-white btn-icon btn-lg btn-right btn-round w-auto"
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>

        <Editor
          editorState={editorState}
          defaultEditorState={location.state.cms}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          onEditorStateChange={setEditorState}
        />
      </Layout>
    </div>
  );
};

export default CmsEditor2;
