import React, { useState, useEffect,useRef } from "react";
import Images from "../Images";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import noToken from "../../services/middleware/noToken";
import { forgotPassword } from "../../services/api/endpoints";
import { message } from "antd";
import { encryptRequest } from "../../services/helpers/crypto";

const ForgotPass = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState(null);
  const [validEmail, setValidEmail] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [blankEmail, setBlankEmail] = useState(false);
  const [firstClick, setFirstClick] = useState(1);


const [loading,setLoading] = useState(false)
  const onFieldChange = (event) => {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    if (name === "email") {
      setEmail(value);
      if (value != "" && !validator.isEmail(value)) {
        setBlankEmail(false);
        setValidEmail(false);
      }
      if (value != "" && validator.isEmail(value)) {
        setBlankEmail(false);
        setValidEmail(true);
      }
    }
  };
  const onFieldError = (event) => {
    event.preventDefault();
   
   
    let target = event.target;
    let name = target.name;
    let value = target.value;

    setFirstClick(2);

    if (name === "email" && value && validator.isEmail(value) && value != "") {
      setEmail(value);
      setValidEmail(true);
      setDisableButton(false);
      setBlankEmail(false);
    }
    if (name === "email" && value == "") {
      setEmail(value);
      setValidEmail(false);
      setDisableButton(true);
      setBlankEmail(true);
    }
  
  };
  const handleSubmit = async(event) => {
    setLoading(true)
    let payload = {
      email:encryptRequest(email)
    };
    try {
      const response = await noToken.put(`${forgotPassword}`, payload);
      const apiData = response;
      setLoading(false)
      
      message.success(apiData?.data?.message);
      // navigate("/auth/reset-password")
      
    } catch (err) {
      setLoading(false)
      
      message.error(err)
    }
  };
const prevCountRef = useRef();

  useEffect(() => {
    if (!email) {
      setDisableButton(true);
    }
  }, [email]);
  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevCountRef.current = email;
  }, [email]);
  return (
    <div className="layout-auth">
      <div className="logo-header">
        <img src={Images.LOGO} />
      </div>
      {/* Left banner column */}
      <div className="left-col">
        <div className="banner-image">
          <img src={Images.FORGOTIMG} />
        </div>
      </div>

      {/* Right Banner Login Page */}
      <div className="right-col">
        <div className="card">
          <div className="card-body">
            <h1 className="heading-two">Forgot Password?</h1>
            <form>
              <div className="forgot-pass">
                <label className="form-label">Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email Address"
                  onChange={(event) => onFieldChange(event)}
                  onBlur={(event) => onFieldError(event)}
                  autoComplete="off"
                />
                {validEmail == false &&
                email !== null &&
                blankEmail == false &&
                firstClick != 1 ? (
                  <p className="error text-wrap">Please enter valid email id</p>
                ) : null}

                {blankEmail == true ? (
                  <p className="error text-wrap">Email is required.</p>
                ) : null}
              </div>

              <button
                type="button"
                className="btn btn-primary signin-btn"
                disabled={disableButton}
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
