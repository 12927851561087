import React, { useState, useEffect } from "react";

import { Column } from "@ant-design/charts";

const FreeTrialUsersGraphComponent = ({ responseGet2 }) => {
  const [data, setData] = useState(responseGet2);
  const config = {
    data,
    isStack: true,
    yField: "count",
    xField: "date",
    seriesField: "type",
    yAxis: {
      nice: false,
      tickInterval: 1,
    },
    // tooltip: {
    //   formatter: (datum) => {
    //     return `Date: ${datum.date}\nValue: ${datum.value}`;
    //   },
    // },
    label: {
      style: {
        fontSize: 15,
        fontWeight: 700,
        fill: '#333333'
      },
      position: "middle",

      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },

      ],
    },
  };
  useEffect(() => {
    setData(responseGet2)
  }, [responseGet2, data]);

  return (
    <div><div className="graph-wrapper">
      <div className="graph w-100">
        <div className="report-box">
          {data?.length > 0 ? <Column {...config} />: null}
        </div>
      </div>
    </div></div>
  )
}

export default FreeTrialUsersGraphComponent