import React, { useEffect,useState } from "react";
import TotalPaidUsersGraphComponent from "./TotalPaidUsersGraphComponent";
import { CSVLink } from "react-csv";

const TotalPaidUsers = ({ responseGet5 }) => {
  const [formatCsv,setFormatCsv] = useState([])
  const headers = [
    { label: "Date", key: "date" },
    { label: "Total Paid Users", key: "totalPaidUsers" },
    { label: "Total Guardian Users", key: "totalGuardianUsers" },
    { label: "Total Free Trial Users", key: "totalFreeUsers" },

  ];
  const csvFormat = (responseGet5) => {
    if (responseGet5?.length > 0) {
      let arr2 = [];
      for (let i = 0; i < responseGet5.length; i++) {
        arr2.push({
          date: responseGet5[i]?.date,
          totalPaidUsers:
            responseGet5[i]?.type == "Total Paid Users"
              ? responseGet5[i]?.count
              : 0,
          totalFreeUsers:
            responseGet5[i]?.type == "Total Free Trial Users"
              ? responseGet5[i]?.count
              : 0,
          totalGuardianUsers:
            responseGet5[i]?.type == "Total Guardian Users"
              ? responseGet5[i]?.count
              : 0,

        });
      }
      if (arr2?.length > 0) {
        setFormatCsv(arr2)

      }
    }
  };

  const csvReport = {
    data: formatCsv,
    headers: headers,
    filename: "selected_day_paid_user_data.csv",
  };
  useEffect(() => {
    csvFormat(responseGet5);
  }, [responseGet5]);

  return (
    <div>
      <div className="card-head">
        <div className="header-data">
          <h1 className="title-heading">Total Paid Users</h1>
          {responseGet5?.length > 0 ? (
            <CSVLink
              type="button"
              className="btn btn-white btn-icon btn-lg btn-right ms-auto"
              {...csvReport}
            >
              <span>Download </span> <i className="icon-download"></i>
            </CSVLink>
          ) : null}
        </div>
      </div>
      <div className="card-body">
        <TotalPaidUsersGraphComponent responseGet5={responseGet5} />
      </div>
    </div>
  );
};
export default TotalPaidUsers;
