import React, { useState, useEffect } from "react";
import Layout from "../../layout";
import DatePicker from "react-datepicker";
import CustomSelect from "../../customSelect/CustomSelect";
import SubscriptionChartComponent from "./SubscriptionChartComponent";
import {
  getSubscriptionCount,
  getSubscriptionList,
} from "../../../services/api/endpoints";
import axiosInstance from "../../../services/middleware";
import moment from "moment/moment";
import { debounce } from "lodash";
import Pagination from "react-responsive-pagination";
import { CSVLink } from "react-csv";
import { Popover } from "antd";
import { decryptRequest } from "../../../services/helpers/crypto";

const Subscription = () => {
  var d = new Date();
  const [startDate, setStartDate] = useState(d.setDate(d.getDate() - 30));
  const [endDate, setEndDate] = useState(new Date());
  const [responseGet, setResponseGet] = useState(null);
  const [responseGet2, setResponseGet2] = useState([]);
  const [errorhandle, setErrorHandle] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [userFilter, setUserFilter] = useState(0);
  const [subsFilter, setSubsFilter] = useState(0);
  const [csvFormat, setCsvFormat] = useState(null);

  const [userType] = useState(() => "User Type");
  const [userList] = useState(() => {
    return [
      {
        id: 0,
        name: "All",
        // icon: "icon-people",
      },
      {
        id: 1,
        name: "Personal",
      },
      {
        id: 2,
        name: "Guardian",
      },
    ];
  });

  const [subscriptionType] = useState(() => "Subscription");
  const [subscriptionList] = useState(() => {
    return [
      // {
      //   id: 1,
      //   name: "Free",
      // },
      {
        id: 0,
        name: "All ",
        // icon: "icon-people",
      },
      {
        id: 2,
        name: "Monthly",
      },
      {
        id: 3,
        name: "Annual",
      },
    ];
  });
  // for new pagination start

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [dataPage, setDataPage] = useState(20);
  function handlePageChange(page) {
    setCurrentPage(page);
    // ... do something with `page`
  }

  // for new pagination end
  const onSearchChange = debounce((event) => {
    setSearchParam(event.target.value);
    setCurrentPage(1);
  }, 500);

  useEffect(() => {
    async function allSubscriptionData() {
      try {
        const response = await axiosInstance.get(
          `${getSubscriptionList}/${currentPage}/${dataPage}/${userFilter}/${subsFilter}/${searchParam}`
        );
        const apiData = response.data.data;
        setErrorHandle(null);
        if (apiData?.result?.length > 0) {
          let cnt = Math.ceil(apiData?.totalCount / dataPage);
          setTotalPages(cnt);
        }
        setResponseGet(apiData);
      } catch (err) {
        setErrorHandle(err);
      }
    }
    allSubscriptionData(responseGet);
    async function allSubscriptionCount() {
      try {
        let d1 = moment.utc(startDate).local().format("MM-DD-YYYYThh:mm:ss");
        let d2 = moment.utc(endDate).local().format("MM-DD-YYYYThh:mm:ss");
        const response2 = await axiosInstance.get(
          `${getSubscriptionCount}/${d1}/${d2}`
        );
        const apiData2 = response2.data.data;

        let arr = [];
        let arr3 = [];
        let arr4 = [];
        if (
          apiData2?.filter((x) => x?.freeCount !== 0 && x?.paidCount !== 0)
            .length > 0
        ) {
          arr3 = apiData2?.filter(
            (x) => x?.freeCount !== 0 && x?.paidCount !== 0
          );
          arr4 = arr3.map((x) => ({
            date: moment
              (x?.subscriptionStartDate)

              .format("MM-DD-YYYY"),
            type: "Paid",
            value: x?.paidCount == 0 ? null : x?.paidCount,
          }));
        }
        if (apiData2?.length > 0) {
          arr = apiData2.map((x) => ({
            date: moment
              (x?.subscriptionStartDate)

              .format("MM-DD-YYYY"),
            type: x?.freeCount !== 0 ? "Free" : "Paid",
            value:
              x?.freeCount !== 0
                ? x?.freeCount == 0
                  ? null
                  : x?.freeCount
                : x?.paidCount == 0
                ? null
                : x?.paidCount,
          }));
        }

        let arr5 = arr4.concat(arr);
        if (arr5?.length > 0) {
          setResponseGet2(arr5);
        }
      } catch (err) {
        console.log(err);
      }
    }
    allSubscriptionCount(responseGet2);
  }, [
    searchParam?.length,
    currentPage,
    startDate,
    endDate,
    userFilter,
    subsFilter,
  ]);

  useEffect(() => {
    async function csvSubscriptionData() {
      try {
        const response = await axiosInstance.get(`${getSubscriptionList}`);
        const apiData = response.data.data;
        setErrorHandle(null);

        const formattedResponse = apiData?.result?.map((response) => {
          const formattedDate = moment
            .utc(response?.subscriptionStartDate)

            .format("MM-DD-YYYY");

          return {
            ...response,
            subscriptionStartDate: formattedDate,
            subscriptionType:
              response.subscriptionType === 1
                ? "Free"
                : response.subscriptionType === 3
                ? "Monthly"
                : "Annual",
            userFName: decryptRequest(response?.userFName),
            userLName: decryptRequest(response?.userLName),
            email: decryptRequest(response?.email),
            phone: formatPhoneNumber(decryptRequest(response?.phone)),
          };
        });
        setCsvFormat(formattedResponse);
      } catch (err) {
        setErrorHandle(err);
      }
    }
    csvSubscriptionData(responseGet);
  }, []);

  const headers = [
    { label: "First Name", key: "userFName" },
    { label: "Last Name", key: "userLName" },
    { label: "User Type", key: "userType" },
    { label: "Gender", key: "gender" },
    { label: "Age", key: "age" },

    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "User Subscription Type", key: "subscriptionType" },
    { label: "Subscription Start Date", key: "subscriptionStartDate" },
  ];

  const csvReport = {
    data: csvFormat,
    headers: headers,
    filename: "subscription_list.csv",
  };
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  const headers2 = [
    { label: "Date", key: "date" },
    { label: "Type", key: "type" },
    { label: "Count", key: "count" },
  ];
  const csvReport2 = {
    data: responseGet2,
    headers: headers2,
    filename: "free_trial_users.csv",
  };

  return (
    <Layout>
      <div className="top-filter">
        <h1 className="title-heading mb-0">Subscription Details</h1>
      </div>
      <div className="top-filter">
        <div className="row main-header mb-0 gx-0 bg-transparent ">
          <div className="col">
            <h1 className="title-heading fs-18 mb-0">Paid v Free Users</h1>
          </div>
          <div className="col-auto">
            <div className="row align-items-center">
              <div className="col-auto title-heading fs-18">Data Range</div>
              <div className="col-auto">
                <div className="header-data">
                  <div className="date-space position-relative">
                    <DatePicker
                      className="btn btn-white btn-lg datepicker"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                    <i className="icon-calendar"></i>
                  </div>
                </div>
              </div>
              <div className="col-auto title-heading fs-18">to</div>
              <div className="col-auto">
                <div className="header-data">
                  <div className="date-space position-relative">
                    <DatePicker
                      className="btn btn-white btn-lg datepicker"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                    <i className="icon-calendar"></i>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                {responseGet2?.length > 0 ? (
                  <CSVLink
                    type="button"
                    className="btn btn-white btn-icon btn-lg btn-right ms-auto"
                    {...csvReport2}
                  >
                    <span>Download </span> <i className="icon-download"></i>
                  </CSVLink>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* chart data */}
      <div className="chart-data rounded-5 mb-5" style={{ height: "380px" }}>
        {responseGet2?.length > 0 ? (
          <SubscriptionChartComponent responseGet2={responseGet2} />
        ) : null}
      </div>
      {/* filter */}
      <div className="top-filter">
        <div className="row main-header justify-content-end gx-0 mb-0 bg-transparent">
          <div className="col-md-8">
            <div className="row">
              {/* select  */}
              <div className="col-auto">
                <CustomSelect
                  defaultText={userType}
                  optionsList={userList}
                  defaultValue={userFilter}
                  changeValue={setUserFilter}
                  changePage={setCurrentPage}
                />
              </div>
              <div className="col-auto">
                <CustomSelect
                  defaultText={subscriptionType}
                  optionsList={subscriptionList}
                  defaultValue={subsFilter}
                  changeValue={
                    setSubsFilter
                    // setCurrentPage(1);
                  }
                  changePage={setCurrentPage}
                />
              </div>
              <div className="col">
                <div className="searchbar me-4 placeholder-light">
                  <Popover content="Search by name, email, phone number">
                    <input
                      type="text"
                      className="form-control py-2"
                      name="search"
                      placeholder="Search by name, email.."
                      onChange={onSearchChange}
                    />
                  </Popover>
                  <i className="icon-search"></i>
                </div>
              </div>
              <div className="col-auto">
                {csvFormat?.length > 0 ? (
                  <CSVLink
                    type="button"
                    className="btn btn-white btn-icon btn-lg btn-right btn-round"
                    {...csvReport}
                  >
                    <span>Download </span> <i className="icon-download"></i>
                  </CSVLink>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* table */}
      <div className="bg-outer table-responsive">
        <table className="table seperate-column">
          <thead>
            <tr>
              <td>Name</td>
              <td>User Type</td>
              <td>Gender</td>
              <td>Age</td>
              <td>Email Address</td>
              <td>Mobile Number</td>
              <td>Subscription Type</td>
              <td>Subscription Date</td>
            </tr>
          </thead>
          <tbody>
            {/* {JSON.stringify(responseGet)} */}
            {responseGet && errorhandle == null ? (
              responseGet?.result?.map((users, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {" "}
                      {users.userFName === null
                        ? "- -"
                        : decryptRequest(users.userFName)}{" "}
                      {users.userLName === null
                        ? "- -"
                        : decryptRequest(users.userLName)}
                    </td>
                    <td>
                      {users.userType === null
                        ? "- -"
                        : users.userType === "PersonalAccount"
                        ? "Personal"
                        : "Guardian"}
                    </td>
                    <td>
                      {users.gender === null || users.gender === ""
                        ? "- -"
                        : users.gender}
                    </td>
                    <td>{users.age === null ? "- -" : users.age}</td>
                    <td>
                      {users.email === null
                        ? "- -"
                        : decryptRequest(users.email)}
                    </td>
                    <td>
                      {users.phone === null
                        ? "- -"
                        : formatPhoneNumber(decryptRequest(users.phone))}
                    </td>
                    <td>
                      {users.subscriptionType === 1 ? "Free" : null}{" "}
                      {users.subscriptionType === 3 ? "Monthly" : null}{" "}
                      {users.subscriptionType === 7 ? "Annual" : null} |{" "}
                      {users.storageType === 1 ? "2 GB" : null}{" "}
                      {users.storageType === 2 ? "10 GB" : null}
                    </td>
                    <td>
                      {" "}
                      {users?.subscriptionStartDate != null
                        ? moment
                            .utc(users?.subscriptionStartDate)
                            .local()
                            .format("MM-DD-YYYY")
                        : "- -"}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-black">No Users Found</tr>
            )}
          </tbody>
        </table>
        {responseGet?.result?.length > 0 ? (
          <Pagination
            total={totalPages}
            current={currentPage}
            onPageChange={(page) => handlePageChange(page)}
            maxWidth={10}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
          />
        ) : (
          <Pagination
            // {...bootstrap5PaginationPreset} // include Bootstrap 5 preset
            total={1}
            current={1}
            // onPageChange={(page) => handlePageChange(page)}
            maxWidth={3}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
            // showLimit={5}
          />
        )}
      </div>
    </Layout>
  );
};

export default Subscription;
