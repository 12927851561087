import axios from "axios";

export const activeSosCountAction = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("adminLoginToken");
    const effectiveUrl = `${process.env.REACT_APP_ENV_VARIABLE_KEY}/api/SOSAlert/ActiveSOSCount`;
    axios({
      method: "GET",
      url: effectiveUrl,
      headers: { Authorization: token},
    })
      .then((response) =>
        dispatch({ type: "SOS_COUNT_GET_DATA", payload: response.data }),
      )
      .catch((error) =>
        dispatch({ type: "SOS_COUNT_GET_DATA", payload: error.response.data }),
      );
  };
};
