import React from "react";

const BreadCrumb = (props) => {
  const onProfileClick = (event) => {
    event.preventDefault();
    props.setHeaderState(1);
  };
  const onTimelineClick = (event) => {
    event.preventDefault();
    props.setHeaderState(2);
  };
  const onSubscriptionClick = (event) => {
    event.preventDefault();
    props.setHeaderState(3);
  };
  const onLocationClick = (event) => {
    event.preventDefault();
    props.setHeaderState(4);
  };
  return (
    <div className="col-auto">
      <div className="col-md-12">
        <div className="btn-group-wrap">
          <button
            className={
              props?.headerState === 1
                ? "btn btn-xl btn-round active"
                : "btn btn-xl btn-round"
            }
            onClick={(event) => onProfileClick(event)}
          >
            Profile Details
          </button>
          <button
            className={
              props?.headerState === 2
                ? "btn btn-xl btn-round active"
                : "btn btn-xl btn-round"
            }
            onClick={(event) => onTimelineClick(event)}
          >
            Timeline
          </button>
          <button
            className={
              props?.headerState === 3
                ? "btn btn-xl btn-round active"
                : "btn btn-xl btn-round"
            }
            onClick={(event) => onSubscriptionClick(event)}
          >
            Subscription History
          </button>
          <button
            className={
              props?.headerState === 4
                ? "btn btn-xl btn-round active"
                : "btn btn-xl btn-round"
            }
            onClick={(event) => onLocationClick(event)}
          >
            Location History
          </button>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumb;
