const initialState = {
    profile: "",
  };
  
  export const profileData = (state = initialState, action) => {
    switch (action.type) {
      case "PROFILE_DATA": {
        return {
          ...state,
          profile: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  