export const getUserList = "/api/ManageUsers/UserList";
export const getCmsData = "/api/CMS/CMSList";
export const getActiveSos = "/api/SOSAlert/ActiveSOS";
export const getSosHistory = "/api/SOSAlert/SOSHistory";
export const getSubscriptionHistory = "/api/ManageUsers/SubscriptionHistory";
export const getSubscriptionList = "/api/ManageUsers/SubscriptionList";
export const getTotalCountAsOf = "/api/DashBoard/TotalCountAsOf";
export const getTotalCountBetweenDateRange =
  "/api/Dashboard/TotalCountBetweenDateRange";
export const getSubscriptionCount = "/api/ManageUsers/SubscriptionCount";
export const getUserProfileDetail = "/api/ManageUsers/ProfileDetail";
export const getUserTimeline = "/api/ManageUser/UsersTimeline";
export const getUserLocationHistory = "/api/Users/UserLocationDetails";
export const getInvoiceList = "/api/ManageUsers/SubscriptionsInvoiceList";
export const getuserTimelineDetails = "/api/Posts/GetCommutePostDetails";
export const getTotalActiveSosCount = "/api/DashBoard/TotalActiveSOSCount";
export const getTotalNewExistingtrialUserCount =
  "/api/DashBoard/TotalNewAndExistingTrailUserCount";
export const getTotalPaidUserGainLoss =
  "/api/DashBoard/TotalPaidUserGainOrLoss";
export const getPostDetailSos = "/api/SOS/GetSOSPostDetails";
export const getPostDetailsLocationCheckin =
  "/api/Posts/GetLocationCheckInPostDetails";
export const getPostDetailsNote = "/api/Posts/GetNotePostDetails";
export const getPostDetailsDate = "/api/Posts/GetDatePostDetails";
export const getPostDetailsRecording = "/api/Posts/GetRecordingDetails";
export const getPostDetailsCommute = "/api/Posts/GetCommutePostDetails";
export const getPostDetailsSafetyCheckin =
  "/api/Posts/SafetyCheckInPostDetails";
export const blockUser = "/api/ManageUsers/BlockUser";
export const deleteUser = "/api/ManageUsers/DeleteUser";
export const updateProfile = "/api/Users/UpdateUserProfileDetail";
export const updateProfileImage = "api/Users/UpdateUserProfileImage"
export const getSosChatDetail = "/api/Chat/GetSOSChatDetails";
export const getArchivedUsers = "/api/ManageUsers/ArchiveUserList";
export const getArchivedSos = "/api/SOSAlert/ArchivedSOS";
export const downloadUserTimeline = "/api/ManageUsers/DownloadTimeLine";
export const deactivateSos = "/api/ManageUsers/DeActivateSOS";
export const forgotPassword = "/api/ManageUsers/ManageForgetPassword";
export const changePassword = "/api/Users/ChangePassword";
export const resetPassword = "/api/ManageUsers/SaveResetPassword";
export const getCredentials = "/api/AzureManager/Credentials";
export const getFaqList = "/api/FAQ/FaqList";
export const editFaq = "/api/FAQ/EditFaq";
export const deleteFaq = "/api/FAQ/DeleteFaq";
export const createFaq = "/api/FAQ/SaveFaqs";
export const resetPasswordUser = "/api/ManageUsers/InitResetPassword";
export const resetPasscodeUser = "/api/ManageUsers/SendResetPasscodeLink";





