import * as CryptoJS from "crypto-js";

let algorithm = "aes-256-cbc";
let key = "&**&^@#$%^&*())(*&DCVbhjkFCVFGBH";
let iv = "#$%^&)(*&$%^&@#$";

// export const encryptRequest = (data) => {
//   const cipher = crypto.createCipheriv(algorithm, key, iv);
//   const encrypted = Buffer.concat([cipher.update(data), cipher.final()]);

//   return {
//     iv: iv.toString("base64"),
//     content: encrypted.toString("base64"),
//   };
// };
export function encryptRequest(text) {
  var keyWA = CryptoJS.enc.Utf8.parse(key);
  var ivWA = CryptoJS.enc.Utf8.parse(iv);
  var encryptedDataCP = CryptoJS.AES.encrypt(text, keyWA, { iv: ivWA });
  var encryptedWA = encryptedDataCP.ciphertext.toString(CryptoJS.enc.Hex);
  return encryptedWA;
}
// const dataFromHexadecimalString = () => {
//   let hexString =
//     "207A3913D143AF91B37C0246D0D472FEF6B4BCD38AB79BF16149E93BC4298B6159B9B149A2F44BA776018A2980FC8640";
//   let data = new Uint8Array(hexString.length / 2);
//   let regex = new RegExp("[0-9a-f]{1,2}", "gi");
//   let matches = hexString.match(regex);

//   for (let i = 0; i < matches.length; i++) {
//     let byteString = matches[i];
//     let num = parseInt(byteString, 16);
//     data[i] = num;
//   }

//   return cryptoJs.lib.WordArray.create(data);
//   // return CryptoJS.enc.Hex.parse(hexString);
// };
// export const decrypt = (hex) => {
//   const pass = cryptoJs.enc.Utf8.parse(key);
//   const iv = cryptoJs.enc.Utf8.parse(iv);
//   try {
//     var options = { mode: cryptoJs.mode.CBC, iv: iv };
//     var json = cryptoJs.AES.decrypt(
//       {
//         ciphertext: cryptoJs.enc.Hex.parse(hex),
//       },
//       pass,
//       options
//     );
//     return json.toString(cryptoJs.enc.Utf8);
//   } catch (err) {
//     console.error(err);
//     return "";
//   }
// };
// export const decryptRequest = (text) => {
//     let byteData = dataFromHexadecimalString();
//     const decryptedBytes = cryptoJs.AES.decrypt({
//       ciphertext: dataFromHexadecimalString(),
//       key: cryptoJs.enc.Utf8.parse("&**&^@#$%^&*())(*&DCVbhjkFCVFGBH"),
//       iv: cryptoJs.enc.Utf8.parse("#$%^&)(*&$%^&@#$"),
//       padding: cryptoJs.pad.Pkcs7,
//     });

//     const decryptedString = decryptedBytes.toString(cryptoJs.enc.Utf8);

// };

export function decryptRequest(text) {

  var encryptedWA = CryptoJS.enc.Hex.parse(text);
  var keyWA = CryptoJS.enc.Utf8.parse(key);
  var ivWA = CryptoJS.enc.Utf8.parse(iv);
  var encryptedDataCP = { ciphertext: encryptedWA };
  var decryptedWA = CryptoJS.AES.decrypt(encryptedDataCP, keyWA, { iv: ivWA });
  var decryptedData = decryptedWA.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

// export const decryptRequest = (input) => {
//   try {
//     let byteData = "207A3913D143AF91B37C0246D0D472FEF6B4BCD38AB79BF16149E93BC4298B6159B9B149A2F44BA776018A2980FC8640";
//     let decryptedInput = [];
//     byteData.forEach((data) => {
//       let tempObject = {};
//       for (let key in data) {
//         let tempArr2 = [];
//         if (data[key] instanceof Array) {
//           data[key].forEach((data2) => {
//             if (typeof data2 === "object") {
//               let tempObject2 = {};
//               for (let key in data2) {
//                 tempObject2[key] =
//                   data2[key] === null ? null : decrypt(data2[key].toString());
//               }
//               tempArr2.push(tempObject2);
//             } else {
//               let val = decrypt(data2.toString());
//               tempArr2.push(val);
//             }
//           });
//           tempObject[key] = tempArr2;
//         } else if (typeof data[key] === "object") {
//           let data3 = data[key];
//           let tempObject3 = {};
//           for (let key in data3) {
//             tempObject3[key] =
//               data3[key] === null ? null : decrypt(data3[key].toString());
//           }
//           tempObject[key] = tempObject3;
//         } else {
//           tempObject[key] =
//             data[key] === null ? null : decrypt(data[key].toString());
//         }
//       }
//       decryptedInput.push(tempObject);
//     });
//     return decryptedInput;
//   } catch (e) {
//     return "";
//   }
// };
