import React, { useState } from "react";
import UserManagement from "..";
import ArchivedUsers from "./ArchivedUsers";
import { useLocation } from "react-router-dom";

const UserTableMainComponent = () => {
  const [userHeadState, setUserHeadState] = useState(1);
  const location = useLocation()

  if (location?.state?.id == "archived") {
    setUserHeadState(2)
   }

  return (
    <div>
      {userHeadState === 1 ? (
        <UserManagement
          userHeadState={userHeadState}
          setUserHeadState={setUserHeadState}
        />
      ) : null}
      {userHeadState === 2 ? (
        <ArchivedUsers
          userHeadState={userHeadState}
          setUserHeadState={setUserHeadState}
        />
      ) : null}
    </div>
  );
};

export default UserTableMainComponent;
