import logo from "../assets/images/logo.png";
import loginbanner from "../assets/images/login-img.png";
import forgotpassbanner from "../assets/images/forgot-pass-img.png";
import forgotpasswordimg from "../assets/images/forgot_password.svg";
import restpassimg from "../assets/images/reset-pass-img.svg";
import profileimg from "../assets/images/profile.jpg";
import arrow from "../assets/images/click.svg";
import companions from "../assets/images/companions.svg";
import sharing_with from "../assets/images/sharing_with.svg";
import user2 from "../assets/images/user2.png";
import card from "../assets/images/card.svg";
import commute_type from "../assets/images/commute_type.svg";
import time from "../assets/images/time.svg";
import recording_play from "../assets/images/recording_play.svg";
import calender from "../assets/images/calender.svg";
import location from "../assets/images/location.svg";
import locationdark from "../assets/images/location-dark.svg";
import adminPic from "../assets/images/admin-pic.png";
const Images = Object.freeze({
  LOGO: logo,
  LOGINBANNER: loginbanner,
  FORGOTIMG: forgotpasswordimg,
  RESETIMG: restpassimg,
  PROFILE: profileimg,
  ARROW: arrow,
  COMPANIONS: companions,
  SHARE: sharing_with,
  USER2: user2,
  CARD: card,
  TIME: time,
  COMMUTETYPE: commute_type,
  RCORDING: recording_play,
  DATE: calender,
  LOCATION: location,
  LOCATIONDARK: locationdark,
  ADMINPIC: adminPic,
});

export default Images;
