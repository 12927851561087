import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Layout from "../../../layout";
import CustomSelect from "../../../../components/customSelect/CustomSelect";
import axiosInstance from "../../../../services/middleware";
import { getArchivedUsers } from "../../../../services/api/endpoints";
import Pagination from "react-responsive-pagination";
import { CSVLink } from "react-csv";
import { decryptRequest } from "../../../../services/helpers/crypto";

const ArchivedUsers = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { archivePageNo } = useSelector((state) => state.pageNoData);
  const [responseGet, setResponseGet] = useState([]);
  const [activeFilter, setActiveFilter] = useState(0);
  const [typeFilter, setTypeFilter] = useState(0);
  const [downloadData, setDownloadData] = useState([]);
  const [noRecord, setNoRecord] = useState(false);

  const [statusList] = useState(() => {
    return [
      {
        id: 0,
        name: "All ",
        icon: "icon-people",
      },
      {
        id: 4,
        name: "Blocked",
        icon: "icon-check-circle",
      },
      {
        id: 3,
        name: "Cancelled",
        icon: "icon-user",
      },
      {
        id: 2,
        name: "Deleted",
        icon: "icon icon-ban",
      },
    ];
  });
  const [defaultStatus] = useState(() => "User Status");
  const [userType] = useState(() => "User Type");
  const [userList] = useState(() => {
    return [
      {
        id: 0,
        name: "All",
        icon: "icon-people",
      },
      {
        id: 1,
        name: "Personal",
        icon: "icon-user",
      },
      {
        id: 2,
        name: "Guardian",
        icon: "icon-user",
      },
    ];
  });
  const onAlluserClick = (event) => {
    event.preventDefault();
    props.setUserHeadState(1);
  };
  const onArchiveUserClick = (event) => {
    event.preventDefault();
    props.setUserHeadState(2);
  };
  // for new pagination start

  const [currentPage, setCurrentPage] = useState(archivePageNo);
  const [totalPages, setTotalPages] = useState(0);
  const [dataPage, setDataPage] = useState(20);
  function handlePageChange(page) {
    setCurrentPage(page);
    // ... do something with `page`
  }
  // for new pagination end
  useEffect(() => {
    async function archivedUsersData() {
      try {
        const response = await axiosInstance.get(
          `${getArchivedUsers}/${currentPage}/${dataPage}/${activeFilter}/${typeFilter}`
        );

        const apiData = response.data.data;
        if (apiData?.result?.length > 0) {
          let cnt = Math.ceil(apiData?.totalCount / dataPage);
          setTotalPages(cnt);
        }
        setResponseGet(apiData);

        // setNoRecord(false);
      } catch (err) {
        // alert("network Error");
        // setNoRecord(true);
        console.log("err", err);
      }
    }

    archivedUsersData();
  }, [activeFilter, typeFilter, currentPage]);

  useEffect(() => {
    totalUsers();
  }, []);

  const totalUsers = async () => {
    try {
      const response = await axiosInstance.get(`${getArchivedUsers}`);

      const apiData = response.data.data.result;
      if (apiData?.length > 0) {
        // dispatch({ type: "IS_ARCHIVE", payload: false });

        // let cnt = Math.ceil(apiData?.totalCount / dataPage);
        // setTotalPages(cnt);
        if (apiData?.length > 0) {
          let arr2 = [];
          for (let i = 0; i < apiData.length; i++) {
            arr2.push({
              lName: decryptRequest(apiData[i]?.lName),
              age: apiData[i]?.age,
              gender: apiData[i]?.gender,
              name: decryptRequest(apiData[i]?.name),
              phone: formatPhoneNumber(decryptRequest(apiData[i]?.phone)),
              status:
                apiData[i]?.status == 1
                  ? "Active"
                  : apiData[i]?.status == 2
                  ? "Inactive"
                  : "Blocked",
              subscription: !apiData[i]?.subscription
                ? "- -"
                : apiData[i]?.subscription == 1
                ? "Free"
                : apiData[i]?.subscription == 3
                ? "Monthly"
                : "Annual",
              userType: apiData[i]?.userType,
              location: apiData[i]?.location,
              email: decryptRequest(apiData[i]?.email),
            });
          }
          if (arr2?.length > 0) {
            setDownloadData(arr2);
          }
        }
      }
      // setResponseGet(apiData);
      // setNoRecord(false);
    } catch (err) {
      // alert("network Error");
      setNoRecord(true);
    }
  };
  const onUserNameClick = (event, sos) => {
    event.preventDefault();
    dispatch({ type: "PROFILE_DATA", payload: sos });
    dispatch({ type: "ARCHIVE_PAGE_NO", payload: currentPage });
    navigate(`/profile`, {
      state: {
        id: "archived",
      },
    });
  };
  const headers = [
    { label: "First Name", key: "name" },
    { label: "Last Name", key: "lName" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Age", key: "age" },
    { label: "User Type", key: "userType" },
    { label: "Gender", key: "gender" },
  ];

  const csvReport = {
    data: downloadData,
    headers: headers,
    filename: "user_list.csv",
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  return (
    <Layout>
      <div className="top-filter">
        <div className="row">
          <div className="col-md-5">
            <div className="btn-group-wrap">
              <button
                className="btn btn-xl btn-round "
                onClick={(event) => onAlluserClick(event)}
              >
                All Users
              </button>
              <button
                className="btn btn-xl btn-round active"
                onClick={(event) => onArchiveUserClick(event)}
              >
                Archive Users
              </button>
            </div>
          </div>
          <div className="col-md-7">
            <div className="right-filter">
              <div className="form-group">
                <CustomSelect
                  defaultText={defaultStatus}
                  optionsList={statusList}
                  changeValue={setActiveFilter}
                />
              </div>
              <div className="form-group">
                <CustomSelect
                  defaultText={userType}
                  optionsList={userList}
                  changeValue={setTypeFilter}
                />
              </div>
              {responseGet?.result?.length > 0 ? (
                <CSVLink
                  type="button"
                  className="btn btn-white btn-icon btn-lg btn-right btn-round"
                  {...csvReport}
                >
                  <span>Download </span> <i className="icon-download"></i>
                </CSVLink>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-outer table-responsive">
        <table className="table seperate-column">
          <thead>
            <tr>
              <td>Name</td>
              <td>User Type</td>
              <td>Gender</td>
              <td>Age</td>
              <td>Location</td>
              <td>Email Address</td>
              <td>Mobile Number</td>
              <td>Subscription Type</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {responseGet ? (
              responseGet?.result?.map((sos, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <a onClick={(event) => onUserNameClick(event, sos)}>
                        {sos?.name === null ? "- -" : decryptRequest(sos?.name)}{" "}
                        {sos?.lName === null
                          ? "- -"
                          : decryptRequest(sos?.lName)}
                      </a>
                    </td>
                    <td>
                      {sos?.userType === "PersonalAccount"
                        ? "Personal"
                        : "Guardian"}
                    </td>
                    <td>{sos?.gender === null ? "- -" : sos?.gender}</td>
                    <td>{sos?.age === null ? "- -" : sos?.age}</td>
                    <td>{sos?.location === null ? "- -" : sos?.location}</td>
                    <td>
                      {sos?.email === null ? "- -" : decryptRequest(sos?.email)}
                    </td>
                    <td>
                      {sos?.phone === null
                        ? "- -"
                        : formatPhoneNumber(decryptRequest(sos?.phone))}
                    </td>
                    <td>
                      {sos?.subscription === 1 ? "Free" : null}{" "}
                      {sos?.subscription === 3 ? "Monthly" : null}{" "}
                      {sos?.subscription === 7 ? "Annual" : null}
                      {/* |{" "}
                      {users.storageType === 1 ? "2 GB" : null}{" "}
                      {users.storageType === 2 ? "10 GB" : null} */}
                    </td>
                    <td>
                      {sos?.status === 4 ? "Blocked" : null}{" "}
                      {sos?.status === 3 ? "Cancelled" : null}{" "}
                      {sos?.status === 2 ? "Deleted" : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-black">
                <span className="">No Users found </span>
              </tr>
            )}
          </tbody>
        </table>
        {responseGet?.result?.length > 0 ? (
          <Pagination
            total={totalPages}
            current={currentPage}
            onPageChange={(page) => handlePageChange(page)}
            maxWidth={10}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
          />
        ) : (
          <Pagination
            // {...bootstrap5PaginationPreset} // include Bootstrap 5 preset
            total={1}
            current={1}
            // onPageChange={(page) => handlePageChange(page)}
            maxWidth={3}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
            // showLimit={5}
          />
        )}
      </div>
    </Layout>
  );
};

export default ArchivedUsers;
