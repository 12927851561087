import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import SosBreadcrumb from "../sosBreadcrumb/SosBreadcrumb";
import Images from "../../../Images";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import axiosInstance from "../../../../services/middleware";
import { getActiveSos } from "../../../../services/api/endpoints";
import SosDetails from "../sosDetails/SosDetails";
import Pagination, {
  bootstrap5PaginationPreset,
} from "react-responsive-pagination";
import { CSVLink } from "react-csv";
import { decryptRequest } from "../../../../services/helpers/crypto";

const center = { lat: 48.8584, lng: 2.2945 };
const containerStyle = {
  width: "400px",
  height: "200px",
};
const ActiveSos = (props) => {
  const navigate = useNavigate();
  const [responseGet, setResponseGet] = useState([]);
  const [pageState, setPageState] = useState(0);
  const [map, setMap] = React.useState(null);
  const { loginData } = useSelector((state) => state.adminLoginData);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedSos, setSelecetedSos] = useState(null);
  const [csvFormat,setCsvFormat] = useState(null)
  const dataPerPage = 3;
  const pagesVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(responseGet?.length / dataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  // for new pagination start

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [dataPage, setDataPage] = useState(20);
  function handlePageChange(page) {
    setCurrentPage(page);
    // ... do something with `page`
  }

  // for new pagination end
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  // const onLoad = React.useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);

  //   setMap(map);
  // }, []);
  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  useEffect(() => {
    async function activeSosData() {
      try {
        const response = await axiosInstance.get(
          `${getActiveSos}/${currentPage}/${dataPage}`
        );
        const apiData = response.data.data;
        if (apiData?.result?.length > 0) {
          let cnt = Math.ceil(apiData?.totalCount / dataPage);
          setTotalPages(cnt);
          
        }
        setResponseGet(apiData);

      } catch (err) {
        console.error("network Error");
      }
    }

    activeSosData();
  }, [currentPage,pageState]);

  useEffect(() => {
    async function allActiveSosData() {
      try {
        const response = await axiosInstance.get(
          `${getActiveSos}`
        );
        const apiData = response.data.data;
       
  
        const formattedResponse = apiData?.result?.map(response => {
          const formattedDate = moment
            .utc(response?.startDate)
  
            .format("MM-DD-YYYY");
          const formattedTime = moment
          .utc(
            response?.startDate?.slice(0, 10) +
              "T" +
              response?.startTime
          )
          .local()
  
          .format("h:mm A");
     const formattedNumber = formatPhoneNumber(decryptRequest(response?.phone))
          return {
            ...response,
            startDate: formattedDate,
            startTime: formattedTime,
            phone:formattedNumber,
            fName:decryptRequest(response?.fName),
            lName:decryptRequest(response?.lName),
            email:decryptRequest(response?.email)
          };
        });
        setCsvFormat(formattedResponse)
      } catch (err) {
        console.error("network Error");
      }
    }
    allActiveSosData() 
  }, [])
  

  

  const onSosCLick = (sos) => {
    setPageState(1);
    setSelecetedSos(sos);
  };
  const headers = [
    { label: "Start Date", key: "startDate" },
    { label: "First Name", key: "fName" },
    { label: "Last Name", key: "lName" },
    { label: "Start Time", key: "startTime" },
    { label: "Email Address", key: "email" },
    { label: "Mobile Number", key: "phone" },
    { label: "Start Location ", key: "locationName" },
  ];

  const csvReport = {
    data: csvFormat,
    headers: headers,
    filename: "active_sos_list.csv",
  };
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }
  return (
    <>
      {pageState === 0 ? (
        <Layout>
          <div className="top-filter">
            <div className="row">
              <div className="col-md-12">
                <SosBreadcrumb
                  setHeaderState={props.setHeaderState}
                  headerState={props.headerState}
                />
              </div>
            </div>
          </div>
          <div className="top-filter">
            <div className="d-flex align-items-center">
              <h1 className="title-heading mb-0">Active SOS Alerts</h1>

              {csvFormat?.length > 0 ? (
                <CSVLink
                  type="button"
                  className="btn btn-white btn-icon btn-lg btn-right btn-round w-auto ms-auto"
                  {...csvReport}
                >
                  <span>Download </span> <i className="icon-download me-2"></i>
                </CSVLink>
              ) : null}
            </div>
          </div>
          <div className="bg-outer">
            <div className="table-responsive">
              <table className="table seperate-column">
                <thead>
                  <tr>
                    <td>Start Date</td>
                    <td>Name</td>
                    <td>Start Time</td>
                    <td>Email Address</td>
                    <td>Mobile Number</td>
                    <td>Start Location</td>
                  </tr>
                </thead>
                <tbody>
                  {responseGet ? (
                    responseGet?.result?.map((sos, index) => {
                      return (
                        <tr
                          onClick={() => onSosCLick(sos)}
                          className="cursor-pointer"
                          key={index}
                        >
                          <td>
                            {moment
                              .utc(sos?.startDate)

                              .format("MM-DD-YYYY")}
                          </td>
                          <td>{decryptRequest(sos?.fName) + " " + decryptRequest(sos?.lName)}</td>
                          <td>
                          {moment
                                .utc(
                                  sos?.startDate?.slice(0, 10) +
                                    "T" +
                                    sos?.startTime
                                )
                                .local()
          
                                .format("h:mm A")}
                          </td>
                          <td>{decryptRequest(sos?.email)}</td>
                          <td>{formatPhoneNumber(decryptRequest(sos?.phone))}</td>
                          <td>{sos?.locationName}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">No Active SOS Found </tr>
                  )}
                </tbody>
              </table>
            </div>

            {responseGet?.result?.length > 0 ? (
              <Pagination
                total={totalPages}
                current={currentPage}
                onPageChange={(page) => handlePageChange(page)}
                maxWidth={10}
                previousLabel="< Prev"
                nextLabel="Next >"
                narrowStrategy="dropEllipsis"
              />
            ) : <Pagination
            // {...bootstrap5PaginationPreset} // include Bootstrap 5 preset
            total={1}
            current={1}
            // onPageChange={(page) => handlePageChange(page)}
            maxWidth={3}
            previousLabel="< Prev"
            nextLabel="Next >"
            narrowStrategy="dropEllipsis"
            // showLimit={5}
          />}
          </div>
        </Layout>
      ) : (
        <SosDetails setPageState={setPageState} selectedSos={selectedSos} />
      )}
    </>
  );
};

export default ActiveSos;
