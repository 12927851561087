import React, { useState, useEffect } from "react";

import { Column } from "@ant-design/charts";

const SubscriptionChartComponent = ({ responseGet2 }) => {
  const [data, setData] = useState(responseGet2);
  // function sortArray() {
  //   const sortedArray = [...data].sort((a, b) => {
  //     const dateA = new Date(a.date);
  //     const dateB = new Date(b.date);
  //     return dateA - dateB;
  //   });
  //   setData(sortedArray);
  // }
  // const config = {
  //   data,
  //   isStack: true,
  //   xField: "date",
  //   yField: "value",
  //   seriesField: "type",
  //   yAxis: {
  //     nice: false,
  //     tickInterval: 1,
  //   },
  //   // tooltip: {
  //   //   shared: true, // Enable shared tooltip for multiple series
  //   //   showMarkers: false, // Disable marker symbols in the tooltip
  //   //   formatter: (datum) => `${datum.value}`, // Format the tooltip content to display the count
  //   // },
  //   label: {
  //     // 可手动配置 label 数据标签位置
  //     position: 'middle',
  //     // 'top', 'bottom', 'middle'
  //     // 可配置附加的布局方法
  //     layout: [
  //       // 柱形图数据标签位置自动调整
  //       {
  //         type: 'interval-adjust-position',
  //       }, // 数据标签防遮挡
  //       {
  //         type: 'interval-hide-overlap',
  //       }, // 数据标签文颜色自动调整
  //       {
  //         type: 'adjust-color',
  //       },
  //     ],
  //   },
  // };
  const config = {
    data,
    isStack: true,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    yAxis: {
      nice: false,
      tickInterval: 1,
    },
    xAxis: {
      type: 'time',
      tickCount: data.length,
    },
    // tooltip: {
    //   formatter: (datum) => {
    //     return `Date: ${datum.date}\nValue: ${datum.value}`;
    //   },
    // },
    label: {
      position: "middle",
      style: {
        fontSize: 16,
        fontWeight: 600,
        fill: "#333333",
      },
      // hello
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
      ],
      
    },
   
  };

  useEffect(() => {
    // sortArray()
    let data = responseGet2.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });
    setData(data);
  }, [responseGet2]);

  return (
    <div>
      <div className="graph-wrapper">
        <div className="graph w-100">
          <div className="report-box">
            <Column {...config} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionChartComponent;
