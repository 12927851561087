import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Images from "../Images";
import { useDispatch, useSelector } from "react-redux";
import { activeSosCountAction } from "../../actions/SosActions";
import Logout from "../modal/logout";
import Swal from "sweetalert2";

const Sidebar = () => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sosCountData } = useSelector((state) => state.sosCountFun);
  const { pageRoute } = useSelector((state) => state.pageRouteFun);

  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
    document.body.classList.toggle("mini-sidebar");
  };

  const onSidebarClick = (route) => {
    dispatch({ type: "PAGE_ROUTE_DATA", payload: route })
  }
  useEffect(() => {
    if (sosCountData?.success === false && sosCountData?.httpStatus === 401) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: sosCountData?.message,
        showConfirmButton: false,
        timer: 2000,
      });
      setTimeout(() => {
        localStorage.clear();
        dispatch({ type: "RESET_STATE" });
        navigate('/')
      }, 2000);
    }
  }, [sosCountData])

  useEffect(() => {
    dispatch(activeSosCountAction());
  }, [dispatch]);

  useEffect(() => {
    if (
      window.location.pathname !== "/user-management" &&
      window.location.pathname !== "/profile"
    ) {

      dispatch({ type: "PAGE_NO", payload: 1 });
      dispatch({ type: "IS_ARCHIVE", payload: false });
    }
  }, [window.location.pathname]);


  return (
    <>
      <div className="sidebar-wrapper">

        <div className="logo-header">
          <img src={Images.LOGO} className="header-logo logo-main" alt="Logo" />
        </div>
        <ul className="sidebar">
          <li
            className={
              pageRoute === "dashboard" ? "active" : ""
            }
          >
            <Link to="/dashboard" onClick={() => onSidebarClick("dashboard")}>
              <i className="icon-dashboard nav-icon"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li
            className={
              pageRoute === "user-management" ? "active" : ""
            }
          >
            <Link to="/user-management" onClick={() => onSidebarClick("user-management")}>
              <i className="icon-user-outline nav-icon"></i>
              <span className="nav-text">User Management</span>
            </Link>
          </li>
          <li
            className={
              pageRoute === "active-sos" ? "active" : ""
            }
          >
            <Link to="/active-sos" onClick={() => onSidebarClick("active-sos")}>
              <i className="icon-sos nav-icon"></i>
              <span className="nav-text">
                SOS Alert
                <span className="badge fs-6 bg-white text-black ms-3 rounded-circle">
                  {sosCountData?.data}
                </span>
              </span>
            </Link>
          </li>
          <li
            className={
              pageRoute === "subscription" ? "active" : ""
            }
          >
            <Link to="/subscription" onClick={() => onSidebarClick("subscription")}>
              <i className="icon-dollar nav-icon"></i>
              <span className="nav-text">Subscription</span>
            </Link>
          </li>
          <li
            className={
              pageRoute === "invoice-manage" ? "active" : ""
            }
          >
            <Link to="/invoice-manage" onClick={() => onSidebarClick("invoice-manage")}>
              <i className="icon-files nav-icon"></i>
              <span className="nav-text">Invoice Management</span>
            </Link>
          </li>
          <li
            className={
              pageRoute === "cms" ? "active" : ""
            }
          >
            <Link to="/cms" onClick={() => onSidebarClick("cms")}>
              <i className="icon-document nav-icon"></i>
              <span className="nav-text">CMS</span>
            </Link>
          </li>
        </ul>

        {/* <Link className="logout-btn" to="/">
          <Link
            className="nav-text"
            onClick={() => setOpenModal(true)}
            style={{ color: "white" }}
          >
            <i className="icon-logout nav-icon"></i>
            <span className="nav-text">Logout</span>
          </Link>
        </Link> */}
        <button type="button" className="logout-btn" onClick={() => setOpenModal(true)}>
          <i className="icon-logout nav-icon"></i>
          <span className="nav-text">Logout</span>
        </button>
      </div>
      <Logout openModal={openModal} closeModal={() => setOpenModal(false)} />
    </>
  );
};

export default Sidebar;
