import React from "react";
import { Modal } from "react-bootstrap";
import { decryptRequest } from "../../services/helpers/crypto";

const BlockUserModal = (props) => {
  return (
    <Modal show={props.openModal} size="md" className="custom-modal" centered>
      <Modal.Header>
        <Modal.Title>Block User</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => {
            props.setOpenModal(false);
          }}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="block-user-modal">
          <p>Are you sure you want to {props?.selectedUser?.status == 4 ? "unblock" : "block"} {!props?.selectedUser?.name ? "" : decryptRequest(props?.selectedUser?.name)}?</p>

          <div className="text-center">
            <button
              className="btn btn-light-danger btn-xxl btn-round d-block m-auto btn-shadow"
              onClick={() => props.confirm(props?.selectedUser)}
            >
              {props?.selectedUser?.status == 4 ? "Unblock" : "Block"}
            </button>
            <button
              className="btn"
              onClick={() => {
                props.setOpenModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BlockUserModal;
