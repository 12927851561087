import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store, { persistor } from "./store";
import "../src/assets/globals.scss";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import 'antd/dist/antd.css';
// store.subscribe(() => store.getState());

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);
