import React, { useRef, useState, useEffect } from "react";
import Images from "../Images";
// import {
//   useJsApiLoader,
//   GoogleMap,
//   Marker,
//   Autocomplete,
//   DirectionsRenderer,
//   Polyline,
//   MarkerF,
// } from "@react-google-maps/api";
import moment from "moment/moment";
import ReactAudioPlayer from "react-audio-player";
import { CSVLink } from "react-csv";
import BatteryGauge from "react-battery-gauge";

const RightSidebar = ({ responseGet2, postType, geoAddress, loading }) => {
  const [detailCsv, setDetailsCsv] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [objectUrl, setObjectUrl] = useState(null);
  const [vidObjectUrl, setVidObjectUrl] = useState(null);

  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  // });
  const CustomBattery = {
    batteryBody: {
      strokeWidth: 4,
      cornerRadius: 6,
      fill: "none",
      strokeColor: "#111",
    },
    batteryCap: {
      fill: "none",
      strokeWidth: 4,
      strokeColor: "#111",
      cornerRadius: 2,
      capToBodyRatio: 0.4,
    },
    batteryMeter: {
      fill: "black",
      lowBatteryValue: 15,
      lowBatteryFill: "red",
      outerGap: 8,
      noOfCells: 1,
      interCellsGap: 1,
    },
    readingText: {
      lightContrastColor: "#111",
      darkContrastColor: "#fff",
      lowBatteryColor: "red",
      fontFamily: "Helvetica",
      fontSize: 14,
      display: "none",
      showPercentage: false,
    },
    chargingFlash: {
      scale: undefined,
      fill: "orange",
      animated: true,
      animationDuration: 1000,
    },
  };
  const handleSeek = (e) => {
    setCurrentTime(e.target.currentTime);
  };
  useEffect(() => {
    if (responseGet2 != null) {
      setDetailsCsv([responseGet2]);
    }
  }, [responseGet2]);

  useEffect(() => {
    if (responseGet2?.duration && responseGet2?.postMedia) {
      fetch(responseGet2?.postMedia?.url)
        .then((response) => response.blob())
        .then((audioBlob) => {
          const url = URL.createObjectURL(audioBlob);
          setObjectUrl(url);
        })
        .catch((error) => {
          console.error("Error fetching audio:", error);
        });
    }
  }, [responseGet2]);
  useEffect(() => {
    if (responseGet2?.postMedia && responseGet2?.postMedia.length > 0) {
      const fetchPromises = responseGet2?.postMedia.map((videoData) => {
        return fetch(videoData.url)
          .then((response) => response.blob())
          .then((videoBlob) => {
            const url = URL.createObjectURL(videoBlob);
            return {
              url,
              duration: videoData.duration,
              fileSize: videoData.fileSize,
              fileType: videoData.fileType,
            };
          })
          .catch((error) => {
            console.error("Error fetching video:", error);
            return null;
          });
      });

      Promise.all(fetchPromises).then((videoUrls) => {
        const validVideoUrls = videoUrls.filter((url) => url !== null);
        setVidObjectUrl(validVideoUrls);
      });
    }
  }, [responseGet2]);

  useEffect(() => {
    // Revoke the Object URL when it's no longer needed
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [objectUrl]);

  const headers = [
    { label: "Address Line 1", key: "addressLine1" },
    { label: "Address Line 2", key: "addressLine2" },
    { label: "Battery %", key: "batteryPercentage" },
    { label: "Post Start Date", key: "datePostStartDate" },
    { label: "Post Start Time", key: "datePostStartTime" },
    { label: "Dating Service", key: "datingService" },
    { label: "Description", key: "description" },
    { label: "Latitude", key: "lattitude" },
    { label: "Longitude", key: "longitude" },
    { label: "Location Name", key: "locationName" },
  ];
  const csvReport = {
    data: detailCsv,
    headers: headers,
    filename: "selected_timelline_detail.csv",
  };
  return (
    <div className="bg-outer right-sidebar">
      <div className="side-head">
        <h1 className="title-heading">Detailed View</h1>
        {/* {detailCsv?.length > 0 ? (
          <CSVLink
            type="button"
            className="btn btn-white btn-icon btn-lg btn-right ms-auto"
            {...csvReport}
          >
            <span>Download </span> <i className="icon-download"></i>
          </CSVLink>
        ) : null} */}
      </div>
      <div className="side-content fw-semibold">
        {responseGet2 === null ? (
          <div className="default-view text-center">
            <div className="circular-arrow">
              <img src={Images.ARROW} alt="arrow" />
            </div>
            <p>Click on an item from the left to view details here.</p>
          </div>
        ) : (
          <>
            {loading === true ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status"></div>
              </div>
            ) : (
              <>
                <div className="sidebar-top mb-3">
                  {postType === 3 ? (
                    <h6 className="text-center fs-14 fw-bold mb-4">Commute</h6>
                  ) : null}
                  {postType === 1 ? (
                    <h6 className="text-center fs-14 fw-bold mb-4">Location</h6>
                  ) : null}
                  {postType === 20 ? (
                    <h6 className="text-center fs-14 fw-bold mb-4">
                      {responseGet2?.didEnter == true
                        ? "Arrived at " + responseGet2?.locationName
                        : "Departed from " + responseGet2?.locationName}
                    </h6>
                  ) : null}
                  {postType === 2 ? (
                    <h6 className="text-center fs-14 fw-bold mb-4">Date</h6>
                  ) : null}
                  {postType === 4 ? (
                    <h6 className="text-center fs-14 fw-bold mb-4">Note</h6>
                  ) : null}
                  {postType === 5 ? (
                    <h6 className="text-center fs-14 fw-bold mb-4">
                      Safety Check-In
                    </h6>
                  ) : null}
                  {postType === 21 ? (
                    <h6 className="text-center fs-14 fw-bold mb-4">
                      Safety Check-In Missed
                    </h6>
                  ) : null}
                  {postType === 6 ? (
                    <h6 className="text-center fs-14 fw-bold mb-4">
                      Recording
                    </h6>
                  ) : null}
                  {postType === 7 ? (
                    <>
                      {responseGet2?.isSOSCancelled == true ? (
                        <h6 className="text-center fs-14 fw-bold mb-4">
                          Cancelled SOS
                        </h6>
                      ) : (
                        <h6 className="text-center fs-14 fw-bold mb-4">SOS</h6>
                      )}
                    </>
                  ) : null}
                  {postType === 8 ? (
                    <h6 className="text-center fs-14 fw-bold mb-4">
                      Initiated SOS
                    </h6>
                  ) : null}
                  <div className="view-status d-flex justify-content-between fs-12 head-time">
                    <p className="mb-2">
                      {moment
                        .utc(responseGet2?.createdAt)
                        .local()
                        .format("h:mm A")}
                    </p>
                    <p className="violet-color mb-2">
                      {moment
                        .utc(responseGet2?.createdAt)
                        .local()
                        .format("MMMM D, YYYY")}
                    </p>

                    <div className="battery-btn d-inline-flex align-items-center px-2">
                      <BatteryGauge
                        className="me-2"
                        value={responseGet2?.batteryPercentage}
                        size={24}
                        customization={CustomBattery}
                      />
                      {responseGet2?.batteryPercentage}%
                    </div>
                  </div>
                </div>
                {/* Commute */}
                {postType === 3 ? (
                  <div className="way-to-work">
                    <p className="fs-12 mb-2">{responseGet2?.description}</p>
                    <div className="map-box mb-3">
                      {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2515839006487!2d77.36386661545025!3d28.622220991297475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff430a9852f%3A0x11b0f6c89f81224e!2sTechAhead!5e0!3m2!1sen!2sin!4v1667380582022!5m2!1sen!2sin"
                    title="maps"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe> */}
                      {/* {isLoaded ? (
                    <GoogleMap
                      center={{
                        lat: responseGet2?.lattitude,
                        lng: responseGet2?.longitude,
                      }}
                      zoom={10}
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                    >
                      <MarkerF
                        position={{
                          lat: responseGet2?.lattitude,
                          lng: responseGet2?.longitude,
                        }}
                      />
                      <MarkerF
                        position={{
                          lat: responseGet2?.commuteChildPost?.lattitude,
                          lng: responseGet2?.commuteChildPost?.longitude,
                        }}
                      />

                      <Polyline
                        path={[
                          {
                            lat: responseGet2?.lattitude,
                            lng: responseGet2?.longitude,
                          },
                          {
                            lat: responseGet2?.commuteChildPost?.lattitude,
                            lng: responseGet2?.commuteChildPost?.longitude,
                          },
                        ]}
                        geodesic={true}
                        options={{
                          strokeColor: "purple",
                          strokeOpacity: 1,
                          strokeWeight: 4,
                        }}
                      />
                    </GoogleMap>
                  ) : null} */}
                      <iframe
                        name="gMap"
                        className="w-100"
                        style={{ height: "100%" }}
                        src={`https://www.google.com/maps/embed/v1/place?q=${responseGet2?.lattitude},${responseGet2?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                      ></iframe>
                    </div>
                    <div className="commut-type mb-4">
                      <p className="d-inline-flex align-items-center mb-0">
                        <img
                          src={Images.COMMUTETYPE}
                          className="me-2"
                          alt="companions"
                        />{" "}
                        <span>Commute type</span>
                      </p>
                      <p className="fw-normal fs-12 mb-0">
                        {responseGet2?.commuteType}
                      </p>
                    </div>
                    <div className="commute-start-end mb-4">
                      <div className="commute-start mb-4">
                        <div className="head-time d-flex justify-content-between align-items-center">
                          <p className="d-inline-flex align-items-center mb-0">
                            <img
                              src={Images.TIME}
                              className="me-2"
                              alt="companions"
                            />{" "}
                            <span>Commute start</span>
                          </p>
                          {/* <button className="battery-btn d-inline-flex align-items-center">
                        <i className="icon-battery-half me-1"></i>
                        <span className="battery-percent">
                          {responseGet2?.batteryPercentage}%
                        </span>
                      </button> */}
                          <div className="battery-btn d-inline-flex align-items-center px-2">
                            <BatteryGauge
                              className="me-2"
                              value={responseGet2?.batteryPercentage}
                              size={24}
                              customization={CustomBattery}
                            />
                            {responseGet2?.batteryPercentage}%
                          </div>
                        </div>
                        <p className="fs-12 mb-0 fw-normal">
                          {moment
                            .utc(
                              responseGet2?.commutePostStartDate?.slice(0, 10) +
                                "T" +
                                responseGet2?.commutePostStartTime
                            )
                            .local()

                            .format("MMMM DD, YYYY | h:mm A")}
                        </p>
                        <p className="fs-12 mb-0 fw-normal">
                          <strong>
                            {responseGet2?.addressLine1 ==
                            responseGet2?.locationName
                              ? responseGet2?.locationName
                              : responseGet2?.locationName}
                            {/* {!responseGet2?.addressLine1
                          ? responseGet2?.locationName
                          : null} */}

                            <br />
                          </strong>
                          {!responseGet2?.addressLine1 ? null : (
                            <span className="d-block">
                              {responseGet2?.addressLine1}
                            </span>
                          )}
                          {!responseGet2?.addressLine2 ? null : (
                            <>
                              {responseGet2?.addressLine2?.split(",")[0] +
                                ", " +
                                responseGet2?.addressLine2?.split(",")[1] +
                                " " +
                                responseGet2?.addressLine2?.split(",")[2]}
                              <span className="d-block">
                                {responseGet2?.addressLine2?.split(",")[3]}
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                      {!responseGet2?.commuteChildPost ? null : (
                        <div className="commute-end mb-4">
                          <div className="head-time d-flex justify-content-between align-items-center">
                            <p className="d-inline-flex align-items-center mb-0">
                              <img
                                src={Images.TIME}
                                className="me-2"
                                alt="companions"
                              />{" "}
                              <span>Commute end</span>
                            </p>
                            {/* <button className="battery-btn d-inline-flex align-items-center">
                        <i className="icon-battery-half me-1"></i>
                        <span className="battery-percent">
                          {responseGet2?.commuteChildPost?.batteryPercentage}%
                        </span>
                      </button> */}
                            <div className="battery-btn d-inline-flex align-items-center px-2">
                              <BatteryGauge
                                className="me-2"
                                value={
                                  responseGet2?.commuteChildPost
                                    ?.batteryPercentage
                                }
                                size={24}
                                customization={CustomBattery}
                              />
                              {
                                responseGet2?.commuteChildPost
                                  ?.batteryPercentage
                              }
                              %
                            </div>
                          </div>
                          <p className="fs-12 mb-0 fw-normal">
                            {moment
                              .utc(
                                responseGet2?.commuteChildPost?.commutePostEndDate?.slice(
                                  0,
                                  10
                                ) +
                                  "T" +
                                  responseGet2?.commuteChildPost
                                    ?.commutePostEndTime
                              )
                              .local()

                              .format("MMMM DD, YYYY | h:mm A")}
                          </p>
                          <p className="fs-12 mb-0 fw-normal">
                            <strong>
                              {responseGet2?.commuteChildPost?.addressLine1 ==
                              responseGet2?.commuteChildPost?.locationName
                                ? responseGet2?.commuteChildPost?.locationName
                                : responseGet2?.commuteChildPost?.locationName}
                              {/* {!responseGet2?.commuteChildPost?.addressLine1
                            ? responseGet2?.commuteChildPost?.locationName
                            : null} */}
                              <br />
                            </strong>
                            {!responseGet2?.commuteChildPost
                              ?.addressLine1 ? null : (
                              <span className="d-block">
                                {responseGet2?.commuteChildPost?.addressLine1}
                              </span>
                            )}
                            {!responseGet2?.commuteChildPost
                              ?.addressLine2 ? null : (
                              <>
                                {responseGet2?.commuteChildPost?.addressLine2?.split(
                                  ","
                                )[0] +
                                  ", " +
                                  responseGet2?.commuteChildPost?.addressLine2?.split(
                                    ","
                                  )[1] +
                                  " " +
                                  responseGet2?.commuteChildPost?.addressLine2?.split(
                                    ","
                                  )[2]}
                                <span className="d-block">
                                  {
                                    responseGet2?.commuteChildPost?.addressLine2?.split(
                                      ","
                                    )[3]
                                  }
                                </span>
                              </>
                            )}
                          </p>
                        </div>
                      )}

                      <div className="share-profile mb-4">
                        {responseGet2?.sharingWith?.length > 0 ? (
                          <>
                            <p className="d-inline-flex align-items-center mb-3">
                              <img
                                src={Images.SHARE}
                                className="me-2"
                                height="14px"
                                alt=""
                              />
                              <span>You're sharing with</span>
                            </p>

                            <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                              {responseGet2?.sharingWith.map((item, index) => {
                                return (
                                  <>
                                    {!item?.userProfilePic ? (
                                      <li className="user me-3" key={index}>
                                        <span className="text-word">
                                          {item?.userFriendName?.substring(
                                            0,
                                            1
                                          )}
                                        </span>
                                        {/* </div> */}
                                        <p className="text-center mb-2 fw-normal">
                                          {item.userFriendName}
                                        </p>
                                      </li>
                                    ) : (
                                      <li className="user me-3">
                                        <div className="img-box">
                                          <img
                                            src={item.url}
                                            className="user-img"
                                            alt="profile"
                                          />
                                        </div>
                                        <p className="text-center mb-2 fw-normal">
                                          {item.userFriendName}
                                        </p>
                                      </li>
                                    )}
                                  </>
                                );
                              })}
                            </ul>
                          </>
                        ) : null}
                      </div>
                      {/* {responseGet2?.companions?.length > 0 ? ( */}
                      <div className="share-profile mb-4">
                        {responseGet2?.companions?.length > 0 ? (
                          <>
                            <p className="d-inline-flex align-items-center mb-3">
                              {/* <img
                            src={Images.SHARE}
                            className="me-2"
                            height="14px"
                            alt=""
                          /> */}
                              <i className="icon-people me-2 fs-18 text-danger"></i>
                              <span>Companions</span>
                            </p>

                            <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                              {responseGet2?.companions.map((item, index) => {
                                return (
                                  <>
                                    <li className="user me-3" key={index}>
                                      <span className="text-word">
                                        {item?.name?.substring(0, 1)}
                                      </span>
                                      {/* </div> */}
                                      <p className="text-center mb-2">
                                        {item.name}
                                      </p>
                                    </li>
                                  </>
                                );
                              })}
                            </ul>
                          </>
                        ) : null}
                      </div>
                      {/* ) : null} */}

                      <>
                        <p className="d-inline-flex align-items-center mb-0">
                          <img
                            src={Images.CARD}
                            className="me-2 "
                            height="13px"
                            alt="companions"
                          />
                          <span>License plate number</span>
                        </p>
                        <p className="fs-12 fw-normal mb-2">
                          {!responseGet2?.licensePlateNo &&
                          responseGet2?.postMedia?.length == 0
                            ? "NA"
                            : responseGet2?.licensePlateNo}
                        </p>
                        {!responseGet2?.postMedia ||
                        !responseGet2?.postMedia?.fileName ||
                        responseGet2?.postMedia?.fileName === "null" ? (
                          ""
                        ) : (
                          <div className="car-img mb-3">
                            <img src={responseGet2?.postMedia?.url} alt="" />
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                ) : null}

                {/* user profile */}
                {postType === 2 ? (
                  <div className="bottom-user">
                    <div className="companions-profile mb-4">
                      <p className="fs-12 mb-2">{responseGet2?.description}</p>
                      <div className="map-box mb-4">
                        {/* {isLoaded ? (
                      <GoogleMap
                        center={{
                          lat: responseGet2?.lattitude,
                          lng: responseGet2?.longitude,
                        }}
                        zoom={10}
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                      >
                        <MarkerF
                          position={{
                            lat: responseGet2?.lattitude,
                            lng: responseGet2?.longitude,
                          }}
                        />
                      </GoogleMap>
                    ) : null} */}
                        <iframe
                          name="gMap"
                          className="w-100"
                          style={{ height: "100%" }}
                          src={`https://www.google.com/maps/embed/v1/place?q=${responseGet2?.lattitude},${responseGet2?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                        ></iframe>
                      </div>
                      <ul>
                        <li>
                          <p className="d-inline-flex align-items-center mb-0">
                            <img
                              src={Images.LOCATION}
                              height="12px"
                              className="me-3"
                              alt=""
                            />
                            <span>Location</span>
                          </p>
                        </li>
                        {/* <li>{responseGet2?.locationName}</li> */}
                        <li className="fs-12 fw-normal">
                          <p className="mb-0">
                            <strong>
                              {responseGet2?.addressLine1 ==
                              responseGet2?.locationName
                                ? responseGet2?.locationName
                                : responseGet2?.locationName}
                              {/* {!responseGet2?.addressLine1
                            ? responseGet2?.locationName
                            : null} */}
                            </strong>
                          </p>
                          {!responseGet2?.addressLine2 ? null : (
                            <p className="mb-0">
                              {responseGet2?.addressLine2?.split(",")[0] +
                                ", " +
                                responseGet2?.addressLine2?.split(",")[1] +
                                " " +
                                responseGet2?.addressLine2?.split(",")[2]}
                              <span className="d-block">
                                {responseGet2?.addressLine2?.split(",")[3]}
                              </span>
                            </p>
                          )}
                        </li>
                      </ul>
                      <div className="head-time d-flex justify-content-between align-items-center">
                        <p className="d-inline-flex align-items-center mb-0">
                          <img
                            src={Images.TIME}
                            className="me-2"
                            height="13px"
                            alt="companions"
                          />{" "}
                          Date Start
                        </p>
                        <div className="battery-btn d-inline-flex align-items-center px-2">
                          <BatteryGauge
                            className="me-2"
                            value={responseGet2?.batteryPercentage}
                            size={24}
                            customization={CustomBattery}
                          />
                          {responseGet2?.batteryPercentage}%
                        </div>
                      </div>

                      <p className="fs-12 mb-0 fw-normal">
                        {moment
                          .utc(
                            responseGet2?.datePostStartDate?.slice(0, 10) +
                              "T" +
                              responseGet2?.datePostStartTime
                          )
                          .local()

                          .format("MMMM DD, YYYY | h:mm A")}{" "}
                        {/* {moment(responseGet2?.datePostStartTime, "HH:mm:ss").format(
                      "h:mm A"
                    )} */}
                      </p>

                      {/* <p className="d-inline-flex align-items-center mb-0 fs-12 fw-normal ">
                    <img
                      src={Images.LOCATION}
                      height="12px"
                      className="me-3"
                      alt=""
                    />
                    <span className="lightgrey me-1 fe-semibold">
                      <strong>Location: </strong>
                    </span>
                  </p> */}
                      {/* <p className="fs-12 mb-0 fw-normal">
                    <strong>
                      {responseGet2?.addressLine1 == responseGet2?.locationName
                        ? responseGet2?.locationName
                        : null}
                      {!responseGet2?.addressLine1
                        ? responseGet2?.locationName
                        : null}
                    </strong>
                    <br />
                    {responseGet2?.addressLine2?.split(",")[0] +
                      ", " +
                      responseGet2?.addressLine2?.split(",")[1] +
                      " " +
                      responseGet2?.addressLine2?.split(",")[2] +
                      ", " +
                      responseGet2?.addressLine2?.split(",")[3]}
                  </p> */}
                      {responseGet2?.dateChildPost == null ? null : (
                        <>
                          <div className="head-time d-flex justify-content-between align-items-center mt-3">
                            <p className="d-inline-flex align-items-center mb-0">
                              <img
                                src={Images.TIME}
                                className="me-2"
                                height="13px"
                                alt="companions"
                              />{" "}
                              Date End
                            </p>
                            <div className="battery-btn d-inline-flex align-items-center px-2">
                              <BatteryGauge
                                className="me-2"
                                value={
                                  responseGet2?.dateChildPost?.batteryPercentage
                                }
                                size={24}
                                customization={CustomBattery}
                              />
                              {responseGet2?.dateChildPost?.batteryPercentage}%
                            </div>
                          </div>

                          <p className="fs-12 mb-1 fw-normal">
                            {moment
                              .utc(
                                responseGet2?.dateChildPost?.datePostEndDate?.slice(
                                  0,
                                  10
                                ) +
                                  "T" +
                                  responseGet2?.dateChildPost?.datePostEndTime
                              )
                              .local()

                              .format("MMMM DD, YYYY | h:mm A")}
                          </p>

                          {/* <p className="fs-12 mb-0 fw-normal">
                        <strong>
                        
                          {responseGet2?.locationName}
                        </strong>
                        <br />
                        {responseGet2?.addressLine2?.split(",")[0] +
                          ", " +
                          responseGet2?.addressLine2?.split(",")[1] +
                          " " +
                          responseGet2?.addressLine2?.split(",")[2] +
                          ", " +
                          responseGet2?.addressLine2?.split(",")[3]}
                      </p> */}
                        </>
                      )}

                      <p className="d-flex align-items-center mt-3 mb-0">
                        <i className="icon-heart text-heart me-2"></i>
                        <span>Dating Services</span>
                      </p>
                      <p className="fs-12 mb-0 fw-normal">
                        {!responseGet2?.datingService
                          ? "NA"
                          : responseGet2?.datingService}
                      </p>

                      {/* {responseGet2?.companions ? (
                    <>
                      <h6 className="fw-semibold fs-12 d-inline-flex align-items-center mb-3">
                        <img
                          src={Images.COMPANIONS}
                          className="me-2"
                          alt="companions"
                        />{" "}
                        Companions
                      </h6>

                      <ul className="user-wrapper d-flex flex-wrap mb-0">
                        <li className="user me-3">
                          <div className="img-box">
                            <img
                              src={Images.PROFILE}
                              className="user-img"
                              alt="profile"
                            />
                          </div>
                          <p className="text-center mb-2">Amelia</p>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <p>None</p>
                  )} */}
                    </div>
                    <div className="share-profile mb-4">
                      {responseGet2?.sharingWith?.length > 0 ? (
                        <>
                          <p className="d-inline-flex align-items-center mb-3">
                            <img
                              src={Images.SHARE}
                              className="me-2"
                              height="14px"
                              alt=""
                            />
                            <span>You're sharing with</span>
                          </p>

                          <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                            {responseGet2?.sharingWith.map((item, index) => {
                              return (
                                <>
                                  {!item?.userProfilePic ? (
                                    <li className="user me-3" key={index}>
                                      <span className="text-word">
                                        {item?.userFriendName?.substring(0, 1)}
                                      </span>
                                      {/* </div> */}
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  ) : (
                                    <li className="user me-3">
                                      <div className="img-box">
                                        <img
                                          src={item.url}
                                          className="user-img"
                                          alt="profile"
                                        />
                                      </div>
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>

                    <>
                      <p className="d-inline-flex align-items-center mb-0">
                        <img
                          src={Images.CARD}
                          className="me-2"
                          height="13px"
                          alt="companions"
                        />
                        <span>License plate number</span>
                      </p>
                      <p className="fs-12 fw-normal mb-2">
                        {!responseGet2?.licensePlateNo &&
                        responseGet2?.postMedia?.length == 0
                          ? "NA"
                          : responseGet2?.licensePlateNo}
                      </p>

                      {!responseGet2?.postMedia ||
                      responseGet2?.postMedia.length == 0 ? null : (
                        <div className="car-img mb-3">
                          <img src={responseGet2?.postMedia[0]?.url} alt="" />
                        </div>
                      )}
                    </>
                  </div>
                ) : null}

                {/* note*/}
                {postType === 4 ? (
                  <div className="car-following">
                    <p className="fs-12">{responseGet2?.description}</p>
                    {/* <div className="car-img mb-3">
                  <img
                    src="https://cdn.pixabay.com/photo/2016/11/22/23/44/porsche-1851246__340.jpg"
                    alt=""
                  />
                </div> */}
                    <ul>
                      {/* <li>
                    <p className="mb-0 d-inline-flex align-items-center">
                      <img
                        src={Images.DATE}
                        className="me-2"
                        height="13px"
                        alt="companions"
                      />
                      Date
                    </p>
                    <p className="mb-0 fs-12 fw-normal">
                      {moment
                        .utc(responseGet2?.createdAt)
                        .local()
                        .format("MMMM D, YYYY")}{" "}
                      |{" "}
                      {moment
                        .utc(responseGet2?.createdAt)
                        .local()
                        .format("h:mm A")}
                    </p>
                  </li> */}
                      {/* <li>
                    <h6 className="fw-semibold fs-12 d-inline-flex align-items-center">
                      <img
                        src={Images.TIME}
                        className="me-2"
                        height="13px"
                        alt="companions"
                      />
                      Time
                    </h6>
                    <p className="fs-12 fw-normal">
                      {moment
                        .utc(responseGet2?.createdAt)
                        .local()
                        .format("hh:mm a")}
                    </p>
                  </li> */}
                    </ul>
                    {vidObjectUrl?.length > 0
                      ? vidObjectUrl?.map((vid) => (
                          <>
                            {vid?.fileType !== "images" ? (
                              <>
                                <video width="300" height="100" controls>
                                  <source src={vid?.url} type="video/mp4" />
                                </video>
                                <p className="mb-0 ps-3">
                                  <span className="me-1">
                                    {/* {vid?.fileSize} */}
                                    {Math.round(
                                      parseFloat(vid?.fileSize) > 1024
                                        ? parseFloat(vid?.fileSize) / 1024
                                        : parseFloat(vid?.fileSize)
                                    )}{" "}
                                    {parseFloat(vid?.fileSize) > 1024
                                      ? "Mb"
                                      : "Kb"}{" "}
                                  </span>
                                  <span>{vid?.duration}</span>
                                </p>
                              </>
                            ) : (
                              <div className="car-img mb-4">
                                <img src={vid?.url} alt="" />
                              </div>
                            )}
                          </>
                        ))
                      : null}
                    <ul>
                      <li>
                        <p className="d-inline-flex align-items-center mb-0">
                          <img
                            src={Images.LOCATION}
                            height="12px"
                            className="me-3"
                            alt=""
                          />
                          <span>Location</span>
                        </p>
                      </li>
                      {/* <li>{responseGet2?.locationName}</li> */}
                      <li className="fs-12 fw-normal">
                        <p className="mb-0">
                          <strong>
                            {responseGet2?.addressLine1 ==
                            responseGet2?.locationName
                              ? responseGet2?.locationName
                              : null}
                            {!responseGet2?.addressLine1
                              ? responseGet2?.locationName
                              : responseGet2?.locationName}
                            {/* {responseGet2?.locationName
                          ? responseGet2?.locationName
                          : null} */}
                          </strong>
                        </p>
                        <p className="mb-0">
                          <span className="d-block">
                            {!responseGet2?.addressLine1
                              ? null
                              : responseGet2?.addressLine1}
                          </span>
                          {!responseGet2?.addressLine2 ? null : (
                            <>
                              {responseGet2?.addressLine2?.split(",")[0] +
                                ", " +
                                responseGet2?.addressLine2?.split(",")[1] +
                                " " +
                                responseGet2?.addressLine2?.split(",")[2]}
                              <span className="d-block">
                                {responseGet2?.addressLine2?.split(",")[3]}
                              </span>
                            </>
                          )}
                        </p>
                      </li>
                    </ul>
                    <div className="map-box mb-4">
                      {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2515839006487!2d77.36386661545025!3d28.622220991297475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff430a9852f%3A0x11b0f6c89f81224e!2sTechAhead!5e0!3m2!1sen!2sin!4v1667380582022!5m2!1sen!2sin"
                    title="maps"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe> */}
                      {/* {isLoaded ? (
                    <GoogleMap
                      center={{
                        lat: responseGet2?.lattitude,
                        lng: responseGet2?.longitude,
                      }}
                      zoom={10}
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                    >
                      <MarkerF
                        position={{
                          lat: responseGet2?.lattitude,
                          lng: responseGet2?.longitude,
                        }}
                      />
                    </GoogleMap>
                  ) : null} */}
                      <iframe
                        name="gMap"
                        className="w-100"
                        style={{ height: "100%" }}
                        src={`https://www.google.com/maps/embed/v1/place?q=${responseGet2?.lattitude},${responseGet2?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                      ></iframe>
                    </div>
                    <div className="share-profile mb-4">
                      {responseGet2?.sharingWith?.length > 0 ? (
                        <>
                          <p className="d-inline-flex align-items-center mb-3">
                            <img
                              src={Images.SHARE}
                              className="me-2"
                              height="14px"
                              alt=""
                            />
                            <span>You're sharing with</span>
                          </p>

                          <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                            {responseGet2?.sharingWith.map((item, index) => {
                              return (
                                <>
                                  {!item?.userProfilePic ? (
                                    <li className="user me-3" key={index}>
                                      <span className="text-word">
                                        {item?.userFriendName?.substring(0, 1)}
                                      </span>
                                      {/* </div> */}
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  ) : (
                                    <li className="user me-3">
                                      <div className="img-box">
                                        <img
                                          src={item.url}
                                          className="user-img"
                                          alt="profile"
                                        />
                                      </div>
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>
                    <div className="share-profile mb-4">
                      {responseGet2?.companions?.length > 0 ? (
                        <>
                          <p className="d-inline-flex align-items-center mb-3">
                            <i className="icon-people me-2 fs-18 text-danger"></i>
                            <span>Companions</span>
                          </p>

                          <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                            {responseGet2?.companions.map((item, index) => {
                              return (
                                <>
                                  <li className="user me-3" key={index}>
                                    <span className="text-word">
                                      {item?.name?.substring(0, 1)}
                                    </span>
                                    {/* </div> */}
                                    <p className="text-center mb-2">
                                      {item.name}
                                    </p>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {/* location check in */}
                {postType === 20 ? (
                  <div className="birthday-party mb-4">
                    <p className="fs-12 mb-2">{responseGet2?.description}</p>
                    <div className="map-box mb-4">
                      {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2515839006487!2d77.36386661545025!3d28.622220991297475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff430a9852f%3A0x11b0f6c89f81224e!2sTechAhead!5e0!3m2!1sen!2sin!4v1667380582022!5m2!1sen!2sin"
                    title="maps"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe> */}
                      {/* {isLoaded ? (
                    <GoogleMap
                      center={{
                        lat: responseGet2?.lattitude,
                        lng: responseGet2?.longitude,
                      }}
                      zoom={10}
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                    >
                      <MarkerF
                        position={{
                          lat: responseGet2?.lattitude,
                          lng: responseGet2?.longitude,
                        }}
                      />
                    </GoogleMap>
                  ) : null} */}
                      <iframe
                        name="gMap"
                        className="w-100"
                        style={{ height: "100%" }}
                        src={`https://www.google.com/maps/embed/v1/place?q=${responseGet2?.lattitude},${responseGet2?.longitude}&key=AIzaSyCAgGrJgo_Z85DivzP2QnKchBw9xasePdg`}
                      ></iframe>
                    </div>
                    <p className="d-inline-flex align-items-center mb-0">
                      <img
                        src={Images.LOCATION}
                        height="12px"
                        className="me-3"
                        alt=""
                      />
                      <span>Location</span>
                    </p>

                    <p className="fs-12 mb-3  fw-normal">
                      {/* <strong>{responseGet2?.b}</strong> */}
                      <span className="fw-semibold">
                        {responseGet2?.businessName}
                      </span>
                      <br />
                      {!responseGet2?.addressLine1 ? null : (
                        <>
                          {responseGet2?.addressLine1}
                          <br />
                        </>
                      )}{" "}
                      {/* {!responseGet2?.addressLine1 ? null : ( */}
                      <>
                        {responseGet2?.addressLine2?.split(",")[0] +
                          ", " +
                          responseGet2?.addressLine2?.split(",")[1] +
                          " " +
                          responseGet2?.addressLine2?.split(",")[2]}
                        <span className="d-block">
                          {responseGet2?.addressLine2?.split(",")[3]}
                        </span>
                      </>
                      {/* )} */}
                    </p>
                    <div className="share-profile mb-4">
                      {responseGet2?.sharingWith?.length > 0 ? (
                        <>
                          <p className="d-inline-flex align-items-center mb-3">
                            <img
                              src={Images.SHARE}
                              className="me-2"
                              height="14px"
                              alt=""
                            />
                            <span>You're sharing with</span>
                          </p>

                          <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                            {responseGet2?.sharingWith.map((item, index) => {
                              return (
                                <>
                                  {!item?.userProfilePic ? (
                                    <li className="user me-3" key={index}>
                                      <span className="text-word">
                                        {item?.userFriendName?.substring(0, 1)}
                                      </span>
                                      {/* </div> */}
                                      <p className="text-center mb-2 fw-normal">
                                        {item?.userFriendName}
                                      </p>
                                    </li>
                                  ) : (
                                    <li className="user me-3">
                                      <div className="img-box">
                                        <img
                                          src={item?.url}
                                          className="user-img"
                                          alt="profile"
                                        />
                                      </div>
                                      <p className="text-center mb-2 fw-normal">
                                        {item?.userFriendName}
                                      </p>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {postType === 1 ? (
                  <div className="birthday-party mb-4">
                    <p className="fs-12 mb-2">{responseGet2?.description}</p>
                    <div className="map-box mb-4">
                      {/* <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2515839006487!2d77.36386661545025!3d28.622220991297475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff430a9852f%3A0x11b0f6c89f81224e!2sTechAhead!5e0!3m2!1sen!2sin!4v1667380582022!5m2!1sen!2sin"
                    title="maps"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe> */}
                      {/* {isLoaded ? (
                    <GoogleMap
                      center={{
                        lat: responseGet2?.lattitude,
                        lng: responseGet2?.longitude,
                      }}
                      zoom={10}
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                    >
                      <MarkerF
                        position={{
                          lat: responseGet2?.lattitude,
                          lng: responseGet2?.longitude,
                        }}
                      />
                    </GoogleMap>
                  ) : null} */}
                      <iframe
                        name="gMap"
                        className="w-100"
                        style={{ height: "100%" }}
                        src={`https://www.google.com/maps/embed/v1/place?q=${responseGet2?.lattitude},${responseGet2?.longitude}&key=AIzaSyCAgGrJgo_Z85DivzP2QnKchBw9xasePdg`}
                      ></iframe>
                    </div>
                    <p className="d-inline-flex align-items-center mb-0 fw-normal">
                      <img
                        src={Images.LOCATION}
                        height="12px"
                        className="me-3"
                        alt=""
                      />
                      <span className="fw-semibold">Location</span>
                    </p>

                    <p className="fs-12 mb-3 fw-normal">
                      {/* <strong>{responseGet2?.b}</strong> */}
                      <span className="fw-semibold">
                        <strong>{responseGet2?.locationName}</strong>
                      </span>
                      <br />
                      {/* {responseGet2?.addressLine1},{" "} */}
                      {!responseGet2?.addressLine1 ? null : (
                        <span className="d-block">
                          {responseGet2?.addressLine1}
                        </span>
                      )}
                      {!responseGet2?.addressLine2 ? null : (
                        <>
                          {" "}
                          {responseGet2?.addressLine2?.split(",")[0] +
                            ", " +
                            responseGet2?.addressLine2?.split(",")[1] +
                            " " +
                            responseGet2?.addressLine2?.split(",")[2]}
                          <span className="d-block">
                            {responseGet2?.addressLine2?.split(",")[3]}
                          </span>
                        </>
                      )}
                    </p>
                    <div className="share-profile mb-4">
                      {responseGet2?.companions?.length > 0 ? (
                        <>
                          <p className="d-inline-flex align-items-center mb-3">
                            <i className="icon-people me-2 fs-18 text-danger"></i>
                            <span>Companions</span>
                          </p>

                          <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                            {responseGet2?.companions.map((item, index) => {
                              return (
                                <>
                                  <li className="user me-3" key={index}>
                                    <span className="text-word">
                                      {item?.name?.substring(0, 1)}
                                    </span>
                                    {/* </div> */}
                                    <p className="text-center mb-2">
                                      {item.name}
                                    </p>
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>
                    <div className="share-profile mb-4">
                      {responseGet2?.sharingWith?.length > 0 ? (
                        <>
                          <p className="d-inline-flex align-items-center mb-3">
                            <img
                              src={Images.SHARE}
                              className="me-2"
                              height="14px"
                              alt=""
                            />
                            <span>You're sharing with</span>
                          </p>

                          <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                            {responseGet2?.sharingWith.map((item, index) => {
                              return (
                                <>
                                  {!item?.userProfilePic ? (
                                    <li className="user me-3" key={index}>
                                      <span className="text-word">
                                        {item?.userFriendName?.substring(0, 1)}
                                      </span>
                                      {/* </div> */}
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  ) : (
                                    <li className="user me-3">
                                      <div className="img-box">
                                        <img
                                          src={item.url}
                                          className="user-img"
                                          alt="profile"
                                        />
                                      </div>
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {/* safety check */}
                {postType === 5 || postType === 21 ? (
                  <div className="safety-check mb-4">
                    {postType === 5 ? (
                      <p className="fs-12 mb-2">
                        {responseGet2?.firstName} Safety check-in completed{" "}
                      </p>
                    ) : null}

                    <div className="map-box mb-4">
                      {/* {isLoaded ? (
                    <GoogleMap
                      center={{
                        lat: responseGet2?.lattitude,
                        lng: responseGet2?.longitude,
                      }}
                      zoom={10}
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                    >
                      <MarkerF
                        position={{
                          lat: responseGet2?.lattitude,
                          lng: responseGet2?.longitude,
                        }}
                      />
                    </GoogleMap>
                  ) : null} */}
                      <iframe
                        name="gMap"
                        className="w-100"
                        style={{ height: "100%" }}
                        src={`https://www.google.com/maps/embed/v1/place?q=${responseGet2?.lattitude},${responseGet2?.longitude}&key=AIzaSyCAgGrJgo_Z85DivzP2QnKchBw9xasePdg`}
                      ></iframe>
                    </div>
                    <p className="d-inline-flex align-items-center mb-0 fw-normal">
                      <img
                        src={Images.LOCATION}
                        height="12px"
                        className="me-3"
                        alt=""
                      />
                      <span className="fw-semibold">Location</span>
                    </p>
                    {postType === 5 ? (
                      <p className="fs-12 mb-3 fw-normal ms-2">
                        {/* <strong>{responseGet2?.b}</strong> */}
                        <span className="lightgrey fw-semibold d-block">
                          {responseGet2?.businessName == "0" ||
                          !responseGet2?.businessName
                            ? responseGet2?.locationName
                            : responseGet2?.businessName}
                        </span>
                        {responseGet2?.addressLine1}
                        {!responseGet2?.addressLine2 ? null : (
                          <>
                            {responseGet2?.addressLine2?.split(",")[0] +
                              ", " +
                              responseGet2?.addressLine2?.split(",")[1] +
                              " " +
                              responseGet2?.addressLine2?.split(",")[2]}
                            <span className="d-block">
                              {responseGet2?.addressLine2?.split(",")[3]}
                            </span>
                          </>
                        )}
                      </p>
                    ) : null}
                    {postType === 21 ? (
                      <p className="fs-12 mb-3 fw-normal">
                        {/* <strong>
                          {geoAddress?.split(",")[0] +
                            " " +
                            geoAddress?.split(",")[1]}
                        </strong>{" "}
                        <br /> */}
                        {/* <span className="lightgrey me-1 fe-semibold">
                    <strong>{responseGet2?.businessName}</strong>
                  </span> */}

                        {/* <span className="d-block">{geoAddress?.split(",")[3]}, {geoAddress?.split(",")[5]}</span>
                          
                        <span className="d-block">
                        {geoAddress?.split(",")[6]}
                        </span> */}
                        <span className="lightgrey fw-semibold d-block">
                          {responseGet2?.businessName == "0" ||
                          !responseGet2?.businessName
                            ? responseGet2?.locationName
                            : responseGet2?.businessName}
                        </span>
                        {responseGet2?.addressLine1}
                        {!responseGet2?.addressLine2 ? null : (
                          <>
                            {" "}
                            {responseGet2?.addressLine2?.split(",")[0] +
                              ", " +
                              responseGet2?.addressLine2?.split(",")[1] +
                              " " +
                              responseGet2?.addressLine2?.split(",")[2]}
                            <span className="d-block">
                              {responseGet2?.addressLine2?.split(",")[3]}
                            </span>
                          </>
                        )}
                      </p>
                    ) : null}
                    <div className="share-profile mb-4">
                      {responseGet2?.sharingWith?.length > 0 ? (
                        <>
                          <p className="d-inline-flex align-items-center mb-3">
                            <img
                              src={Images.SHARE}
                              className="me-2"
                              height="14px"
                              alt=""
                            />
                            <span>You're sharing with</span>
                          </p>

                          <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                            {responseGet2?.sharingWith.map((item, index) => {
                              return (
                                <>
                                  {!item?.userProfilePic ? (
                                    <li className="user me-3" key={index}>
                                      <span className="text-word">
                                        {item?.userFriendName?.substring(0, 1)}
                                      </span>
                                      {/* </div> */}
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  ) : (
                                    <li className="user me-3">
                                      <div className="img-box">
                                        <img
                                          src={item.url}
                                          className="user-img"
                                          alt="profile"
                                        />
                                      </div>
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>
                  </div>
                ) : null}
                {/* {postType === 2 ? (
              <div className="safety-check mb-4">
                
                <p className="d-inline-flex align-items-center mb-0 fs-12 fw-normal ps-3">
                  <img
                    src={Images.LOCATION}
                    height="12px"
                    className="me-3"
                    alt=""
                  />
                  <span className="lightgrey me-1">Location: </span>24th ST,
                  Horizon Circle, Austin, TX
                </p>
              </div>
            ) : null} */}

                {/* sos */}
                {postType === 7 || postType === 8 ? (
                  <>
                    <div className="sos">
                      {/* <p className="fs-12 mb-2">
                    {responseGet2?.firstName} reported that she's safe
                  </p> */}

                      <div className="map-box mb-4">
                        {/* <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2515839006487!2d77.36386661545025!3d28.622220991297475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff430a9852f%3A0x11b0f6c89f81224e!2sTechAhead!5e0!3m2!1sen!2sin!4v1667380582022!5m2!1sen!2sin"
                      title="maps"
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe> */}
                        {/* {isLoaded ? (
                      <GoogleMap
                        center={{
                          lat: responseGet2?.lattitude,
                          lng: responseGet2?.longitude,
                        }}
                        zoom={10}
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                      >
                        <MarkerF
                          position={{
                            lat: responseGet2?.lattitude,
                            lng: responseGet2?.longitude,
                          }}
                        />
                      </GoogleMap>
                    ) : null} */}
                        <iframe
                          name="gMap"
                          className="w-100"
                          style={{ height: "100%" }}
                          src={`https://www.google.com/maps/embed/v1/place?q=${responseGet2?.lattitude},${responseGet2?.longitude}&key=AIzaSyCAgGrJgo_Z85DivzP2QnKchBw9xasePdg`}
                        ></iframe>
                      </div>
                      {postType == 8 ? (
                        <>
                          <div className="head-time d-flex justify-content-between align-items-center">
                            <p className="d-inline-flex align-items-center mb-0">
                              <img
                                src={Images.TIME}
                                className="me-2"
                                height="13px"
                                alt="companions"
                              />{" "}
                              {moment
                                .utc(responseGet2?.createdAt)
                                .local()
                                .format("h:mm A")}
                            </p>
                            <div className="battery-btn d-inline-flex align-items-center px-2">
                              <BatteryGauge
                                className="me-2"
                                value={responseGet2?.batteryPercentage}
                                size={24}
                                customization={CustomBattery}
                              />
                              {responseGet2?.batteryPercentage}%
                            </div>
                          </div>
                          <p className="mb-0">
                            Initiated <span className="text-danger">SOS</span>
                          </p>
                          {/* <p className="fs-12 mb-0 fw-normal">
                        {moment
                          .utc(responseGet2?.createdAt)
                          .local()
                          .format("MMMM D, YYYY")}
                      </p> */}
                          <p className=" mb-4 fs-12 fw-normal">
                            <img
                              src={Images.LOCATION}
                              height="12px"
                              className="me-2"
                              alt=""
                            />
                            <span className="lightgrey">
                              <strong>
                                {responseGet2?.addressLine1 ==
                                responseGet2?.locationName
                                  ? responseGet2?.locationName
                                  : null}
                                {!responseGet2?.addressLine1
                                  ? responseGet2?.locationName
                                  : responseGet2?.locationName}
                              </strong>
                            </span>
                            <br />
                            {!responseGet2?.addressLine2 ? null : (
                              <>
                                <span className="d-block ms-4">
                                  {responseGet2?.addressLine2?.split(",")[0] +
                                    ", " +
                                    responseGet2?.addressLine2?.split(",")[1] +
                                    " " +
                                    responseGet2?.addressLine2?.split(",")[2]}
                                </span>{" "}
                                <span className="d-block ms-4">
                                  {responseGet2?.addressLine2?.split(",")[3]}
                                </span>
                              </>
                            )}
                          </p>
                        </>
                      ) : null}
                      {responseGet2?.isSOSCancelled == true && postType != 8 ? (
                        <>
                          <div className="head-time d-flex justify-content-between align-items-center">
                            <p className="d-inline-flex align-items-center mb-0">
                              <img
                                src={Images.TIME}
                                className="me-2"
                                height="13px"
                                alt="companions"
                              />{" "}
                              {moment
                                .utc(responseGet2?.createdAt)
                                .local()
                                .format("h:mm A")}
                            </p>
                            <div className="battery-btn d-inline-flex align-items-center px-2">
                              <BatteryGauge
                                className="me-2"
                                value={responseGet2?.batteryPercentage}
                                size={24}
                                customization={CustomBattery}
                              />
                              {responseGet2?.batteryPercentage}%
                            </div>
                          </div>
                          <p className="mb-0">
                            Cancelled <span className="text-danger">SOS</span>
                          </p>
                          {/* <p className="fs-12 mb-0 fw-normal">
                        {moment
                          .utc(responseGet2?.createdAt)
                          .local()
                          .format("MMMM D, YYYY")}
                      </p> */}
                          <p className="mb-4 fs-12 fw-normal">
                            <img
                              src={Images.LOCATION}
                              height="12px"
                              className="me-2"
                              alt=""
                            />
                            <span className="lightgrey ">
                              <strong>
                                {responseGet2?.addressLine1 ==
                                responseGet2?.locationName
                                  ? responseGet2?.locationName
                                  : null}
                                {!responseGet2?.addressLine1
                                  ? responseGet2?.locationName
                                  : null}
                              </strong>
                            </span>
                            <br />
                            {!responseGet2?.addressLine2 ? null : (
                              <>
                                <span className="d-block ms-4">
                                  {responseGet2?.addressLine2?.split(",")[0] +
                                    ", " +
                                    responseGet2?.addressLine2?.split(",")[1] +
                                    " " +
                                    responseGet2?.addressLine2?.split(",")[2]}
                                </span>
                                <span className="d-block ms-4">
                                  {responseGet2?.addressLine2?.split(",")[3]}
                                </span>
                              </>
                            )}
                          </p>
                        </>
                      ) : (
                        <>
                          {responseGet2?.sosChildPost == null &&
                          postType != 8 ? (
                            <>
                              <div className="head-time d-flex justify-content-between align-items-center">
                                <p className="d-inline-flex align-items-center mb-0">
                                  <img
                                    src={Images.TIME}
                                    className="me-2"
                                    height="13px"
                                    alt="companions"
                                  />{" "}
                                  {moment
                                    .utc(responseGet2?.createdAt)
                                    .local()
                                    .format("h:mm A")}
                                </p>
                                <div className="battery-btn d-inline-flex align-items-center px-2">
                                  <BatteryGauge
                                    className="me-2"
                                    value={responseGet2?.batteryPercentage}
                                    size={24}
                                    customization={CustomBattery}
                                  />
                                  {responseGet2?.batteryPercentage}%
                                </div>
                              </div>
                              <p className="mb-0">
                                Activated{" "}
                                <span className="text-danger">SOS</span>
                              </p>
                              {/* <p className="fs-12 mb-0 fw-normal">
                            {moment
                              .utc(responseGet2?.createdAt)
                              .local()
                              .format("MMMM D, YYYY")}
                          </p> */}
                              <p className="mb-0 fs-12 mb-4 fw-normal">
                                {/* <img
                                src={Images.LOCATION}
                                height="12px"
                                className="me-3"
                                alt=""
                              /> */}
                                <span className="lightgrey">
                                  <strong>
                                    {responseGet2?.addressLine1 ==
                                    responseGet2?.locationName
                                      ? responseGet2?.locationName
                                      : responseGet2?.addressLine1}

                                    {!responseGet2?.addressLine1
                                      ? responseGet2?.locationName
                                      : null}
                                  </strong>
                                </span>
                                <br />
                                {!responseGet2?.addressLine2 ? null : (
                                  <>
                                    <span className="d-block lightgrey">
                                      {responseGet2?.addressLine2?.split(
                                        ","
                                      )[0] +
                                        ", " +
                                        responseGet2?.addressLine2?.split(
                                          ","
                                        )[1] +
                                        " " +
                                        responseGet2?.addressLine2?.split(
                                          ","
                                        )[2]}
                                    </span>{" "}
                                    <span className="d-block">
                                      {
                                        responseGet2?.addressLine2?.split(
                                          ","
                                        )[3]
                                      }
                                    </span>
                                  </>
                                )}
                              </p>
                            </>
                          ) : (
                            <>
                              {postType != 8 ? (
                                <>
                                  <div className="head-time d-flex justify-content-between align-items-center">
                                    <p className="d-inline-flex align-items-center mb-0">
                                      <img
                                        src={Images.TIME}
                                        className="me-2"
                                        height="13px"
                                        alt="companions"
                                      />{" "}
                                      {moment
                                        .utc(responseGet2?.createdAt)
                                        .local()
                                        .format("h:mm A")}
                                    </p>
                                    <div className="battery-btn d-inline-flex align-items-center px-2">
                                      <BatteryGauge
                                        className="me-2"
                                        value={responseGet2?.batteryPercentage}
                                        size={24}
                                        customization={CustomBattery}
                                      />
                                      {responseGet2?.batteryPercentage}%
                                    </div>
                                  </div>
                                  <p className="mb-0">
                                    Activatedss{" "}
                                    <span className="text-danger">SOS</span>
                                  </p>
                                  {/* <p className="fs-12 mb-0 fw-normal">
                                {moment
                                  .utc(responseGet2?.createdAt)
                                  .local()
                                  .format("MMMM D, YYYY")}
                              </p> */}
                                  <p className="mb-0 fs-12 mb-4 fw-normal">
                                    {/* <img
                                src={Images.LOCATION}
                                height="12px"
                                className="me-3"
                                alt=""
                              /> */}
                                    {responseGet2?.addressLine1 !==
                                    responseGet2?.locationName ? (
                                      <>
                                        <span className="lightgrey">
                                          <strong>
                                            {responseGet2?.locationName}
                                          </strong>
                                        </span>
                                        <br />
                                      </>
                                    ) : null}
                                    <span className="lightgrey">
                                      <strong>
                                        {responseGet2?.addressLine1 ==
                                        responseGet2?.locationName
                                          ? responseGet2?.locationName
                                          : responseGet2?.addressLine1}

                                        {!responseGet2?.addressLine1
                                          ? responseGet2?.locationName
                                          : null}
                                      </strong>
                                    </span>
                                    <br />
                                    {!responseGet2?.addressLine2 ? null : (
                                      <>
                                        <span className="d-block lightgrey">
                                          {responseGet2?.addressLine2?.split(
                                            ","
                                          )[0] +
                                            ", " +
                                            responseGet2?.addressLine2?.split(
                                              ","
                                            )[1] +
                                            " " +
                                            responseGet2?.addressLine2?.split(
                                              ","
                                            )[2]}
                                        </span>{" "}
                                        <span className="d-block">
                                          {
                                            responseGet2?.addressLine2?.split(
                                              ","
                                            )[3]
                                          }
                                        </span>
                                      </>
                                    )}
                                  </p>
                                  <div className="head-time d-flex justify-content-between align-items-center">
                                    <p className="d-inline-flex align-items-center mb-0">
                                      <img
                                        src={Images.TIME}
                                        className="me-2"
                                        height="13px"
                                        alt="companions"
                                      />{" "}
                                      {moment
                                        .utc(
                                          responseGet2?.sosChildPost?.createdAt
                                        )
                                        .local()
                                        .format("h:mm A")}
                                    </p>
                                    <div className="battery-btn d-inline-flex align-items-center px-2">
                                      <BatteryGauge
                                        className="me-2"
                                        value={
                                          responseGet2?.sosChildPost
                                            ?.batteryPercentage
                                        }
                                        size={24}
                                        customization={CustomBattery}
                                      />
                                      {
                                        responseGet2?.sosChildPost
                                          ?.batteryPercentage
                                      }
                                      %
                                    </div>
                                  </div>
                                  <p className="mb-0">
                                    Deactivated{" "}
                                    <span className="text-danger">SOS</span>
                                  </p>
                                  <p className="fs-12 mb-0 fw-normal">
                                    {moment
                                      .utc(
                                        responseGet2?.sosChildPost?.createdAt
                                      )
                                      .local()
                                      .format("MMMM D, YYYY")}
                                  </p>
                                  <p className="mb-4 fs-12 fw-normal">
                                    {/* <img
                                src={Images.LOCATION}
                                height="12px"
                                className="me-3"
                                alt=""
                              /> */}
                                    {responseGet2?.sosChildPost
                                      ?.addressLine1 !==
                                    responseGet2?.sosChildPost?.locationName ? (
                                      <>
                                        <span className="lightgrey">
                                          <strong>
                                            {
                                              responseGet2?.sosChildPost
                                                ?.locationName
                                            }
                                          </strong>
                                        </span>
                                        <br />
                                      </>
                                    ) : null}

                                    <span className="lightgrey">
                                      <strong>
                                        {responseGet2?.sosChildPost
                                          ?.addressLine1 ==
                                        responseGet2?.sosChildPost?.locationName
                                          ? responseGet2?.sosChildPost
                                              ?.locationName
                                          : responseGet2?.sosChildPost
                                              ?.addressLine1}
                                        {!responseGet2?.sosChildPost
                                          ?.addressLine1
                                          ? responseGet2?.sosChildPost
                                              ?.locationName
                                          : null}
                                      </strong>
                                    </span>
                                    <br />
                                    {!responseGet2?.sosChildPost
                                      ?.addressLine2 ? null : (
                                      <>
                                        <span className="lightgrey d-block">
                                          {responseGet2?.sosChildPost?.addressLine2?.split(
                                            ","
                                          )[0] +
                                            ", " +
                                            responseGet2?.sosChildPost?.addressLine2?.split(
                                              ","
                                            )[1] +
                                            " " +
                                            responseGet2?.sosChildPost?.addressLine2?.split(
                                              ","
                                            )[2]}
                                        </span>{" "}
                                        <span className="d-block">
                                          {
                                            responseGet2?.sosChildPost?.addressLine2?.split(
                                              ","
                                            )[3]
                                          }
                                        </span>
                                      </>
                                    )}
                                  </p>
                                </>
                              ) : null}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    {/* <ul className="img-collect">
                  {responseGet2?.sharingWith.map((item, index) => {
                    return (
                      // <li className="user me-3">
                      //   <div className="img-box">
                      //     <img
                      //       src={item.url}
                      //       className="user-img"
                      //       alt="profile"
                      //     />
                      //   </div>
                      //   <p className="text-center mb-2">
                      //     {item.userFriendName} {item.userFriendLName}
                      //   </p>
                      // </li>
                      
                    );
                  })}
                </ul> */}
                    <div className="share-profile mb-4">
                      {responseGet2?.sharingWith?.length > 0 ? (
                        <>
                          <p className="d-inline-flex align-items-center mb-3">
                            <img
                              src={Images.SHARE}
                              className="me-2"
                              height="14px"
                              alt=""
                            />
                            <span>You're sharing with</span>
                          </p>

                          <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                            {responseGet2?.sharingWith.map((item, index) => {
                              return (
                                <>
                                  {!item?.userProfilePic ? (
                                    <li className="user me-3" key={index}>
                                      <span className="text-word">
                                        {item?.userFriendName?.substring(0, 1)}
                                      </span>
                                      {/* </div> */}
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  ) : (
                                    <li className="user me-3">
                                      <div className="img-box">
                                        <img
                                          src={item.url}
                                          className="user-img"
                                          alt="profile"
                                        />
                                      </div>
                                      <p className="text-center mb-2 fw-normal">
                                        {item.userFriendName}
                                      </p>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                        </>
                      ) : null}
                    </div>
                    {responseGet2?.isSOSCancelled == true ||
                    !responseGet2?.postMedia?.length ? null : (
                      <>
                        <p className="mb-2">Audio and Video Recordings</p>
                        <div className="">
                          <div className="play-status  fs-12 ">
                            {/* <img
                      src={Images.RCORDING}
                      height="55px"
                      className=""
                      alt="recording"
                    /> */}
                            {/* <ReactAudioPlayer
                      src={responseGet2?.postMedia?.url}
                      autoPlay
                      controls
                    /> */}

                            {vidObjectUrl?.length > 0
                              ? vidObjectUrl?.map((vid) => (
                                  <>
                                    <video width="300" height="100" controls>
                                      <source src={vid?.url} type="video/mp4" />
                                    </video>
                                    <p className="mb-0 ps-3">
                                      <span>{vid?.duration}</span>
                                    </p>
                                  </>
                                ))
                              : null}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : null}
                {/* recording */}

                {postType === 6 ? (
                  <div className="recording mb-4">
                    {/* video player */}
                    {responseGet2?.postMedia?.fileName == null ? null : (
                      <div className="mb-3">
                        <div className="text-center fs-12">
                          {/* <img
                      src={Images.RCORDING}
                      height="55px"
                      className=""
                      alt="recording"
                    /> */}
                          {/* <ReactAudioPlayer
                            src={responseGet2?.postMedia?.url}
                            controls={true}
                            onSeeked={handleSeek}
                          /> */}
                          {objectUrl && (
                            <ReactAudioPlayer
                              src={objectUrl}
                              type="audio/mpeg"
                              controls={true}
                              playsinline={true}
                            />
                          )}
                          {/* <video
                            controls
                            style={{ width: "300px", height: "60px" }}
                            className="mb-2"
                            autoPlay
                          >
                            <source
                              src={responseGet2?.postMedia?.url}
                              type="audio/mp3"
                            />
                          </video> */}
                          <p className="mb-0 ps-3">
                            <span className="me-1">
                              {Math.round(
                                parseFloat(responseGet2?.postMedia?.fileSize) >
                                  1024
                                  ? parseFloat(
                                      responseGet2?.postMedia?.fileSize
                                    ) / 1024
                                  : parseFloat(
                                      responseGet2?.postMedia?.fileSize
                                    )
                              )}{" "}
                              {parseFloat(responseGet2?.postMedia?.fileSize) >
                              1024
                                ? "Mb"
                                : "Kb"}{" "}
                            </span>
                            <span>{responseGet2?.postMedia?.duration}</span>
                          </p>
                        </div>
                      </div>
                    )}

                    <div className="location mb-4">
                      <div>
                        <ul>
                          <li>
                            <p className="mb-0 d-inline-flex align-items-center">
                              <img
                                src={Images.DATE}
                                className="me-2"
                                height="13px"
                                alt="companions"
                              />
                              Date
                            </p>
                            <p className="fs-12 fw-normal">
                              {moment
                                .utc(responseGet2?.createdAt)
                                .local()
                                .format("MMMM D, YYYY")}
                            </p>
                          </li>
                          <li>
                            <p className=" mb-0 d-inline-flex align-items-center">
                              <img
                                src={Images.TIME}
                                className="me-2"
                                height="13px"
                                alt="companions"
                              />
                              Time
                            </p>
                            <p className="fs-12 fw-normal">
                              {moment
                                .utc(responseGet2?.createdAt)
                                .local()
                                .format("h:mm A")}
                            </p>
                          </li>
                          <li>
                            <p className="d-inline-flex align-items-center mb-0">
                              <img
                                src={Images.TIME}
                                className="me-2"
                                height="13px"
                                alt="companions"
                              />
                              Duration
                            </p>
                            <p className="fs-12 fw-normal">
                              {responseGet2?.duration}
                            </p>
                          </li>
                        </ul>
                        {responseGet2?.isOngoingPost == false ? (
                          <>
                            {responseGet2?.description ==
                            "Recording scheduled" ? null : (
                              <>
                                <p className="d-inline-flex align-items-center mb-0">
                                  <img
                                    src={Images.LOCATION}
                                    height="12px"
                                    className="me-3"
                                    alt=""
                                  />
                                  <span>Location</span>
                                </p>
                                <p className="fs-12 mb-4 fw-normal">
                                  <strong>
                                    {responseGet2?.addressLine1 ==
                                    responseGet2?.locationName
                                      ? responseGet2?.locationName
                                      : null}
                                    {!responseGet2?.addressLine1
                                      ? responseGet2?.locationName
                                      : null}
                                    <br />
                                  </strong>
                                  {!responseGet2?.addressLine2 ? null : (
                                    <>
                                      {responseGet2?.addressLine2?.split(
                                        ","
                                      )[0] +
                                        ", " +
                                        responseGet2?.addressLine2?.split(
                                          ","
                                        )[1] +
                                        " " +
                                        responseGet2?.addressLine2?.split(
                                          ","
                                        )[2]}
                                      <span className="d-block">
                                        {
                                          responseGet2?.addressLine2?.split(
                                            ","
                                          )[3]
                                        }
                                      </span>
                                    </>
                                  )}
                                </p>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {responseGet2?.description ==
                            "Recording scheduled" ? null : (
                              <>
                                <p className="d-inline-flex align-items-center mb-0">
                                  <img
                                    src={Images.LOCATION}
                                    height="12px"
                                    className="me-3"
                                    alt=""
                                  />
                                  <span>Location</span>
                                </p>
                                <p className="fs-12 mb-4 fw-normal">
                                  <strong>
                                    {responseGet2?.addressLine1 ==
                                    responseGet2?.locationName
                                      ? responseGet2?.locationName
                                      : null}
                                    {!responseGet2?.addressLine1
                                      ? responseGet2?.locationName
                                      : null}
                                    <br />
                                  </strong>
                                  {responseGet2?.addressLine2?.split(",")[0] +
                                    ", " +
                                    responseGet2?.addressLine2?.split(",")[1] +
                                    " " +
                                    responseGet2?.addressLine2?.split(",")[2]}
                                  <span className="d-block">
                                    {responseGet2?.addressLine2?.split(",")[3]}
                                  </span>
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </div>
                      {responseGet2?.isOngoingPost == false ? (
                        <>
                          {responseGet2?.description ==
                          "Recording scheduled" ? null : (
                            <div className="map-box mb-4">
                              {/* <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.2515839006487!2d77.36386661545025!3d28.622220991297475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff430a9852f%3A0x11b0f6c89f81224e!2sTechAhead!5e0!3m2!1sen!2sin!4v1667380582022!5m2!1sen!2sin"
                      title="maps"
                      allowfullscreen=""
                      loading="lazy"
                    ></iframe> */}

                              <>
                                {/* {isLoaded ? (
                          <GoogleMap
                            center={{
                              lat: responseGet2?.lattitude,
                              lng: responseGet2?.longitude,
                            }}
                            zoom={10}
                            mapContainerStyle={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <MarkerF
                              position={{
                                lat: responseGet2?.lattitude,
                                lng: responseGet2?.longitude,
                              }}
                            />
                          </GoogleMap>
                        ) : null} */}
                                <iframe
                                  name="gMap"
                                  className="w-100"
                                  style={{ height: "100%" }}
                                  src={`https://www.google.com/maps/embed/v1/place?q=${responseGet2?.lattitude},${responseGet2?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                                ></iframe>
                              </>
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                    {responseGet2?.description ==
                    "Recording scheduled" ? null : (
                      <div className="share-profile mb-4">
                        {responseGet2?.sharingWith?.length > 0 ? (
                          <>
                            <p className="d-inline-flex align-items-center mb-3">
                              <img
                                src={Images.SHARE}
                                className="me-2"
                                height="14px"
                                alt=""
                              />
                              <span>You're sharing with</span>
                            </p>

                            <ul className="user-wrapper sharing-with d-flex flex-wrap mb-0">
                              {responseGet2?.sharingWith.map((item, index) => {
                                return (
                                  <>
                                    {!item?.userProfilePic ? (
                                      <li className="user me-3" key={index}>
                                        <span className="text-word">
                                          {item?.userFriendName?.substring(
                                            0,
                                            1
                                          )}
                                        </span>
                                        {/* </div> */}
                                        <p className="text-center mb-2 fw-normal">
                                          {item.userFriendName}
                                        </p>
                                      </li>
                                    ) : (
                                      <li className="user me-3">
                                        <div className="img-box">
                                          <img
                                            src={item.url}
                                            className="user-img"
                                            alt="profile"
                                          />
                                        </div>
                                        <p className="text-center mb-2 fw-normal">
                                          {item.userFriendName}
                                        </p>
                                      </li>
                                    )}
                                  </>
                                );
                              })}
                            </ul>
                          </>
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default RightSidebar;
