import React, { useState, useEffect } from "react";
import Layout from "../../layout";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCmsData } from "../../../services/api/endpoints";
import axiosInstance from "../../../services/middleware";


function Cms() {

  const [responseGet, setResponseGet] = useState([]);
  const [noRecord, setNoRecord] = useState(false)
  useEffect(() => {
    async function cmsData() {
      try {
        const response = await axiosInstance.get(getCmsData);
        const apiData = response.data.data;
        setResponseGet(apiData);
      } catch (err) {
        setNoRecord(true)
      }
    }

    cmsData();
  }, []);



  return (
    <Layout>
      <h1 className="title-heading">CMS Pages</h1>
      <div className="static-page">
        <div className="bg-outer table-responsive mb-4">
          <table className="table big-col-table seperate-column">
            <thead>
              <tr>
                <td>S No.</td>
                <td>Page Name</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Customer Help</td>
                <td>
                  <Link
                    to="/cms-editor"
                    state={{
                      cms: responseGet ? responseGet[0]?.pageHtml : "",
                      id: responseGet ? responseGet[0]?.id : null,
                      name: "Customer Help"
                    }}
                    className="text-dark "
                  >
                    <i className="icon icon-pencil-square"></i>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>About Us</td>
                <td>
                  <Link to="/cms-editor" className="text-dark " state={{
                    cms: responseGet ? responseGet[1]?.pageHtml : "",
                    id: responseGet ? responseGet[1]?.id : null,
                    name: "About Us"
                  }}>
                    <i className="icon icon-pencil-square"></i>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Terms & Conditions</td>
                <td>
                  <Link to="/cms-editor" className="text-dark " state={{
                    cms: responseGet ? responseGet[2]?.pageHtml : "",
                    id: responseGet ? responseGet[2]?.id : null,
                    name: "Terms & Conditions"
                  }}>
                    <i className="icon icon-pencil-square"></i>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Privacy Policy</td>
                <td>
                  <Link to="/cms-editor" className="text-dark " state={{
                    cms: responseGet ? responseGet[3]?.pageHtml : "",
                    id: responseGet ? responseGet[3]?.id : null,
                    name: "Privacy Policy"
                  }}>
                    <i className="icon icon-pencil-square"></i>
                  </Link>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>FAQ</td>
                <td>
                  <Link to="/faq-editor" className="text-dark " state={{
                    cms: responseGet ? responseGet[3]?.pageHtml : "",
                    id: responseGet ? responseGet[3]?.id : null,
                    name: "Privacy Policy"
                  }}>
                    <i className="icon icon-pencil-square"></i>
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

export default Cms;
