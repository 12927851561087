import React, { useState, useEffect } from "react";
import Layout from "../../../layout";
import { Breadcrumb } from "react-bootstrap";
import BreadCrumb from "../breadcrumb";
import axiosInstance from "../../../../services/middleware";
import { useSelector ,useDispatch } from "react-redux";
import { getUserLocationHistory } from "../../../../services/api/endpoints";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Link , useNavigate, useLocation  } from "react-router-dom";
import { CSVLink } from "react-csv";
import { decryptRequest } from "../../../../services/helpers/crypto";

const LocationHistory = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.profileData);
  const [responseGet, setResponseGet] = useState();
  // ${profile?.id}
  const [csvFormat,setCsvFormat] = useState(null)

  const [pageNumber, setPageNumber] = useState(0);
  const dataPerPage = 12;
  const pagesVisited = pageNumber * dataPerPage;
  const pageCount = Math.ceil(responseGet?.length / dataPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  useEffect(() => {
    async function locationHistoryData() {
      try {
        const response = await axiosInstance.get(
          `${getUserLocationHistory}/${profile?.id}`
        );
        const apiData = response.data.data;
        setResponseGet(apiData);
        const formattedResponse = apiData?.map(response => {
          const formattedDate = moment
            .utc(response?.createdAt)

            .format("MM-DD-YYYY");
          const formattedTime = moment
          .utc(
            response?.createdAt)
          .local()

          .format("h:mm A");
          return {
            ...response,
            createdAt: formattedDate,
            startTime: formattedTime,
          };
        });
        setCsvFormat(formattedResponse)
      } catch (err) {
        console.error("network Error");
      }
    }

    locationHistoryData();
  }, []);
  const headers = [
    { label: "Date", key: "createdAt" },
    { label: "Time", key: "startTime" },
    { label: "Latitude", key: "latitude" },
    { label: "longitude", key: "longitude" },
    { label: "Battery %", key: "batteryPercentage" },

  ];
  const csvReport = {
    data: csvFormat,
    headers: headers,
    filename: "location_history.csv",
  };
  const onBackClick = () => {
    if (location?.state?.id == "archived") {
      dispatch({ type: "IS_ARCHIVE", payload: true });
    } else {
      dispatch({ type: "IS_ARCHIVE", payload: false });
    }

    navigate(`/user-management`, {
      state: {
        id: location?.state?.id == "archived" ? location?.state?.id : null,
      },
    });
  };
  return (
    <Layout>
      <Breadcrumb>
        {/* <Breadcrumb.Item href="#"><Link to="/user-management">User Management</Link></Breadcrumb.Item> */}
        <Breadcrumb.Item>
          <Link to="/user-management" onClick={() => onBackClick()}>
            {location?.state?.id == "archived" ? "Archive Users" : "All Users"}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{decryptRequest(profile?.name)}'s Location History</Breadcrumb.Item>
      </Breadcrumb>
      <div className="top-filter">
        <div className="row">
          <BreadCrumb
            className="new"
            setHeaderState={props.setHeaderState}
            headerState={props.headerState}
          />
          <div className="col-auto ms-auto">
            {responseGet?.length > 0 ? (
              <CSVLink
                type="button"
                className="btn btn-white btn-icon btn-lg btn-right ms-auto"
                {...csvReport}
              >
                <span>Download </span> <i className="icon-download"></i>
              </CSVLink>
            ) : null}
          </div>
        </div>
      </div>
      <div className="bg-outer table-responsive">
        <table className="table seperate-column">
          <thead>
            <tr>
              <td>Date</td>
              <td>Time</td>
              <td>Latitude</td>
              <td>Longitude</td>
              <td>Battery Percentage</td>
            </tr>
          </thead>
          <tbody>
            {/* {responseGet?.length > 0 ? ( */}
            {responseGet
              ?.slice(pagesVisited, pagesVisited + dataPerPage)
              ?.map((location) => (
                <tr>
                  <td>
                    {moment.utc(location?.createdAt).local().format("MM-DD-YYYY")}
                  </td>
                  <td>{moment.utc(location?.createdAt).local().format("h:mm a")}</td>
                  <td>{location?.latitude}</td>
                  <td>{location?.longitude}</td>
                  <td>
                    {location?.batteryPercentage === null
                      ? "- -"
                      : location?.batteryPercentage + "%"}
                  </td>
                </tr>
              ))}
           
          </tbody>
        </table>
        {responseGet?.length > 0 ? (
          <div className="pagination-btn">
            <ReactPaginate
              previousLabel={`previous`}
              nextLabel={`next`}
              breakLabel={`..`}
              containerClassName={`pagination mb-4 justify-content-start `}
              pageClassName={`page-item`}
              pageCount={pageCount}
              onPageChange={changePage}
              pageLinkClassName={`page-link `}
              previousClassName={`page-item `}
              previousLinkClassName={`page-link `}
              nextClassName={`page-item `}
              nextLinkClassName={`page-link `}
              breakClassName={`page-item `}
              breakLinkClassName={`page-link `}
              activeClassName={`pagination-bg-color`}
            />
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default LocationHistory;
