const initialState = {
    pageNo: 1,
    archivePageNo: 1,
    isArchive:false
  };
  
  export const pageNoData = (state = initialState, action) => {
    switch (action.type) {
      case "PAGE_NO": {
        return {
          ...state,
          pageNo: action.payload,
        };
      }
      case "ARCHIVE_PAGE_NO": {
        return {
          ...state,
          archivePageNo: action.payload,
        };
      }
      case "IS_ARCHIVE": {
        return {
          ...state,
          isArchive: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  