import React from "react";

const SosBreadcrumb = (props) => {
  const onActiveSosClick = (event) => {
    event.preventDefault();
    props.setHeaderState(1);
  };
  const onSosHistoryClick = (event) => {
    event.preventDefault();
    props.setHeaderState(2);
  };
  const onArchivedSosClick = (event) => { 
    event.preventDefault();
    props.setHeaderState(3);
  };

  return (
    <div className="col-auto">
      <div className="col-md-12">
        <div className="btn-group-wrap">
          <button
            className={
              props?.headerState === 1
                ? "btn btn-xl btn-round active"
                : "btn btn-xl btn-round"
            }
            onClick={(event) => onActiveSosClick(event)}
          >
            Active SOS Alerts
          </button>
          <button
            className={
              props?.headerState === 2
                ? "btn btn-xl btn-round active"
                : "btn btn-xl btn-round"
            }
            onClick={(event) => onSosHistoryClick(event)}
          >
            SOS History
          </button>
          <button
            className={
              props?.headerState === 3
                ? "btn btn-xl btn-round active"
                : "btn btn-xl btn-round"
            }
            onClick={(event) => onArchivedSosClick(event)}
          >
            Archived SOS
          </button>
        </div>
      </div>
    </div>
  );
};

export default SosBreadcrumb;
