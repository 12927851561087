const initalState = {
    forgotPasswordResponse: "",
  };
  
  export const forgotPassword = (state = initalState, action) => {
    switch (action.type) {
      case "FORGOT_PWD": {
        return {
          ...state,
          forgotPasswordResponse: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  