import { Row, Col } from "react-bootstrap";
import Images from "../Images";
import { useSelector } from "react-redux";
import { useState } from "react";
import ChangePassword from "../ChangePassword";
import { changePassword } from "../../services/api/endpoints";
import axiosInstance from "../../services/middleware";
import { message } from "antd";
import { decryptRequest, encryptRequest } from "../../services/helpers/crypto";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const MyProfile = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { closeModal } = props;
  const { loginData } = useSelector((state) => state.adminLoginData);
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleClick = (event) => {
    closeModal();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    let payload = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    try {
      const response = await axiosInstance.put(`${changePassword}`, payload);
      const apiData = response;
      if (apiData?.data?.success == false) {
        setLoading(false);

        message.error(apiData?.data?.message);
      } else {
        setLoading(false);

        message.success(apiData?.data?.message);
        dispatch({ type: "RESET_STATE" });
        localStorage.clear();
        navigate("/");
      }
    } catch (err) {
      setLoading(false);

      message.error(err);
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  return (
    <>
      <div className="profile-wrappedup">
        <Row>
          <Col md={6}>
            <div className="profile-title">
              <h3 className="title-heading text-primary mb-0">
                Personal Information
              </h3>
            </div>
            <div className="profile-body">
              <div className="profile-top-details text-center mb-4">
                <div className="profile-img">
                  <img src={Images.ADMINPIC} alt="profile" />
                </div>
                <h3>{decryptRequest(loginData?.data?.name)}</h3>
                <p>{decryptRequest(loginData?.data?.email)}</p>
              </div>
              <div className="profile-details">
                {/* <div className="row-icon">
                  <i className="icon-user"></i>
                  <div className="right-detail">
                    <span>Name</span>
                    <p>{formatPhoneNumber(loginData?.data?.name)}</p>
                  </div>
                </div> */}
                <div className="row-icon">
                  <i className="icon-envelope"></i>
                  <div className="right-detail">
                    <span>Email Address</span>
                    <p>{decryptRequest(loginData?.data?.email)}</p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <ChangePassword
              closeModal={handleClick}
              handleSubmit={handleSubmit}
              setCurrentPassword={setCurrentPassword}
              setNewPassword={setNewPassword}
              currentPassword={currentPassword}
              newPassword={newPassword}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyProfile;
