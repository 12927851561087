import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Layout from "../../../layout";
import Images from "../../../Images";
import { Breadcrumb } from "react-bootstrap";
import RightSidebar from "../../../rightSideBar";
import BreadCrumb from "../breadcrumb";
import TimelineModal from "../../../modal/timeline";
import axiosInstance from "../../../../services/middleware";
import {
  getPostDetailsCommute,
  getPostDetailsDate,
  getPostDetailsLocationCheckin,
  getPostDetailsNote,
  getPostDetailSos,
  getPostDetailsRecording,
  getPostDetailsSafetyCheckin,
  getUserTimeline,
} from "../../../../services/api/endpoints";
import moment from "moment/moment";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  Polyline,
  MarkerF,
} from "@react-google-maps/api";
import BatteryGauge from "react-battery-gauge";
import { decryptRequest } from "../../../../services/helpers/crypto";

const containerStyle = {
  width: "100%",
  height: "calc(100vh - 294px)",
};

const Timeline = (props) => {
  // modal show
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profileData);
  // const { isLoaded } = useJsApiLoader({
  //   id: "google-map-script",
  //   // googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  //   googleMapsApiKey: "AIzaSyCAgGrJgo_Z85DivzP2QnKchBw9xaseP",
  // });
  const [showmodal, setShowmodal] = useState(false);
  const [postId, setPostId] = useState(null);
  const [date, setDate] = useState(
    moment.utc(new Date()).local().format("MM-DD-YYYY")
  );
  const [startDate, setStartDate] = useState(new Date());
  const [postType, setPostType] = useState(null);
  const showHandler = (param) => {
    setShowmodal(param);
  };
  const [responseGet, setResponseGet] = useState(null);
  const [responseGet2, setResponseGet2] = useState(null);
  const [bigMapData, setBigMapData] = useState(null);
  const [commuteData, setCommuteDate] = useState(null);
  const [geoAddress, setGeoAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function timelineData() {
      try {
        const response = await axiosInstance.get(
          `${getUserTimeline}/${profile?.id}/${moment(date)
            .local()
            .format("MM-DD-YYYYThh:mm:ss")}`
          // `${getUserTimeline}/225/12-14-2022T00:00:00`
        );
        const apiData = response.data.data;
        setResponseGet(apiData);

        if (apiData) {
          const id22 = apiData.find((obj) => obj.type === 21);
          if (id22 != undefined) {
            const url = `https://nominatim.openstreetmap.org/reverse?lat=${id22?.lattitude}&lon=${id22?.longitude}&format=json`;

            return fetch(url)
              .then((response) => response.json())
              .then((data) => setGeoAddress(data.display_name))
              .catch((error) => console.error(error));
          }
        }
        let arr = [];
        let arr2 = [];
        for (let i = 0; i < apiData?.length; i++) {
          if (apiData[i]?.lattitude != null && apiData[i]?.longitude != null) {
            arr.push({
              latitude: apiData[i]?.lattitude,
              longitude: apiData[i]?.longitude,
            });
          }
          if (apiData[i]?.sosEndDetails != null) {
            arr2.push({
              latitudeStart: apiData[i]?.lattitude,
              longitudeStart: apiData[i]?.longitude,
              latitudeEnd: apiData[i]?.sosEndDetails?.lattitude,
              longitudeEnd: apiData[i]?.sosEndDetails?.longitude,
            });
          }
        }
        if (arr?.length > 0) {
          setBigMapData(arr);
        }
        if (arr2?.length > 0) {
          setCommuteDate(arr2);
        }
      } catch (err) {
        console.log(err);
      }
    }
    timelineData();
  }, [date]);

  const onTimelineClick = async () => {};

  const onReduceDayClick = () => {
    let oneDay = moment
      .utc(date)

      .subtract(1, "days")
      .format("MM-DD-YYYY");
    setDate(oneDay);
  };

  const onAddDayClick = () => {
    let oneDay = moment.utc(date).add(1, "days").format("MM-DD-YYYY");
    setDate(oneDay);
  };

  const selectPostId = async (timeline) => {
    setPostId(timeline?.id);
    setLoading(true);
    try {
      if (timeline?.type === 1) {
        setPostType(timeline?.type);
        const response2 = await axiosInstance.get(
          `${getPostDetailsLocationCheckin}/${timeline?.id}`
        );
        const apiData2 = response2.data.data;
        if (apiData2) {
          setLoading(false);
        }
        setResponseGet2(apiData2);
      }
      if (timeline?.type === 2) {
        setPostType(timeline?.type);
        const response2 = await axiosInstance.get(
          `${getPostDetailsDate}/${timeline?.id}`
        );
        const apiData2 = response2.data.data;
        if (apiData2) {
          setLoading(false);
        }
        setResponseGet2(apiData2);
      }
      if (timeline?.type === 3) {
        setPostType(timeline?.type);
        const response2 = await axiosInstance.get(
          `${getPostDetailsCommute}/${timeline?.id}`
        );
        const apiData2 = response2.data.data;
        if (apiData2) {
          setLoading(false);
        }
        setResponseGet2(apiData2);
      }
      if (timeline?.type === 4) {
        setPostType(timeline?.type);
        const response2 = await axiosInstance.get(
          `${getPostDetailsNote}/${timeline?.id}`
        );
        const apiData2 = response2.data.data;
        if (apiData2) {
          setLoading(false);
        }
        setResponseGet2(apiData2);
      }
      if (timeline?.type === 5) {
        setPostType(timeline?.type);
        const response2 = await axiosInstance.get(
          `${getPostDetailsSafetyCheckin}/${timeline?.id}`
        );
        const apiData2 = response2.data.data;
        if (apiData2) {
          setLoading(false);
        }
        setResponseGet2(apiData2);
      }
      if (timeline?.type === 6) {
        setPostType(timeline?.type);
        const response2 = await axiosInstance.get(
          `${getPostDetailsRecording}/${timeline?.id}`
        );
        const apiData2 = response2.data.data;
        if (apiData2) {
          setLoading(false);
        }
        setResponseGet2(apiData2);
      }
      if (timeline?.type === 7 || timeline?.type === 8) {
        setPostType(timeline?.type);
        const response2 = await axiosInstance.get(
          `${getPostDetailSos}/${timeline?.id}`
        );
        const apiData2 = response2.data.data;
        if (apiData2) {
          setLoading(false);
        }
        setResponseGet2(apiData2);
      }
      if (timeline?.type === 20) {
        setPostType(timeline?.type);
        const response2 = await axiosInstance.get(
          `${getPostDetailsLocationCheckin}/${timeline?.id}`
        );
        const apiData2 = response2.data.data;
        if (apiData2) {
          setLoading(false);
        }
        setResponseGet2(apiData2);
      }
      if (timeline?.type === 21) {
        setPostType(timeline?.type);
        const response2 = await axiosInstance.get(
          `${getPostDetailsSafetyCheckin}/${timeline?.id}`
        );
        const apiData2 = response2.data.data;
        // if (apiData2) {
        //   const url = `https://nominatim.openstreetmap.org/reverse?lat=${apiData2?.lattitude}&lon=${apiData2?.longitude}&format=json`;

        //   fetch(url)
        //     .then((response) => response.json())

        //     .then((data) => setGeoAddress(data.display_name))

        //     .catch((error) => console.error(error));
        // }
        if (apiData2) {
          setLoading(false);
        }
        setResponseGet2(apiData2);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const getGeoAddress = (lat, lng) => {

  // };

  const onBackClick = () => {
    if (location?.state?.id == "archived") {
      dispatch({ type: "IS_ARCHIVE", payload: true });
    } else {
      dispatch({ type: "IS_ARCHIVE", payload: false });
    }

    navigate(`/user-management`, {
      state: {
        id: location?.state?.id == "archived" ? location?.state?.id : null,
      },
    });
  };
  const CustomBattery = {
    batteryBody: {
      strokeWidth: 4,
      cornerRadius: 6,
      fill: "none",
      strokeColor: "#111",
    },
    batteryCap: {
      fill: "none",
      strokeWidth: 4,
      strokeColor: "#111",
      cornerRadius: 2,
      capToBodyRatio: 0.4,
    },
    batteryMeter: {
      fill: "black",
      lowBatteryValue: 15,
      lowBatteryFill: "red",
      outerGap: 8,
      noOfCells: 1,
      interCellsGap: 1,
    },
    readingText: {
      lightContrastColor: "#111",
      darkContrastColor: "#fff",
      lowBatteryColor: "red",
      fontFamily: "Helvetica",
      fontSize: 14,
      display: "none",
      showPercentage: false,
    },
    chargingFlash: {
      scale: undefined,
      fill: "orange",
      animated: true,
      animationDuration: 1000,
    },
  };
  return (
    <Layout>
      <TimelineModal showmodal={showmodal} showHandler={showHandler} />
      <Breadcrumb>
        {/* <Breadcrumb.Item>
          <Link to="/user-management">User Management</Link>
        </Breadcrumb.Item> */}
        <Breadcrumb.Item>
          <Link to="/user-management" onClick={() => onBackClick()}>
            {location?.state?.id == "archived" ? "Archive Users" : "All Users"}
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item active>
          {decryptRequest(profile?.name)}’s Timeline
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="top-filter">
        <div className="row">
          <div className="col-md-12">
            <BreadCrumb
              setHeaderState={props.setHeaderState}
              headerState={props.headerState}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-7">
          <div className="bg-outer timeline-wrapper">
            <div className="head-bar">
              <h1 className="title-heading">
                {decryptRequest(profile?.name)}'s Timeline
              </h1>
              <button
                type="button"
                className="btn btn-white btn-round btn-icon btn-lg"
                onClick={() => {
                  showHandler(true);
                }}
              >
                <span>Download Timeline</span>{" "}
                <i className="icon-download"> </i>
              </button>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="date-time">
                  <a className="angle" onClick={() => onReduceDayClick()}>
                    <i className="icon-angle-left"></i>
                  </a>
                  <div className="date">
                    <span>
                      <span>Date</span>{" "}
                      <label htmlFor="calendarshow" className="cursor-pointer">
                        <i className="icon-calendar"></i>
                      </label>
                      <div className="date-space h-0">
                        <DatePicker
                          className="datepicker d-none"
                          id="calendarshow"
                          selected={new Date(date)}
                          // value={moment(date, "MM-DD-YYYY")}
                          onChange={(value) =>
                            setDate(
                              moment.utc(value).local().format("MM-DD-YYYY")
                            )
                          }
                        />
                      </div>
                    </span>
                    <h3>{date}</h3>
                  </div>
                  <a className="angle" onClick={() => onAddDayClick()}>
                    <i className="icon-angle-right"></i>
                  </a>
                </div>
                <div className="timeline">
                  <ul className="timeline-status">
                    {responseGet?.length > 0 ? (
                      responseGet?.map((timeline, index) => {
                        return (
                          <>
                            {timeline?.type === 5 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                <div className="status-attr timeline-edit-status">
                                  <i className="icon-check"></i>
                                </div>
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(timeline?.createdAt)
                                      .local()
                                      .format("h:mm A")}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                <h5>{timeline?.description}</h5>
                                <p>
                                  <img src={Images.LOCATION} alt="location" />{" "}
                                  {timeline?.locationName}
                                </p>

                                <div className="img-group">
                                  <p>Shared with:</p>
                                  {timeline?.sharingWith?.length > 0 ? (
                                    <>
                                      <ul className="img-collect">
                                        {timeline?.sharingWith.map(
                                          (item, index) => (
                                            <>
                                              {!item?.userProfilePic ? (
                                                <li>
                                                  <span className="letter-f">
                                                    {item?.userFriendName?.substring(
                                                      0,
                                                      1
                                                    )}
                                                  </span>
                                                </li>
                                              ) : (
                                                <li>
                                                  <img
                                                    src={item?.url}
                                                    alt="profile"
                                                  />
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    <p> &#x2014;</p>
                                  )}
                                </div>
                              </li>
                            ) : null}
                            {timeline?.type === 21 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                <div className="status-attr timeline-sos-status">
                                  <i className="icon-cross"></i>
                                </div>
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(timeline?.createdAt)
                                      .local()
                                      .format("h:mm A")}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                <h5>{timeline?.description}</h5>
                                <p>
                                  <img src={Images.LOCATION} alt="location" />{" "}
                                  {/* {getGeoAddress(timeline?.lattitude, timeline?.longitude)} */}
                                  {/* {geoAddress?.split(",")[0] +
                                    " " +
                                    geoAddress?.split(",")[1]} */}
                                  {/* {geoAddress} */}
                                  
                                  {timeline?.locationName}
                                
                                </p>

                                <div className="img-group">
                                  <p>Shared with:</p>
                                  {timeline?.sharingWith?.length > 0 ? (
                                    <>
                                      <ul className="img-collect">
                                        {timeline?.sharingWith.map(
                                          (item, index) => (
                                            <>
                                              {!item?.userProfilePic ? (
                                                <li>
                                                  <span className="letter-f">
                                                    {item?.userFriendName?.substring(
                                                      0,
                                                      1
                                                    )}
                                                  </span>
                                                </li>
                                              ) : (
                                                <li>
                                                  <img
                                                    src={item?.url}
                                                    alt="profile"
                                                  />
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    <p> &#x2014;</p>
                                  )}
                                </div>
                              </li>
                            ) : null}
                            {timeline?.type === 6 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                <div className="status-attr timeline-recording-status">
                                  <i className="icon-recorder"></i>
                                </div>
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(
                                        timeline?.postStartDate?.slice(0, 10) +
                                          "T" +
                                          timeline?.startTime
                                      )
                                      .local()
                                      .format("h:mm A")}
                                    {moment
                                      .utc(
                                        timeline?.postStartDate?.slice(0, 10) +
                                          "T" +
                                          timeline?.startTime
                                      )
                                      .local()
                                      .format("h:mm A") >=
                                    moment
                                      .utc(new Date())
                                      .local()
                                      .format("h:mm A") ? (
                                      <span className="text-success">
                                        <i>
                                          {timeline?.description ==
                                            "Recording scheduled" &&
                                          timeline?.upcoming === true
                                            ? " Upcoming"
                                            : null}
                                        </i>
                                      </span>
                                    ) : null}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                {timeline?.description ==
                                "Recording scheduled" ? (
                                  <h5>Recording Scheduled</h5>
                                ) : (
                                  <h5>Created a Recording</h5>
                                )}
                                {/* {timeline?.isOngoingPost == false ? (
                                 
                                ) : null} */}
                                {timeline?.description ==
                                "Recording scheduled" ? null : (
                                  <p>
                                    <img src={Images.LOCATION} alt="location" />{" "}
                                    {timeline?.locationName}
                                  </p>
                                )}

                                {/* <div className="img-group">
                                  <p>Shared with:</p>
                                  {timeline?.sharingWith?.length > 0 ? (
                                    <>
                                      <ul className="img-collect">
                                        {timeline?.sharingWith.map(
                                          (item, index) => (
                                            <>
                                              {item?.userProfilePic != null ? (
                                                <li>
                                                  <img
                                                    src={item?.url}
                                                    alt="profile"
                                                  />
                                                </li>
                                              ) : (
                                                <li>
                                                  <span className="letter-f">
                                                    {item?.userFriendName?.substring(
                                                      0,
                                                      1
                                                    )}
                                                  </span>
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    <p> &#x2014;</p>
                                  )}
                                </div> */}
                              </li>
                            ) : null}
                            {timeline?.type === 4 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                <div className="status-attr timeline-edit-status">
                                  <i className="icon-pencil-square"></i>
                                </div>
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(timeline?.createdAt)
                                      .local()
                                      .format("h:mm A")}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                <h5>{timeline?.description}</h5>
                                <p>
                                  <img src={Images.LOCATION} alt="location" />{" "}
                                  {timeline?.locationName}
                                </p>

                                <div className="img-group">
                                  <p>Shared with:</p>
                                  {timeline?.sharingWith?.length > 0 ? (
                                    <>
                                      <ul className="img-collect">
                                        {timeline?.sharingWith.map(
                                          (item, index) => (
                                            <>
                                              {!item?.userProfilePic ? (
                                                <li>
                                                  <span className="letter-f">
                                                    {item?.userFriendName?.substring(
                                                      0,
                                                      1
                                                    )}
                                                  </span>
                                                </li>
                                              ) : (
                                                <li>
                                                  <img
                                                    src={item?.url}
                                                    alt="profile"
                                                  />
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    <p> &#x2014;</p>
                                  )}
                                </div>
                              </li>
                            ) : null}
                            {timeline?.type === 20 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                {timeline?.freqLocationType == "Work" ? (
                                  <div className="status-attr timeline-heart-status">
                                    <i className="icon-work"></i>
                                  </div>
                                ) : null}
                                {timeline?.freqLocationType == "School" ? (
                                  <div className="status-attr timeline-heart-status">
                                    <i className="icon-school"></i>
                                  </div>
                                ) : null}
                                {timeline?.freqLocationType == "Home" ? (
                                  <div className="status-attr timeline-heart-status">
                                    <i className="icon-house"></i>
                                  </div>
                                ) : null}
                                {timeline?.freqLocationType == "Other1" ? (
                                  <div className="status-attr timeline-heart-status">
                                    <i className="icon-star"></i>
                                  </div>
                                ) : null}
                                {timeline?.freqLocationType == "Other2" ? (
                                  <div className="status-attr timeline-heart-status">
                                    <i className="icon-smile"></i>
                                  </div>
                                ) : null}
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(timeline?.createdAt)
                                      .local()
                                      .format("h:mm A")}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                <h5>
                                  {timeline?.didEnter == true
                                    ? "Arrived at " + timeline?.locationName
                                    : "Departed from " + timeline?.locationName}
                                </h5>
                                <p>
                                  <img src={Images.LOCATION} alt="location" />{" "}
                                  {timeline?.businessName}
                                </p>

                                <div className="img-group">
                                  <p>Shared with:</p>
                                  {timeline?.sharingWith?.length > 0 ? (
                                    <>
                                      <ul className="img-collect">
                                        {timeline?.sharingWith.map(
                                          (item, index) => (
                                            <>
                                              {!item?.userProfilePic ? (
                                                <li>
                                                  <span className="letter-f">
                                                    {item?.userFriendName?.substring(
                                                      0,
                                                      1
                                                    )}
                                                  </span>
                                                </li>
                                              ) : (
                                                <li>
                                                  <img
                                                    src={item?.url}
                                                    alt="profile"
                                                  />
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    <p> &#x2014;</p>
                                  )}
                                </div>
                              </li>
                            ) : null}
                            {timeline?.type === 2 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                <div className="status-attr timeline-heart-status">
                                  <i className="icon-heart"></i>
                                </div>
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(
                                        timeline?.postStartDate?.slice(0, 10) +
                                          "T" +
                                          timeline?.startTime
                                      )
                                      .local()
                                      .format("h:mm A")}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                <h5>
                                  {timeline?.description}{" "}
                                  {moment
                                    .utc(
                                      timeline?.postStartDate?.slice(0, 10) +
                                        "T" +
                                        timeline?.startTime
                                    )
                                    .local() >=
                                  moment.utc(new Date()).local() ? (
                                    // <span className="text-success">
                                    <i className="text-success">
                                      {/* {timeline?.description ==
                                            "Recording scheduled"
                                            ? " Upcoming"
                                            : null} */}
                                      Upcoming
                                    </i>
                                  ) : // </span>
                                  null}
                                </h5>
                                <p>
                                  <img src={Images.LOCATION} alt="location" />{" "}
                                  {timeline?.locationName}
                                </p>

                                <div className="img-group">
                                  <p>Shared with:</p>
                                  {timeline?.sharingWith?.length > 0 ? (
                                    <>
                                      <ul className="img-collect">
                                        {timeline?.sharingWith.map(
                                          (item, index) => (
                                            <>
                                              {!item?.userProfilePic ? (
                                                <li>
                                                  <span className="letter-f">
                                                    {item?.userFriendName?.substring(
                                                      0,
                                                      1
                                                    )}
                                                  </span>
                                                </li>
                                              ) : (
                                                <li>
                                                  <img
                                                    src={item?.url}
                                                    alt="profile"
                                                  />
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    <p> &#x2014;</p>
                                  )}
                                </div>
                              </li>
                            ) : null}
                            {timeline?.type === 1 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                <div className="status-attr timeline-location-status">
                                  <i className="icon-location"></i>
                                </div>
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(timeline?.createdAt)
                                      .local()
                                      .format("h:mm A")}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                <h5>{timeline?.description}</h5>
                                <p>
                                  <img src={Images.LOCATION} alt="location" />{" "}
                                  {timeline?.locationName}
                                </p>

                                <div className="img-group">
                                  <p>Shared with:</p>
                                  {timeline?.sharingWith?.length > 0 ? (
                                    <ul className="img-collect">
                                      {timeline?.sharingWith.map(
                                        (item, index) => (
                                          <>
                                            {!item?.userProfilePic ? (
                                              <li>
                                                <span className="letter-f">
                                                  {item?.userFriendName?.substring(
                                                    0,
                                                    1
                                                  )}
                                                </span>
                                              </li>
                                            ) : (
                                              <li>
                                                <img
                                                  src={item?.url}
                                                  alt="profile"
                                                />
                                              </li>
                                            )}
                                          </>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <p> &#x2014;</p>
                                  )}
                                </div>
                              </li>
                            ) : null}
                            {timeline?.type === 3 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                <div className="status-attr timeline-recording-status">
                                  <i className="icon-car"></i>
                                </div>
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(timeline?.createdAt)
                                      .local()
                                      .format("h:mm A")}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                <h5>{timeline?.description}</h5>
                                <p>
                                  <img src={Images.LOCATION} alt="location" />{" "}
                                  {timeline?.locationName}
                                </p>

                                <div className="img-group">
                                  <p>Shared with:</p>
                                  {timeline?.sharingWith?.length > 0 ? (
                                    <ul className="img-collect">
                                      {timeline?.sharingWith.map(
                                        (item, index) => (
                                          <>
                                            {!item?.userProfilePic ? (
                                              <li>
                                                <span className="letter-f">
                                                  {item?.userFriendName?.substring(
                                                    0,
                                                    1
                                                  )}
                                                </span>
                                              </li>
                                            ) : (
                                              <li>
                                                <img
                                                  src={item?.url}
                                                  alt="profile"
                                                />
                                              </li>
                                            )}
                                          </>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <p> &#x2014;</p>
                                  )}
                                </div>
                              </li>
                            ) : null}
                            {timeline?.type === 7 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                <div className="status-attr timeline-sos-status">
                                  <i className="icon-sos"></i>
                                </div>
                                {/* <div className="status-attr timeline-edit-status">
                                <i className="icon-pencil-square"></i>
                              </div>
                              <div className="status-attr timeline-recording-status">
                                <i className="icon-recorder"></i>
                              </div>
                              <div className="status-attr timeline-heart-status">
                                <i className="icon-heart"></i>
                              </div>                            
                             
                              <div className="status-attr timeline-location-status">
                                <i className="icon-location"></i>
                              </div>
                                <div className="status-attr timeline-recording-status">
                                <i className="icon-calendar"></i>
                              </div>
                              <div className="status-attr timeline-location-status">
                                <i className="icon-check"></i>
                              </div>
                              <div className="status-attr timeline-sos-status">
                                <i className="icon-cross"></i>
                              </div>
                              <div className="status-attr timeline-recording-status">

                                <i className="icon-car"></i>

                              </div>
                              */}
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(timeline?.createdAt)
                                      .local()
                                      .format("h:mm A")}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                <h5>
                                  {timeline?.description == "SOS Start"
                                    ? "SOS"
                                    : null}
                                  {timeline?.description == "SOS cancelled"
                                    ? "Cancelled SOS"
                                    : timeline?.description}{" "}
                                  {/* <span className="danger">SOS</span> */}
                                </h5>
                                <p>
                                  <img src={Images.LOCATION} alt="location" />{" "}
                                  {timeline?.locationName}
                                </p>
                                {timeline?.description ===
                                "SOS cancelled" ? null : (
                                  <div className="img-group">
                                    <p>Shared with:</p>
                                    {timeline?.sharingWith?.length > 0 ? (
                                      <ul className="img-collect">
                                        {timeline?.sharingWith.map(
                                          (item, index) => (
                                            <>
                                              {!item?.userProfilePic ? (
                                                <li>
                                                  <span className="letter-f">
                                                    {item?.userFriendName?.substring(
                                                      0,
                                                      1
                                                    )}
                                                  </span>
                                                </li>
                                              ) : (
                                                <li>
                                                  <img
                                                    src={item?.url}
                                                    alt="profile"
                                                  />
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      <p>&#x2014;</p>
                                    )}
                                  </div>
                                )}
                              </li>
                            ) : null}
                            {timeline?.type === 8 ? (
                              <li
                                className={`"drive-cancelled" ${
                                  postId === timeline?.id ? "active" : ""
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() => selectPostId(timeline)}
                                key={index}
                              >
                                <div className="status-attr timeline-sos-status">
                                  <i className="icon-sos"></i>
                                </div>
                                {/* <div className="status-attr timeline-edit-status">
                                <i className="icon-pencil-square"></i>
                              </div>
                              <div className="status-attr timeline-recording-status">
                                <i className="icon-recorder"></i>
                              </div>
                              <div className="status-attr timeline-heart-status">
                                <i className="icon-heart"></i>
                              </div>                            
                             
                              <div className="status-attr timeline-location-status">
                                <i className="icon-location"></i>
                              </div>
                                <div className="status-attr timeline-recording-status">
                                <i className="icon-calendar"></i>
                              </div>
                              <div className="status-attr timeline-location-status">
                                <i className="icon-check"></i>
                              </div>
                              <div className="status-attr timeline-sos-status">
                                <i className="icon-cross"></i>
                              </div>
                              <div className="status-attr timeline-recording-status">

                                <i className="icon-car"></i>

                              </div>
                              */}
                                <div className="head-time">
                                  <h4>
                                    {moment
                                      .utc(timeline?.createdAt)
                                      .local()
                                      .format("h:mm A")}
                                  </h4>
                                  <div className="battery-btn d-inline-flex align-items-center px-2">
                                    <BatteryGauge
                                      className="me-2"
                                      value={timeline?.batteryPercentage}
                                      size={20}
                                      customization={CustomBattery}
                                    />
                                    {timeline?.batteryPercentage}%
                                  </div>
                                </div>
                                <h5>
                                  {timeline?.description == "SOS Start"
                                    ? "SOS"
                                    : timeline?.description}{" "}
                                  {/* <span className="danger">SOS</span> */}
                                </h5>
                                <p>
                                  <img src={Images.LOCATION} alt="location" />{" "}
                                  {timeline?.locationName}
                                </p>
                                {timeline?.description ===
                                "Initiated SOS" ? null : (
                                  <div className="img-group">
                                    <p>Shared with:</p>
                                    {timeline?.sharingWith?.length > 0 ? (
                                      <ul className="img-collect">
                                        {timeline?.sharingWith.map(
                                          (item, index) => (
                                            <>
                                              {!item?.userProfilePic ? (
                                                <li key={index}>
                                                  <span className="letter-f">
                                                    {item?.userFriendName?.substring(
                                                      0,
                                                      1
                                                    )}
                                                  </span>
                                                </li>
                                              ) : (
                                                <li key={index}>
                                                  <img
                                                    src={item?.url}
                                                    alt="profile"
                                                  />
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      <p>&#x2014;</p>
                                    )}
                                  </div>
                                )}
                              </li>
                            ) : null}
                          </>
                        );
                      })
                    ) : (
                      <h6 className="text-center mt-5">No Data Found...</h6>
                    )}
                  </ul>
                </div>
                <div className="timeline"></div>
              </div>
              {/* <div className="col-md-6">
                 <Map google={this.props.google} zoom={14}>

                  <Marker onClick={this.onMarkerClick}
                    name={'Current location'} />

                  <InfoWindow onClose={this.onInfoWindowClose}>
                    <div>
                      <h1>{this.state.selectedPlace.name}</h1>
                    </div>
                  </InfoWindow>
                </Map> 
                <div className="maps">
                   {bigMapData?.length > 0 ? (
                    <iframe
                      name="gMap"
                      className="w-100"
                      src={`https://www.google.com/maps/embed/v1/place?q=${bigMapData[0]?.latitude},${bigMapData[0]?.longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}
                    ></iframe>
                  ) : null} */}

              {/* {isLoaded ? (
                    <GoogleMap
                      center={{
                        lat:
                          bigMapData?.length > 0
                            ? bigMapData[0]?.latitude
                            : 48.8584,
                        lng:
                          bigMapData?.length > 0
                            ? bigMapData[0]?.longitude
                            : 2.2945,
                      }}
                      // center={{
                      //   lat: 48.8584,
                      //   lng: 2.2945,
                      // }}
                      zoom={10}
                      mapContainerStyle={containerStyle}
                    >
                      <>
                        {bigMapData?.length > 0
                          ? bigMapData?.map((item, index) => (
                              <>
                                <MarkerF
                                  position={{
                                    lat: item?.latitude,
                                    lng: item?.longitude,
                                  }}
                                />
                              </>
                            ))
                          : null}
                        {commuteData?.length > 0
                          ? commuteData?.map((item, index) => (
                              <>
                                <Polyline
                                  path={[
                                    {
                                      lat: item?.latitudeStart,
                                      lng: item?.longitudeStart,
                                    },
                                    {
                                      lat: item?.latitudeEnd,
                                      lng: item?.longitudeEnd,
                                    },
                                  ]}
                                  geodesic={true}
                                  options={{
                                    strokeColor: "purple",
                                    strokeOpacity: 1,
                                    strokeWeight: 4,
                                  }}
                                />
                              </>
                            ))
                          : null}
                      </>
                    </GoogleMap>
                  ) : null} 
                </div>
              </div>*/}
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <RightSidebar
            responseGet2={responseGet2}
            postType={postType}
            geoAddress={geoAddress}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Timeline;
