import { combineReducers } from "redux";
import contactReucer from './contactReducer';
import { adminLoginData } from "./loginReducer";
import { forgotPassword } from "./forgotPasswordReducer";
import { sosCountFun } from "./sosReducer";
import { profileData } from "./profileReducer";
import { pageNoData } from "./pageReducer";
import {dropdownData} from "./dropdownReducer";
import { pageRouteFun } from "./sidebarReducer";

const combineReducer = combineReducers({
    adminLoginData,
    forgotPassword,
    sosCountFun,
    profileData,
    pageNoData,
    dropdownData,
    pageRouteFun
})

const rootReducer = (state, action) => {
  if (action.type === "RESET_STATE") {

    delete state.adminLoginData
    delete state.sosCountFun
    delete state.profileData
    delete state.pageNoData
    delete state.dropdownData
    delete state.pageRouteFun
  }
return combineReducer(state,action);
};
export default rootReducer;

