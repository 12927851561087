import React, { useEffect,useState } from "react";
import TotalUsersGraphComponent from "./TotalUsersGraphComponent";
import { CSVLink } from "react-csv";
import moment from "moment/moment";

const TotalUsers = ({
  responseGet6,
  responseGet8,
  responseGet9,
  responseGet10,
  responseGet7
}) => {
  const [formatCsv,setFormatCsv] = useState([])
  const headers = [
    { label: "Date", key: "date" },
    { label: "Total Paid Users", key: "totalPaidUsers" },
    { label: "Total Guardian Users", key: "totalGuardianUsers" },
    { label: "Total Free Trial Users", key: "totalFreeUsers" },

  ];
  const csvFormat = (responseGet7) => {
    if (responseGet7?.length > 0) {
      let arr2 = [];
      for (let i = 0; i < responseGet7.length; i++) {
        arr2.push({
          date:  moment.utc(responseGet7[i]?.subscriptionStartDate).local().format(
            "MM-DD-YYYY"
          ),
          totalPaidUsers:
          !responseGet7[i]?.totalPaidUsers 
              ? 0
              : responseGet7[i]?.totalPaidUsers,
          totalFreeUsers:
          !responseGet7[i]?.totalFreeTrialUsers 
              ? 0
              : responseGet7[i]?.totalFreeTrialUsers,
          totalGuardianUsers:
          !responseGet7[i]?.totalGuardiansUsers 
              ? 0
              : responseGet7[i]?.totalGuardiansUsers,

        });
      }
      if (arr2?.length > 0) {
        setFormatCsv(arr2)

      }
    }
  };

  const csvReport = {
    data: formatCsv,
    headers:headers,
    filename: "selected_day_user_data.csv",
  };
  useEffect(() => {
    csvFormat(responseGet7);
  }, [responseGet7]);
  return (
    <div>
      <div className="card-head">
        <div className="header-data">
          <h1 className="title-heading">Total Users</h1>
      
          {responseGet6?.length > 0 ? (
                <CSVLink
                  type="button"
                  className="btn btn-white btn-icon btn-lg btn-right ms-auto"
                  {...csvReport}
                >
                  <span>Download </span> <i className="icon-download"></i>
                </CSVLink>
              ) : null}
        </div>
      </div>
      <div className="card-body">
        <TotalUsersGraphComponent
          responseGet6={responseGet6}
          responseGet8={responseGet8}
          responseGet9={responseGet9}
          responseGet10={responseGet10}
        />
      </div>
    </div>
  );
};
export default TotalUsers;
