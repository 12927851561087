import { message } from "antd";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { createFaq, deleteFaq, editFaq } from "../../services/api/endpoints";
import axiosInstance from "../../services/middleware";

const FaqAdd = ({
  openModal,
  closeModal,
  question,
  setQuestion,
  answer,
  setAnswer,
  faqId,
  getFaqData,
  setFaqId,
  isDelete,
  setIsDelete,
  setOpenModal,
}) => {
  const onInputChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name == "question") {
      setQuestion(value);
    }
    if (name == "answer") {
      setAnswer(value);
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (faqId == null) {
      let payload = {
        faqDtos: [
          {
            question: question,
            answer: answer,
          },
        ],
      };
      try {
        const response = await axiosInstance.post(`${createFaq}`, payload);
        const apiData = response;
      
        if (apiData?.data?.success == false) {
          message.error(apiData?.data?.message);
          closeModal();
        } else {
          message.success(apiData?.data?.message);
          closeModal();
          getFaqData();
        }
      } catch (err) {

        message.error(err);
      }
    } else {
      let payload = {
        id: faqId,
        question: question,
        answer: answer,
      };
      try {
        const response = await axiosInstance.put(`${editFaq}`, payload);
        const apiData = response;

        if (apiData?.data?.success == false) {
          message.error(apiData?.data?.message);
          closeModal();
          setFaqId(null);
        } else {
          message.success(apiData?.data?.message);
          closeModal();
          getFaqData();
          setFaqId(null);
        }
      } catch (err) {
        message.error(err);
      }
    }
  };
  const handleDelete =async (event) => {
    event.preventDefault();
   
    try {
      const response = await axiosInstance.delete(`${deleteFaq}/${faqId}`);
      const apiData = response;
   
      if (apiData?.data?.success == false) {
        message.error(apiData?.data?.message);
        closeModal();
      } else {
        message.success(apiData?.data?.message);
        closeModal();
        getFaqData();
      }
    } catch (err) {

      message.error(err);
    }
  }

  useEffect(() => {
    if (faqId == null) {
      setAnswer("");
      setQuestion("");
    }
  }, [faqId]);

  return (
    <>
      <Modal show={openModal} onHide={closeModal} centered>
        {isDelete == 0 ? (
          <>
          <Modal.Header>
          <Modal.Title>Add Faq</Modal.Title>
          <button
            type="button"
            className="btn-close-modal"
            onClick={closeModal}
          >
            <span className="icon-cross"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="mb-3">
              <label className="form-label" htmlFor="faqtitle">
                Question
              </label>
              <input
                type="text"
                id="faqtitle"
                className="form-control"
                name="question"
                onChange={onInputChange}
                value={question}
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="faqtext">
                Answer
              </label>
              <textarea
                rows="6"
                id="faqtext"
                className="form-control"
                name="answer"
                onChange={onInputChange}
                value={answer}
              />
            </div>
          </Form>
          <div className="text-center">
            <Button
              variant="primary"
              className="btn-xxl"
              onClick={(event) => handleSave(event)}
            >
              Save
            </Button>
            <Button
              variant="transparent"
              className="btn-xxl"
              onClick={() => {
                setOpenModal(false);
                setIsDelete(0);
                setFaqId(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
          </>
          
        ):(

          <>
          <Modal.Header>
          <Modal.Title>Delete Faq</Modal.Title>
          <button
            type="button"
            className="btn-close-modal"
            onClick={closeModal}
          >
            <span className="icon-cross"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this FAQ?
          
          <div className="text-center">
            <Button
              variant="primary"
              className="btn-xxl"
              onClick={(event) => handleDelete(event)}
            >
              Yes
            </Button>
            <Button
              variant="transparent"
              className="btn-xxl"
              onClick={() => {
                setOpenModal(false);
                setIsDelete(0);
                setFaqId(null);
              }}
            >
              No
            </Button>
          </div>
        </Modal.Body>
          </>
        )}
        
      </Modal>
    </>
  );
};

export default FaqAdd;
