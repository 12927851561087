import axios from "axios";

// for normal environment
const baseURL = process.env.REACT_APP_ENV_VARIABLE_KEY;

// export const getToken = () =>
//   localStorage.getItem("token")
//     ? JSON.parse(localStorage.getItem("token"))
//     : null;

const noToken = axios.create({
  baseURL,
  headers: {
    timezone_offset: -330,
    language: "en-US",
    "Content-Type": "application/json",
    // Authorization: localStorage.getItem('adminLoginToken')
  },
});

// noToken.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('adminLoginToken');

//     // if (token) {
//       config.headers["Authorization"] = token;
//     // }

//     return config;
//   },
// );
export default noToken;
