import React, { useEffect } from "react";
import "./App.css";
import {
  Routes,
  Route,
  Redirect,
  BrowserRouter,
  Navigate,
  useNavigate,

} from "react-router-dom";
import { useDispatch } from "react-redux";
import Login from "./components/auth/Login";
import Dashboard from "./components/pages/Dashboard";
import ForgotPass from "./components/auth/ForgotPassword";
import ResetPass from "./components/auth/ResetPassword";
import UserManagement from "./components/pages/userManagement";
// import UserProfile from "./components/pages/userManagement/userProfile/profile";
import Timeline from "./components/pages/userManagement/Timeline/timeline";
import SubscriptionHistory from "./components/pages/userManagement/subscriptionHistory/SubscriptionHistory";
import LocationHistory from "./components/pages/userManagement/locationHistory/LocationHistory";
import UserProfile from "./components/pages/userManagement/profile/UserProfile";
import ProfileMainComponent from "./components/pages/userManagement/ProfileMainComponent";
import UserTableMainComponent from "./components/pages/userManagement/archivedUsers/UserTableMainComponent";
// cms
import Cms from "./components/pages/cms/Cms";
import InvoiceManagement from "./components/pages/invoiceManagement/InvoiceManagement";
// sos
import ActiveSos from "./components/pages/sos/activeSOS/ActiveSOS";
import SosHistory from "./components/pages/sos/sosHistory/SosHistory";
import ArchivedSOS from "./components/pages/sos/archivedSos/ArchivedSOS";
import SosDetails from "./components/pages/sos/sosDetails/SosDetails";
import Subscription from "./components/pages/subscription/Subscription";
import CmsEditor2 from "./components/pages/cms/CmsEditor2";
import SosMainComponent from "./components/pages/sos/SosMainComponent";
import PrivateRoute from "./privateRoutes";
import FaqEditor from "./components/pages/cms/FaqEditor";
function App() {
  const dispatch = useDispatch();


  const handleStorageEvent = (event) => {
    if (event.key === null && event.storageArea === localStorage) {
      // The local storage has been cleared
      dispatch({ type: "RESET_STATE" });
      Navigate("/")
    }
  };
// small change
  useEffect(() => {
    window.addEventListener("storage", handleStorageEvent);
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);
  useEffect(() => {
    const handleBrowserBack = () => {
      // Call your function when the back button is clicked
      myFunction();
    };

    window.addEventListener('popstate', handleBrowserBack);

    return () => {
      window.removeEventListener('popstate', handleBrowserBack);
    };
  }, []);

  const myFunction = () => {
    // Your function logic goes here
    dispatch({ type: "PAGE_ROUTE_DATA", payload:window.location?.pathname?.slice(1)  })
  };
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navigate to="/login" />} />

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPass />} />
          <Route exact path="/auth/reset-password" element={<ResetPass />} />
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/user-management"
            element={
              <PrivateRoute>
                <UserTableMainComponent />
              </PrivateRoute>
            }
          />
          {/* <Route exact path="/profile" element={<UserProfile />} /> */}
          <Route
            exact
            path="/timeline"
            element={
              <PrivateRoute>
                <Timeline />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/subscription-history"
            element={
              <PrivateRoute>
                <SubscriptionHistory />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/location"
            element={
              <PrivateRoute>
                <LocationHistory />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <PrivateRoute>
                <ProfileMainComponent />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/cms"
            element={
              <PrivateRoute>
                <Cms />
              </PrivateRoute>
            }
          />
          {/* <Route exact path="/cms-editor" element={<CmsEditor />} /> */}
          <Route
            exact
            path="/invoice-manage"
            element={
              <PrivateRoute>
                <InvoiceManagement />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/active-sos"
            element={
              <PrivateRoute>
                <SosMainComponent />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/sos-history"
            element={
              <PrivateRoute>
                <SosHistory />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/archived-sos"
            element={
              <PrivateRoute>
                <ArchivedSOS />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/sos-details"
            element={
              <PrivateRoute>
                <SosDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/subscription"
            element={
              <PrivateRoute>
                <Subscription />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/cms-editor"
            element={
              <PrivateRoute>
                <CmsEditor2 />
              </PrivateRoute>
            }
          />
 

          <Route exact path="/faq-editor" element={<FaqEditor />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
