import React, { useState, useEffect } from "react";

import Layout from "../../layout";
import { Link, useNavigate, useLocation } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Collapse, message } from "antd";
import FaqAdd from "../../modal/faqadd";
import axiosInstance from "../../../services/middleware";
import { getFaqList } from "../../../services/api/endpoints";

const { Panel } = Collapse;
const FaqEditor = (props) => {
  const navigate = useNavigate();

  const [responseGet, setResponseGet] = useState(null);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqId, setFaqId] = useState(null);
  const [faqData, setFaqData] = useState([]);
  const [isDelete, setIsDelete] = useState(0);

  const onBackClick = () => {
    navigate("/cms");
  };
  async function getFaqData() {
    try {
      const response = await axiosInstance.get(`${getFaqList}`);

      const apiData = response.data.data;

      setResponseGet(apiData);
    } catch (err) {
    
      console.log("faq list error", err);
    }
  }
  useEffect(() => {
    async function getFaqData() {
      try {
        const response = await axiosInstance.get(`${getFaqList}`);

        const apiData = response.data.data;

        setResponseGet(apiData);
      } catch (err) {
      
        console.log("faq list error",err);
      }
    }
    getFaqData();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const genExtra = (faq) => (
    <>
      <div className="btn-group">
        <button
          className="icon-btn btn-primary"
          onClick={() => {
            setOpenModal(true);
            setAnswer(faq?.answer);
            setQuestion(faq?.question);
            setFaqId(faq?.id);
          }}
        >
          <i className="icon-pencil"></i>
        </button>
        <button
          className="icon-btn btn-danger ms-2"
          onClick={(event) => {
            event.stopPropagation();
            setFaqId(faq?.id);
            setOpenModal(true);
            setIsDelete(1);
          }}
        >
          <i className="icon-cross"></i>
        </button>
      </div>
    </>
  );

  const onFaqChange = (event, faqId) => {
    let name = event.target.name;
    let value = event.target.value;
    let id = event.target.id;

    const itemIndex = faqData.findIndex((item) => item.id === faqId);

    const updatedItem = {
      ...faqData[itemIndex],
      [name]: value,
    };
    const updatedItems = [...faqData];
    updatedItems[itemIndex] = updatedItem;
    setFaqData(updatedItems);
  };

  return (
    <div>
      <Layout>
        <div className="back-btn mx--3">
          <button type="button" onClick={() => onBackClick()}>
            <i className="icon-angle-left"></i>
          </button>
        </div>
        <div className="top-filter">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1 className="fs-3 mb-0 customer-help">FAQ</h1>
              <p className="d-inline-flex">
                <i className="icon-pending me-2"></i>
                <small>
                  Change price on iTunes before making changes here.
                </small>
              </p>
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary btn-icon btn-lg btn-round w-auto"
                onClick={() => {
                  setOpenModal(true);
                  setFaqId(null);
                }}
              >
                Add More
              </button>

              <button
                type="button"
                className="btn btn-white btn-icon btn-lg btn-right btn-round w-auto"
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <Collapse accordion bordered={false} className="panel-collapse-heading">
          {responseGet?.map((faq, index) => (
            <Panel header={faq.question} key={faq?.id} extra={genExtra(faq)}>
              <div className="card">
                <div className="card-body">
              
                  <div className="mb-3">
                 
                    <h6>Question:</h6>
                  </div>
                 
                  <textarea
                    className="w-100"
                    onChange={(event) => onFaqChange(event, faq.id)}
                    name="question"
                    id={index}
                    disabled
                    value={faq.question}
                  />
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                
                  <div className="mb-3">
                  
                    <h6>Answer:</h6>
                  </div>
                 
                  <textarea
                    className="w-100"
                    onChange={(event) => onFaqChange(event, faq.id)}
                    name="answer"
                    id={faq?.id}
                    value={faq?.answer}
                    disabled
                  />
                </div>
              </div>
            </Panel>
          ))}
        </Collapse>
      </Layout>
      <FaqAdd
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
        question={question}
        setQuestion={setQuestion}
        answer={answer}
        setAnswer={setAnswer}
        faqId={faqId}
        setFaqId={setFaqId}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        getFaqData={getFaqData}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

export default FaqEditor;
