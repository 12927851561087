import React, { useState, useEffect } from "react";

import { Column } from "@ant-design/charts";

const TotalUsersGraphComponent = ({
  responseGet6,
  responseGet8,
  responseGet9,
  responseGet10,
}) => {
  const [data, setData] = useState(responseGet6);
  const config = {
    data,
    isStack: true,
    // isGroup:true,
    xField: "date",
    yField: "count",
    seriesField: "type",
    autofit: true,
    yAxis: {
      nice: true,
      tickInterval: 1,
      // label: {
      //   formatter: (value) => (value === 0 ? "-" : value), // format 0 values as "-"
      // },
    },
    // tooltip: {
    //   formatter: (datum) => {
    //     return `Date: ${datum.date}`;
    //   },
    // },
    // xAxis: {
    //   label: {
    //     formatter: (value) => (value === 0 ? "-" : value), // format 0 values as "-"
    //   },
    // },
    label: {
      style: {
        fontSize: 15,
        fontWeight: 700,
        fill: "#333333",
      },
      position: "middle",

      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
      ],

    
    },
  };
  useEffect(() => {
    setData(responseGet6);
  }, [responseGet6, data, responseGet8, responseGet9, responseGet10]);

  return (
    <div className="graph-wrapper">
      {/* <div className="graph-data">
        <div className="report-box">
          <div className="top-icon yellow">
            <i className="icon-dashboard"></i>
          </div>
          <div className="data">
            <p>Total Paid Users</p>
            <h4>{responseGet8}</h4>
          </div>
        </div>

        <div className="report-box">
          <div className="top-icon purple">
            <i className="icon-dashboard"></i>
          </div>
          <div className="data">
            <p>Total Guardian Users</p>
            <h4>{responseGet9}</h4>
          </div>
        </div>

        <div className="report-box">
          <div className="top-icon">
            <i className="icon-dashboard"></i>
          </div>
          <div className="data">
            <p>Total Free Trial Users</p>
            <h4>{responseGet10}</h4>
          </div>
        </div>

      </div> */}
      <div className="graph-users graph w-100">
        <div className="report-box">
          {data?.length > 0 ? <Column {...config} /> : null}
        </div>
      </div>
    </div>
  );
};

export default TotalUsersGraphComponent;
