const initialState = {
    pageRoute: null,
  };
  
  export const pageRouteFun = (state = initialState, action) => {
    switch (action.type) {
      case "PAGE_ROUTE_DATA": {
        return {
          ...state,
          pageRoute: action.payload,
        };
      }
  
      default: {
        return state;
      }
    }
  };
  