import moment from 'moment';

export const regionFromTimezone = (timezone) => {
    const offsetInMinutes = moment.tz(timezone).utcOffset();

    if (offsetInMinutes === -600) {
        return 'HAST'; // Hawaii-Aleutian Standard Time
    } else if (offsetInMinutes === -240) {
        return 'ET'; // Eastern Standard Time (US & Canada)
    } else if (offsetInMinutes === -300) {
        return 'CT'; // Central Time
    } else if (offsetInMinutes === 330) {
        return 'IT'; // Indian Standard Time
    } else if (offsetInMinutes === -360) {
        return 'MT'; // Mountain Daylight Time
    } else if (offsetInMinutes === -420) {
        return 'PT'; // Pacific Time
    } else if (offsetInMinutes === -480) {
        return 'AK'; // Alaska Time
    }

    // Default to a default region if the offset doesn't match any mapping
    return '';
};