const initialState = {
    dropState:""
  };
  
  export const dropdownData = (state = initialState, action) => {
    switch (action.type) {
      case "DROPDOWN": {
        return {
          ...state,
          dropState: action.payload,
        };
      }
      
      default: {
        return state;
      }
    }
  };
  