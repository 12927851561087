import React, { useState, useEffect } from "react";
import Images from "../Images";
import { useDispatch } from "react-redux";
import { useNavigate,useSearchParams } from "react-router-dom";
import validator from "validator";

import noToken from "../../services/middleware/noToken";
import { resetPassword } from "../../services/api/endpoints";
import { message } from "antd";

const ResetPass = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
const [loading,setLoading]= useState(false);
  const [validOldPassword, setValidOldPassword] = useState(false);
  const [validNewPassword, setValidNewPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const [samePassCheck, setSamePassCheck] = useState(false);

  const onFieldChange = (event) => {
    let target = event.target;
    let name = target.name;
    let value = target.value;
    if (name === "oldPass" && value && validator.isStrongPassword(value)) {
      setOldPassword(value);
      setValidOldPassword(true);
      setDisableButton(false);
    } else if (
      name === "oldPass" &&
      value &&
      !validator.isStrongPassword(value)
    ) {
      setOldPassword(value);
      setValidOldPassword(false);
      setDisableButton(true);
    }

    if (name === "newPass" && value && validator.isStrongPassword(value)) {
      setNewPassword(value);
      setValidNewPassword(true);
      setDisableButton(false);
    } else if (
      name === "newPass" &&
      value &&
      !validator.isStrongPassword(value)
    ) {
      setNewPassword(value);
      setValidNewPassword(false);
      setDisableButton(true);
    }
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    let payload = {
      newPassword:oldPassword,
      hashCode:searchParams.get("accessToken")
    }
    try {
      const response = await noToken.put(`${resetPassword}`, payload);
      const apiData = response;
      setLoading(false)
      
      message.success(apiData?.data?.message);
      navigate("/login")
      
    } catch (err) {
      setLoading(false)
      
      message.error(err)
    }
  };

  useEffect(() => {
    if (oldPassword === newPassword) {
      setSamePassCheck(true);
      setDisableButton(false);
    } else {
      setSamePassCheck(false);
      setDisableButton(true);
    }
  }, [oldPassword, newPassword]);

  return (
    <div className="layout-auth">
      <div className="logo-header">
        <img src={Images.LOGO} />
      </div>
      {/* Left banner column */}
      <div className="left-col">
        <div className="banner-image">
          <img src={Images.RESETIMG} />
        </div>
      </div>

      {/* Right Banner Login Page */}
      <div className="right-col">
        <div className="card">
          <div className="card-body">
            <h1 className="heading-two">Reset Password?</h1>
            <form>
              <div className="forgot-pass">
                <div className="mb-4">
                  <label className="form-label">New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="oldPass"
                    placeholder="Enter your password"
                    onChange={(event) => onFieldChange(event)}
                  />
                  {validOldPassword == false && oldPassword != null ? (
                    <p className="error text-wrap">
                      Password must contain 8 characters with 1 upper case,
                      <br /> 1 lower case, 1 number and 1 character
                    </p>
                  ) : null}
                </div>
                <div className="mb-4">
                  <label className="form-label">Confirm New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="newPass"
                    placeholder="Enter your password"
                    onChange={(event) => onFieldChange(event)}
                  />
                  {validNewPassword === false && newPassword != null ? (
                    <p className="error text-wrap">
                      Password must contain 8 characters with 1 upper case,
                      <br /> 1 lower case, 1 number and 1 character
                    </p>
                  ) : null}
                  {samePassCheck === false && newPassword != null ? (
                    <p className="error text-wrap">
                      New Password and confirm password should,
                      <br /> be same
                    </p>
                  ) : null}
                </div>
              </div>

              <button
                type="button"
                className="btn btn-primary signin-btn"
                disabled={disableButton}
                onClick={(event) => handleSubmit(event)}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
