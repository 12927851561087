const initialState = {
  sosCountData: null,
};

export const sosCountFun = (state = initialState, action) => {
  switch (action.type) {
    case "SOS_COUNT_GET_DATA": {
      return {
        ...state,
        sosCountData: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
